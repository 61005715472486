import { Card, CardContent, CardHeader, Typography } from "@mui/material";

export default function PreviewProjectCreated({ displayTitle }) {
  return (
    <Card>
      {displayTitle && (
        <CardHeader
          title={
            <Typography variant="h5" data-testid="PreviewProjectCreated-text">
              Your Project Has Been Created!
            </Typography>
          }
        />
      )}
      <CardContent>
        <div className="preview-project-created-content">
          <Typography>
            We currently do not have the minimum number of patients required to
            show results for the selected device(s) and procedure date range.
          </Typography>
          <br />
          <Typography>
            3Aware adheres to the Safe Harbor method and cannot return data
            counts unless there are at least 10 patients that meet the defined
            criteria. Our data set is continuously expanding, so check back
            later to see if we have more data on your device. In the meantime,
            please broaden the procedure date range of your project, or contact
            us for further assistance.
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
