import React from "react";
import { Card, CardContent, Skeleton, Stack, Typography } from "@mui/material";

export default function IndexProcedureVisitCardSkeleton() {
  return (
    <div className={"index-procedure-visit-container"}>
      <Card className={"index-procedure-visit-card"}>
        <div id="card-header-skeleton" style={{ padding: 16 }}>
          <Typography>
            <Skeleton animation={"wave"} width={120} />
          </Typography>
        </div>
        <CardContent classes={{ root: "content" }} align={"left"}>
          <Stack spacing={2}>
            <div className={"section"}>
              <Typography variant="body2">
                <Skeleton animation={"wave"} width={90} />
              </Typography>
              <Typography variant="body2">
                <Skeleton animation={"wave"} width={40} />
              </Typography>
            </div>
            <div className={"section diagnosis"}>
              <Typography variant="body2">
                <Skeleton animation={"wave"} width={90} />
              </Typography>
              <Typography variant="body2" sx={{ width: "100%" }}>
                <Skeleton animation={"wave"} />
              </Typography>
              <Typography variant="body2" sx={{ width: "100%" }}>
                <Skeleton animation={"wave"} />
              </Typography>
            </div>
            <div className={"section procedure"}>
              <Typography variant="body2">
                <Skeleton animation={"wave"} width={90} />
              </Typography>
              <Typography variant="body2" sx={{ width: "100%" }}>
                <Skeleton animation={"wave"} />
              </Typography>
              <Typography variant="body2" sx={{ width: "100%" }}>
                <Skeleton animation={"wave"} />
              </Typography>
            </div>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
