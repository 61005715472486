import React from "react";
import ListItemsSkeleton from "./ListItemsSkeleton";
import { FixedSizeList } from "react-window";
import ListItem from "./ListItem";

export default function ListItemsWrapper({
  patientSummaries,
  patientSelected,
  onPatientSelected,
}) {
  if (!patientSummaries || patientSummaries.length === 0) {
    return <ListItemsSkeleton />;
  }

  function renderRow({ index, style }) {
    const patientSummary = patientSummaries?.[index];

    return (
      <ListItem
        index={index}
        style={style}
        patientSummary={patientSummary}
        patientSelected={patientSelected}
        onPatientSelected={onPatientSelected}
      />
    );
  }

  return (
    <FixedSizeList
      height={window.innerHeight}
      style={{ width: "300px", display: "flex" }}
      itemSize={60}
      itemCount={patientSummaries.length}
      overscanCount={5}
    >
      {renderRow}
    </FixedSizeList>
  );
}
