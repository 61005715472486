import React from "react";
import { Button, Typography, Chip, Paper } from "@mui/material";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import { useNavigate } from "react-router-dom";
import { RequireAuth } from "@fusionauth/react-sdk";

export default function ZeroProjects({ handleStartProject, loading }) {
  const navigate = useNavigate();
  if (loading) {
    return null;
  }

  function navigateToCounts() {
    navigate("/counts");
  }

  return (
    <Paper className={"table-box-0"}>
      <div className="zero-projects">
        <Chip
          icon={
            <SettingsApplicationsOutlinedIcon
              style={{
                color: "#0D3E56",
                width: "48px",
                height: "48px",
                marginLeft: "18px",
              }}
            />
          }
          className="table-box-0-icon"
        ></Chip>
        <Typography variant="h6">
          You have not created any projects yet.
        </Typography>
      </div>
      <div className="zero-projects-buttons">
        <Button variant="contained" onClick={handleStartProject}>
          Start a Preview Project
        </Button>
        <RequireAuth withRole={"ADMIN"}>
          <Button variant="contained" onClick={navigateToCounts}>
            Pull Device Counts
          </Button>
        </RequireAuth>
      </div>
    </Paper>
  );
}
