import { convertDate, projectVersions } from "../utils/main";

export const startProject = {
  name: "",
  description: "",
  status: 0,
  version: projectVersions.previewMode,
  deviceManufacturer: null,
  deviceGmdn: null,
  devices: [],
  startDate: null,
  endDate: null,
  patientHistoryDiagExclude: false,
  patientHistoryProcExclude: false,
  createdBy: "b8edeed2-2ebc-b441-c3ac-26f09dd4ed4e",
  createdAt: new Date(),
  modifiedBy: "b8edeed2-2ebc-b441-c3ac-26f09dd4ed4e",
  modifiedAt: new Date(),
  expectedPatientCount: "",
};

export const projectMockData = {
  ages: [
    {
      count: 4,
      models: [
        {
          count: 4,
          name: "Chiba Biopsy Needle G01559",
        },
      ],
      range: "31-40",
    },
  ],
  races: [
    {
      count: 3,
      models: [
        {
          count: 3,
          name: "Chiba Biopsy Needle G01559",
        },
      ],
      race: "Asian",
    },
  ],
  ethnicities: [
    {
      count: 9,
      models: [
        {
          count: 9,
          name: "Chiba Biopsy Needle G01559",
        },
      ],
      ethnicity: "Not Hispanic or Latino",
    },
    {
      count: 6,
      models: [
        {
          count: 6,
          name: "Chiba Biopsy Needle G01559",
        },
      ],
      ethnicity: "Hispanic or Latino",
    },
  ],
  sexes: [
    {
      count: 2,
      models: [
        {
          count: 2,
          name: "Chiba Biopsy Needle G01559",
        },
      ],
      sex: "Male",
    },
    {
      count: 8,
      models: [
        {
          count: 8,
          name: "Chiba Biopsy Needle G01559",
        },
      ],
      sex: "Female",
    },
  ],
};

export function projectActivityLogs(logs) {
  const { stringToDate } = convertDate;
  return logs.map((log) => {
    return {
      ...log,
      createdAt: stringToDate(log.createdAt),
    };
  });
}

export const mockKeyword = {
  category: "",
  phrase: "",
  target: "body",
};

export function filterKeywords(keywords) {
  const keywordsFiltered = keywords.filter((item) => {
    if (!item.editing) {
      return false;
    }
    // Filter items that are marked as forDelete but they haven't been created yet
    return !(!item.id && item.forDelete);
  });
  return keywordsFiltered.map((keyword) => {
    const copy = { ...keyword };
    delete copy.editing;
    return copy;
  });
}

export function hasIncompleteKeywords(keywords) {
  const keywordsFiltered = filterKeywords(keywords);
  return keywordsFiltered.some(
    (item) => !item.phrase || !item.category || !item.target
  );
}

export function formatUpsertProjectData(project) {
  const startDateString = formatYear(project.startDate, "startDate");
  const endDateString = formatYear(project.endDate, "endDate");
  return {
    ...project,
    ...(startDateString && { startDate: startDateString }),
    ...(endDateString && { endDate: endDateString }),
  };
}

function formatYear(date, type) {
  if (!date) {
    return null;
  }
  const year = date.getFullYear();
  if (type === "startDate") {
    return `${year}-01-01T00:00:00.000+00:00`;
  }
  return `${year}-12-31T00:00:00.000+00:00`;
}
