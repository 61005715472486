import React, { useState, useEffect, useCallback } from "react";

import {
  Box,
  CardActions,
  Divider,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StandardDrawerFooter from "../StandardDrawerFooter";
import PatientDemographics from "./PatientDemographics";
import DiagnosisCodes from "./DiagnosisCodes";
import ProcedureCodes from "./ProcedureCodes";
import { getAllTags } from "../../../services/TagsServices";
import {
  getDiagnosisCodes,
  getProcedureCodes,
  updateProjectsFilters,
} from "../../../services/ProjectsServices";
import { useSearchParams } from "react-router-dom";
import { useFusionAuth } from "@fusionauth/react-sdk";

export default function FilterDataSet({ onClose, project }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { startLogout } = useFusionAuth();
  const [tabSelected, setTabSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [diagLoaded, setDiagLoaded] = useState(false);
  const [procedureLoaded, setProcedureLoaded] = useState(false);
  const [diagSearchValue, setDiagSearchValue] = useState("");
  const [diagType, setDiagType] = useState("icd-10");
  const [procedureSearchValue, setProcedureSearchValue] = useState("");
  const [procedureType, setProcedureType] = useState("cpt");
  const [updating, setUpdating] = useState(false);
  const [reason, setReason] = useState("");
  const [err, setErr] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [filters, setFilters] = useState({
    tags: [],
    diagnosisCodes: {},
    procedureCodes: {},
  });
  const [filterPayload, setFilterPayload] = useState({
    patientDemographics: project.tags ? project.tags : [],
    diagnosisCodes: [],
    procedureCodes: [],
  });

  const updateFilterPayload = useCallback(
    (type, filters) => {
      setUpdating(true);
      if (type === "patientDemographics") {
        setFilterPayload({
          ...filterPayload,
          patientDemographics: filters,
        });
      }
      if (type === "diagnosisCodes") {
        setFilterPayload({
          ...filterPayload,
          diagnosisCodes: filters,
        });
      }
      if (type === "procedureCodes") {
        setFilterPayload({
          ...filterPayload,
          procedureCodes: filters,
        });
      }
    },
    [filterPayload]
  );

  function getCountFromArray(codes) {
    let totalCount = 0;
    codes.forEach((code) => {
      totalCount = totalCount + (code.grouped ? code.grouped.length : 0);
    });
    return totalCount;
  }

  useEffect(() => {
    const diagnosisObject = {
      relatedCount: 0,
      unrelatedCount: 0,
      related: {
        grouped: [],
        ungrouped: [],
      },
      unrelated: {
        grouped: [],
        ungrouped: [],
      },
    };

    const procedureObject = {
      relatedCount: 0,
      unrelatedCount: 0,
      related: {
        grouped: [],
        ungrouped: [],
      },
      unrelated: {
        grouped: [],
        ungrouped: [],
      },
    };

    // the contents of this function are a temporary solution
    // TO-DO: once we remove separate calls for filters then...
    // take out the three calls and combine
    getAllTags()
      .then((tags) => {
        getDiagnosisCodes(
          project.id,
          diagType,
          diagSearchValue,
          "related"
        ).then((diagData) => {
          diagnosisObject.relatedCount =
            getCountFromArray(diagData.grouped) + diagData.ungrouped.length;
          diagnosisObject.related.grouped = diagData.grouped;
          diagnosisObject.related.ungrouped = diagData.ungrouped;

          getDiagnosisCodes(
            project.id,
            diagType,
            diagSearchValue,
            "unrelated"
          ).then((diagData2) => {
            diagnosisObject.unrelatedCount =
              getCountFromArray(diagData2.grouped) + diagData2.ungrouped.length;
            diagnosisObject.unrelated.grouped = diagData2.grouped;
            diagnosisObject.unrelated.ungrouped = diagData2.ungrouped;

            getProcedureCodes(
              project.id,
              procedureType,
              procedureSearchValue,
              "related"
            ).then((procCodes) => {
              procedureObject.relatedCount = procCodes.relatedCount;
              procedureObject.related.grouped = procCodes.grouped;
              procedureObject.related.ungrouped = procCodes.ungrouped;

              getProcedureCodes(
                project.id,
                procedureType,
                procedureSearchValue,
                "unrelated"
              ).then((procCodes2) => {
                procedureObject.unrelatedCount = procCodes2.unrelatedCount;
                procedureObject.unrelated.grouped = procCodes2.grouped;
                procedureObject.unrelated.ungrouped = procCodes2.ungrouped;

                setFilters({
                  tags: tags,
                  diagnosisCodes: diagnosisObject,
                  procedureCodes: procedureObject,
                });
                setDiagLoaded(true);
                setProcedureLoaded(true);
              });
            });
          });
        });
      })
      .catch((err) => {
        if (err === "401" || err === "403") {
          void startLogout();
        }
      });
  }, [
    project.id,
    diagSearchValue,
    diagType,
    procedureSearchValue,
    procedureType,
    startLogout,
  ]);

  function handleTabChange(_, value) {
    setTabSelected(value);
  }

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  function applyFilters(e) {
    e.preventDefault();
    if (reason.trim() === "" && updating) {
      document.getElementById("reason").scrollIntoView();
      setErr(true);
      return;
    }
    if (!updating) {
      return;
    }
    setLoading(true);
    const filterObject = {
      tags: filterPayload.patientDemographics,
      diagnosisCodes: filterPayload.diagnosisCodes,
      procedureCodes: filterPayload.procedureCodes,
      reason: reason,
    };
    updateProjectsFilters(project.id, filterObject)
      .then((_) => {
        setLoading(false);
        if (
          filterObject.diagnosisCodes.length > 0 ||
          filterObject.procedureCodes.length > 0
        ) {
          searchParams.set("refreshIndexProcedure", "true");
          setSearchParams(searchParams);
        }
        onClose({ fetchProject: true });
      })
      .catch((err) => {
        if (err === "401" || err === "403") {
          void logout();
        }
      });
  }

  function updateSelectedTags(tagId, checked) {
    let tagsSelected = [];
    setUpdating(true);
    if (checked) {
      const selectedTag = filters.tags.find((tag) => tag.id === tagId);
      tagsSelected = [...filterPayload.patientDemographics, selectedTag];
    } else {
      tagsSelected = filterPayload.patientDemographics.filter(
        (tag) => tag.id !== tagId
      );
    }

    setFilterPayload({
      ...filterPayload,
      patientDemographics: tagsSelected,
    });
  }

  return (
    <Drawer
      anchor={"right"}
      open={true}
      PaperProps={{
        sx: { width: "min(100%, 800px)" },
      }}
      onClose={handleClose}
    >
      <Box className={"project-drawer"} sx={{ bgcolor: "common.white" }}>
        <Stack
          className={"drawer-header"}
          direction={"row"}
          spacing={1}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">Refine Data Set</Typography>
          <IconButton
            onClick={handleClose}
            sx={{ "&:hover": { bgcolor: "action.hover" } }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider flexItem />
        <Tabs
          className={"filter-dataset-tabs"}
          value={tabSelected}
          onChange={handleTabChange}
          centered
        >
          <Tab label={"demographics"} />
          <Tab disabled={!diagLoaded} label={"diagnosis codes"} />
          <Tab disabled={!procedureLoaded} label={"procedure codes"} />
        </Tabs>
        <Box className={"modal-content"} sx={{ bgcolor: "grey.50" }}>
          {tabSelected === 0 && (
            <PatientDemographics
              tags={filters.tags}
              selectedTags={filterPayload.patientDemographics}
              updateSelectedTags={updateSelectedTags}
              updateFilterPayload={updateFilterPayload}
              updating={updating}
              reason={reason}
              setReason={setReason}
            />
          )}
          {tabSelected === 1 && (
            <DiagnosisCodes
              codes={filters.diagnosisCodes}
              setDiagSearchValue={setDiagSearchValue}
              setDiagType={setDiagType}
              updateFilterPayload={updateFilterPayload}
              payload={filterPayload.diagnosisCodes}
              setUpdating={setUpdating}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
            />
          )}
          {tabSelected === 2 && (
            <ProcedureCodes
              codes={filters.procedureCodes}
              setProcedureSearchValue={setProcedureSearchValue}
              setProcedureType={setProcedureType}
              updateFilterPayload={updateFilterPayload}
              payload={filterPayload.procedureCodes}
              setUpdating={setUpdating}
              allChecked={allChecked}
              setAllChecked={setAllChecked}
            />
          )}
          {updating && (
            <TextField
              label="Note/Reason for Refining the Data Set"
              variant="outlined"
              fullWidth
              size="medium"
              value={reason}
              onChange={handleReasonChange}
              sx={{ bgcolor: "common.white" }}
              helperText={"This field is required"}
              error={err}
              id={"reason"}
            />
          )}
        </Box>
        <CardActions className="filter-dataset-footer">
          <StandardDrawerFooter
            leftBtnLabel={"Cancel"}
            onLeftBtn={handleCancel}
            rightBtnLabel={"Apply Filters"}
            onRightBtn={applyFilters}
            loading={loading}
            displayLoadingRightBtn={true}
          />
        </CardActions>
      </Box>
    </Drawer>
  );
}
