import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../shared/Footer";
import { useFusionAuth } from "@fusionauth/react-sdk";
import { useFetch } from "../../../services/hooks/useFetch";
import { deviceApiPrefix } from "../../../utils/main";

export default function ManufacturerSettingsPage() {
  const [editMode, setEditMode] = useState(false);

  const { update: createDMAlias, delete: deleteDMAlias } = useFetch();
  const { update: createDMDomain, delete: deleteDMDomain } = useFetch();
  const { update: updateDeviceManufacturerAlias } = useFetch();
  const { update: updateDomainName } = useFetch();
  const { update: updateDeviceManufacturer } = useFetch();

  const { data: aliases, fetch: getDeviceManufacturerAliases } = useFetch();
  const { data: domains, fetch: getDeviceManufacturerDomains } = useFetch();
  const { data: deviceManufacturers, fetch: getDeviceManufacturers } =
    useFetch();
  const { data: sources, fetch: getSources } = useFetch();

  const [selectedManufacturer, setSelectedManufacturer] = useState({});
  const [currentManufacturerName, setManufacturerName] = useState("N.A.");

  const [currentDMSearch, setCurrentDMSearch] = useState("");

  const [domainIndex, setDomainIndex] = useState(-1);
  const [domainName, setDomainName] = useState("N.A.");

  const [aliasIndex, setAliasIndex] = useState(-1);
  const [aliasName, setAliasName] = useState("N.A.");

  const [sourceIndex, setSourceIndex] = useState(-1);

  const [domainsChanged, setDomainsChanged] = useState(false);
  const [aliasChanged, setAliasChanged] = useState(false);

  const [displayErrors, setDisplayErrors] = useState(false);

  const { userInfo } = useFusionAuth();
  const isWorkshop = userInfo?.roles?.includes("WORKSHOP");

  useEffect(() => {
    if (isWorkshop) {
      if (currentDMSearch !== "") {
        getDeviceManufacturers(
          `${deviceApiPrefix}/devices/manufacturers?search=~${currentDMSearch}~`
        );
      } else {
        getDeviceManufacturers(
          `${deviceApiPrefix}/devices/manufacturers?search=~~`
        );
      }
    } else {
      getDeviceManufacturers(
        `${deviceApiPrefix}/devices/manufacturers/company/`
      );
    }
  }, [isWorkshop, getDeviceManufacturers, currentDMSearch]);

  useEffect(() => {
    if (selectedManufacturer.id) {
      let id = selectedManufacturer.id;
      getDeviceManufacturerDomains(
        `${deviceApiPrefix}/devices/manufacturers/${id}/domains`
      );

      setDomainIndex(-1);
      setDomainName("N.A.");

      if (domainsChanged) setDomainsChanged(false);
    }
  }, [
    selectedManufacturer,
    deviceManufacturers,
    domainsChanged,
    getDeviceManufacturerDomains,
  ]);

  useEffect(() => {
    if (selectedManufacturer.id) {
      let id = selectedManufacturer.id;

      getDeviceManufacturerAliases(
        `${deviceApiPrefix}/devices/manufacturers/${id}/aliases`
      );

      setAliasIndex(-1);
      setAliasName("N.A.");

      if (aliasChanged) setAliasChanged(false);
    }
  }, [
    selectedManufacturer,
    deviceManufacturers,
    aliasChanged,
    getDeviceManufacturerAliases,
  ]);

  useEffect(() => {
    getSources(`${deviceApiPrefix}/sources/`);
  }, [getSources]);

  useEffect(() => {
    if (selectedManufacturer && selectedManufacturer.name) {
      setManufacturerName(selectedManufacturer.name);
    } else {
      setManufacturerName("N.A.");
    }
  }, [selectedManufacturer]);

  const getSourceIndex = function (sourceId) {
    let sourceIndex = -1;
    sources.forEach((source, index) => {
      if (source.id === sourceId) {
        sourceIndex = index;
      }
    });
    return sourceIndex;
  };

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              <Link underline="hover" color="inherit" to={"/"}>
                Global Settings
              </Link>
              <Typography>Manufacturer Settings</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Manufacturer Settings</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">
              Information about the manufacturer.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          <Grid item>
            <Autocomplete
              disablePortal
              options={deviceManufacturers}
              sx={{ width: "50 ch" }}
              getOptionLabel={(option) => {
                if (option.name) {
                  return option.name;
                } else {
                  return "";
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Current Device Manufacturer" />
              )}
              inputValue={currentDMSearch}
              onInputChange={(event) => {
                if (event === null) {
                  setCurrentDMSearch("");
                } else {
                  setCurrentDMSearch(event.target.value);
                }
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={selectedManufacturer.id ? selectedManufacturer : null}
              onChange={(event, value) => {
                if (value === null) {
                  setSelectedManufacturer({});
                  setCurrentDMSearch("");
                } else {
                  setSelectedManufacturer(value);
                  setCurrentDMSearch(value.name);
                }
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === null) {
                  return true;
                }

                if (option.id && value.id) {
                  return option.id === value.id;
                }

                return false;
              }}
            />
          </Grid>
          <br />
          <Grid item>
            {editMode ? (
              <TextField
                required
                variant="outlined"
                label="Device Manufacturer Name"
                className="settings-text-input"
                sx={{ width: "50ch" }}
                value={currentManufacturerName}
                error={displayErrors && currentManufacturerName === ""}
                helperText={
                  displayErrors && currentManufacturerName === ""
                    ? "This field is required"
                    : ""
                }
                InputProps={{
                  readOnly: !editMode || !selectedManufacturer.id,
                }}
                onChange={(event) => {
                  setManufacturerName(event.target.value);
                }}
              />
            ) : (
              <>
                <Typography variant="caption">
                  Device Manufacturer Name *
                </Typography>
                <Typography variant="body1">
                  {currentManufacturerName}
                </Typography>
              </>
            )}
          </Grid>
          <br />
          <Grid item>
            <InputLabel shrink htmlFor="select-domain">
              Current Device Manufacturer Domain
            </InputLabel>
            <Select
              value={domainIndex}
              defaultValue={-1}
              onChange={(event) => {
                setDomainIndex(event.target.value);

                if (event.target.value === -1) {
                  setDomainName("N.A.");
                } else if (event.target.value === -2) {
                  setDomainName("");
                } else {
                  setDomainName(domains[event.target.value].domain);
                }
              }}
              inputProps={{
                id: "select-domain",
              }}
            >
              <MenuItem value={-1}>None</MenuItem>
              {domains.map((json, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {json.id}
                  </MenuItem>
                );
              })}
              {selectedManufacturer.id && (
                <MenuItem value={-2}>Add New Domain</MenuItem>
              )}
            </Select>
          </Grid>
          <br />
          {editMode ? (
            <Grid item>
              <TextField
                required
                variant="outlined"
                label="Device Manufacturer Domain Name"
                className="settings-text-input"
                sx={{ width: "50ch" }}
                value={domainName}
                error={displayErrors && domainName === ""}
                helperText={
                  displayErrors && domainName === ""
                    ? "This field is required"
                    : ""
                }
                InputProps={{
                  readOnly: !editMode || domainIndex === -1,
                }}
                onChange={(event) => {
                  setDomainName(event.target.value);
                }}
              />
            </Grid>
          ) : (
            <>
              <Typography variant="caption">
                Device Manufacturer Domain Name *
              </Typography>
              <Typography variant="body1" sx={{ width: "50ch" }}>
                {domainName}
              </Typography>
            </>
          )}
          {domainIndex !== -1 && domainIndex !== -2 && editMode && (
            <>
              <br />
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    deleteDMDomain(
                      `${deviceApiPrefix}/devices/manufacturers/domains/${domains[domainIndex].id}`
                    ).then(() => {
                      setDomainsChanged(true);
                      setDomainIndex(-1);
                      setDomainName("N.A.");
                      setEditMode(false);
                    });
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </>
          )}
          <br />
          <Grid item>
            <InputLabel shrink htmlFor="select-alias">
              Current Device Manufacturer Alias
            </InputLabel>
            <Select
              value={aliasIndex}
              defaultValue={-1}
              onChange={(event) => {
                setAliasIndex(event.target.value);
                if (event.target.value === -1) {
                  setAliasName("N.A.");
                  setSourceIndex(-1);
                } else if (event.target.value === -2) {
                  setAliasName("");
                  setSourceIndex(-1);
                } else {
                  setAliasName(aliases[event.target.value].alias);
                  setSourceIndex(
                    getSourceIndex(aliases[event.target.value].sourceId)
                  );
                }
              }}
              inputProps={{
                id: "select-alias",
              }}
            >
              <MenuItem value={-1}>None</MenuItem>
              {aliases.map((json, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {json.id}
                  </MenuItem>
                );
              })}
              {selectedManufacturer.id && (
                <MenuItem value={-2}>Add New Alias</MenuItem>
              )}
            </Select>
          </Grid>
          <br />
          {editMode ? (
            <Grid item>
              <TextField
                required
                variant="outlined"
                label="Device Manufacturer Alias Name"
                className="settings-text-input"
                sx={{ width: "50ch" }}
                value={aliasName}
                error={displayErrors && aliasName === ""}
                helperText={
                  displayErrors && aliasName === ""
                    ? "This field is required"
                    : ""
                }
                InputProps={{
                  readOnly: !editMode || aliasIndex === -1,
                }}
                onChange={(event) => {
                  setAliasName(event.target.value);
                }}
              />
            </Grid>
          ) : (
            <>
              <Typography variant="caption">
                Device Manufacturer Alias Name *
              </Typography>
              <Typography variant="body1" sx={{ width: "50ch" }}>
                {aliasName}
              </Typography>
            </>
          )}
          <br />
          {editMode ? (
            <Grid item>
              <InputLabel shrink htmlFor="select-source">
                Current Alias Source
              </InputLabel>
              <Select
                value={sourceIndex}
                defaultValue={-1}
                onChange={(event) => {
                  setSourceIndex(event.target.value);
                }}
                inputProps={{
                  id: "select-source",
                  readOnly: !editMode,
                }}
              >
                <MenuItem value={-1}>Null</MenuItem>
                {sources.map((json, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {json.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          ) : (
            <>
              <Typography variant="caption">Current Alias Source</Typography>
              <Typography variant="body1" sx={{ width: "50ch" }}>
                {sources[sourceIndex] ? sources[sourceIndex].name : "Null"}
              </Typography>
            </>
          )}
          {aliasIndex !== -1 && aliasIndex !== -2 && editMode && (
            <>
              <br />
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    deleteDMAlias(
                      `${deviceApiPrefix}/devices/manufacturers/${selectedManufacturer.id}/aliases/${aliases[aliasIndex].id}`
                    ).then(() => {
                      setAliasChanged(true);
                      setAliasIndex(-1);
                      setAliasName("N.A.");
                      setSourceIndex(-1);
                      setEditMode(false);
                    });
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </>
          )}
          <br />
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setManufacturerName(selectedManufacturer.name);
                    if (aliasIndex !== -1) {
                      if (aliasIndex === -2) {
                        setAliasIndex(-1);
                        setAliasName("N.A.");
                      } else {
                        setAliasName(aliases[aliasIndex].alias);
                      }
                    }
                    if (domainIndex !== -1) {
                      if (domainIndex === -2) {
                        setDomainIndex(-1);
                        setDomainName("N.A.");
                      } else {
                        setDomainName(domains[domainIndex].domain);
                      }
                    }
                    setDisplayErrors(false);
                    setEditMode(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    if (
                      currentManufacturerName === "" ||
                      domainName === "" ||
                      aliasName === ""
                    ) {
                      setDisplayErrors(true);
                      return;
                    }

                    if (
                      selectedManufacturer.id &&
                      currentManufacturerName !== selectedManufacturer.name
                    ) {
                      let id = selectedManufacturer.id;

                      updateDeviceManufacturer(
                        `${deviceApiPrefix}/devices/manufacturers/update/${id}`,
                        { name: currentManufacturerName },
                        "PUT"
                      );
                    }

                    if (
                      domains[domainIndex] &&
                      domainName !== domains[domainIndex].domain
                    ) {
                      let domainId = domains[domainIndex].id;
                      domains[domainIndex].domain = domainName;

                      updateDomainName(
                        `${deviceApiPrefix}/devices/manufacturers/domains/${domainId}`,
                        domains[domainIndex],
                        "PUT"
                      );
                    } else if (domainIndex === -2) {
                      createDMDomain(
                        `${deviceApiPrefix}/devices/manufacturers/domains/`,
                        {
                          deviceManufacturerId: selectedManufacturer.id,
                          domain: domainName,
                        },
                        "POST"
                      ).then(() => {
                        setDomainsChanged(true);
                        setDomainIndex(-1);
                        setDomainName("N.A.");
                      });
                    }

                    if (
                      aliases[aliasIndex] &&
                      aliasName !== aliases[aliasIndex].alias
                    ) {
                      let dmId = selectedManufacturer.id;
                      let aliasId = aliases[aliasIndex].id;
                      updateDeviceManufacturerAlias(
                        `${deviceApiPrefix}/devices/manufacturers/${dmId}/aliases/${aliasId}`,
                        { alias: aliasName },
                        "PUT"
                      );
                    } else if (aliasIndex === -2) {
                      let sourceId = null;

                      if (sources[sourceIndex]) {
                        sourceId = sources[sourceIndex].id;
                      }

                      createDMAlias(
                        `${deviceApiPrefix}/devices/manufacturers/${selectedManufacturer.id}/aliases`,
                        {
                          deviceManufacturerId: selectedManufacturer.id,
                          sourceId: sourceId,
                          alias: aliasName,
                        },
                        "POST"
                      ).then(() => {
                        setAliasChanged(true);
                        setAliasIndex(-1);
                        setAliasName("N.A.");
                        setSourceIndex(-1);
                      });
                    }

                    if (
                      aliases[aliasIndex] &&
                      sources[sourceIndex] &&
                      sources[sourceIndex].id !== aliases[aliasIndex].sourceId
                    ) {
                      let manufacturerId = selectedManufacturer;
                      let aliasId = aliases[aliasIndex].id;
                      updateDeviceManufacturerAlias(
                        `${deviceApiPrefix}/devices/manufacturers/${manufacturerId}/aliases/${aliasId}`,
                        { sourceId: sources[sourceIndex].id },
                        "POST"
                      );
                    }

                    setEditMode(false);
                  }}
                >
                  Update
                </Button>
              </>
            )) || (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Grid>
          <br />
          <Grid item>
            <Footer />
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
