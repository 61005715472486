import React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import theme from "../../../../styles/theme";
import ImportantKeywords from "./ImportantKeywords";
export default function Prioritization({
  displayContent,
  displayErrors,
  keywords,
  updateKeyword,
}) {
  if (!displayContent) {
    return null;
  }
  return (
    <Box className="prioritization-container" sx={{ bgcolor: "grey.50" }}>
      <div>
        <Alert className="prioritation-info-text " severity="info">
          <AlertTitle>How 3Aware Prioritization Works</AlertTitle>
          Using your selections below, 3Aware will assign a priority score to
          clinical notes and patient visits. Records with high priority will be
          displayed with this icon:
          <OfflineBoltOutlinedIcon
            className="icon"
            size="small"
            sx={{ color: theme.palette.info.main }}
          />
        </Alert>
      </div>
      {/* <div className="clinical-notes-box">
        <Typography variant="h6">Important Clinical Note Titles</Typography>
        <Typography variant="body2">
          Select the titles of clinical notes that you would like to
          prioritize for review.
        </Typography>
        <Autocomplete
          filterSelectedOptions
          multiple
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={notesTitle}
          getOptionLabel={(option) => option.description}
          value={titlesSelected}
          onChange={onNotesChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="standard"
              fullWidth
            />
          )}
        />
        {err && (
          <FormHelperText error>This field is required</FormHelperText>
        )}
      </div> */}
      <ImportantKeywords
        keywords={keywords}
        updateKeyword={updateKeyword}
        displayRequiredError={displayErrors}
      />
    </Box>
  );
}
