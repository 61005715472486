import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PatientDemographicsDropDown({
  tagsSelected,
  onChange,
  categoryName,
  categoryItems,
}) {
  return (
    <Accordion>
      <AccordionSummary
        sx={{ marginTop: "24px" }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className="category-name">{categoryName}</Typography>
      </AccordionSummary>
      <AccordionDetails className={"details"}>
        {categoryItems.map((categoryItem) => {
          return (
            <div className={"dropdown-text-box"} key={String(categoryItem.id)}>
              <FormControlLabel
                sx={{ marginLeft: "6px" }}
                color={"primary"}
                label={categoryItem.name}
                control={
                  <Checkbox
                    color={"primary"}
                    checked={tagsSelected.some(
                      (item) => item.id === categoryItem.id
                    )}
                    id={String(categoryItem.id)}
                    onChange={onChange}
                  />
                }
              />
              {/* <button className="dropdown-button">14</button> */}
              <br />
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
