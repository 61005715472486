import React from "react";
import { Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { scrollToSection } from "../../utils/main";

const jumpToSections = [
  {
    title: "Version/Model # Breakdown",
    sectionId: "version-model-breakdown",
  },
  {
    title: "Demographic Breakdown",
    sectionId: "demographic-breakdown",
    isPremiumFeature: true,
  },
  {
    title: "Geographic Breakdown",
    sectionId: "geographic-breakdown",
    isPremiumFeature: true,
  },
];
export default function JumpTo({ version }) {
  return (
    <Card className={"jump-to-card"}>
      <CardHeader
        className={"jump-to-card-header"}
        title={<Typography className={"header-title"}>Jump To</Typography>}
      />
      <CardContent className={"jump-to-card-content"}>
        {jumpToSections.map((section) => {
          if (version === "Preview Mode" && section.isPremiumFeature) {
            return (
              <div key={section.title} className="jump-to-item-preview-mode">
                <Typography sx={{ color: "text.disabled" }}>
                  {section.title}
                </Typography>
                <LockOutlinedIcon color="disabled" fontSize="small" />
              </div>
            );
          }
          return (
            <Link
              key={section.title}
              className={"jump-to-item"}
              onClick={() => scrollToSection(section.sectionId)}
              variant={"body1"}
            >
              {section.title}
            </Link>
          );
        })}
      </CardContent>
    </Card>
  );
}
