import React from "react";
import { Skeleton } from "@mui/material";

export default function DemographicsChartSkeleton() {
  return (
    <div className="skeleton">
      <Skeleton
        variant="circular"
        animation="wave"
        width="144px"
        height="144px"
      />
      <div className="skeleton-row">
        <Skeleton animation="wave" width="60px" />
        <Skeleton animation="wave" width="60px" />
        <Skeleton animation="wave" width="60px" />
        <Skeleton animation="wave" width="60px" />
      </div>
    </div>
  );
}
