import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import ActivityCard from "./activitycard/ActivityCard";
import Activity from "./Activity";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import { projectActivityLogs } from "../../../models/Projects";

export default function ProjectActivity({ onClose }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { fetch, data: logs } = useFetch();

  useEffect(() => {
    fetch(`${apiPrefix}/activity/projects/${projectId}`, (data) => {
      return projectActivityLogs(data);
    });
  }, [projectId, fetch]);

  return (
    <Activity title={"Project Activty"} onClose={onClose} logs={logs}>
      {(log) => <ActivityCard key={log.id} log={log} cardType={"project"} />}
    </Activity>
  );
}
