import React, { useCallback, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@mui/material";
import RowGroup from "./RowGroup";
import RowItem from "./RowItem";
import {
  getTableDataIsRelatedValues,
  isThisCodeRelated,
  sortByEnum,
  sortData,
  sortEnum,
} from "../../../../data/codesTable";
import MarkCodesSnackbar from "./MarkCodesSnackbar";

export default function CodesTable({
  firstColumnLabel,
  tableData,
  codesMarkedMap,
  updateCodes,
  codeTypeLabel,
}) {
  const [sort, setSort] = useState(sortEnum.asc);
  const [sortBy, setSortBy] = useState("");
  const [codesSelected, setCodesSelected] = useState({});
  const [types, setTypes] = useState([]);

  const allCodesSelectedIds = useMemo(() => {
    const groupsValues = Object.values(codesSelected);
    const flatArray = groupsValues.flatMap((id) => id);
    return flatArray;
  }, [codesSelected]);

  const tableDataFiltered = useMemo(() => {
    if (!tableData || (!tableData.grouped && !tableData.ungrouped)) {
      return {};
    }
    const dataUnsorted = { ...tableData };
    if (sort === "" || sortBy === "") {
      return dataUnsorted;
    }
    const dataSorted = sortData(sort, sortBy, dataUnsorted);
    return dataSorted;
  }, [sort, sortBy, tableData]);

  const isAllSelectionRelatedItems = useMemo(() => {
    if (!allCodesSelectedIds || allCodesSelectedIds.length === 0) {
      return;
    }
    const tableDataCodesMap = getTableDataIsRelatedValues(tableData);
    const unrelatedCodeFounded = allCodesSelectedIds.some(
      (codeId) =>
        !isThisCodeRelated(codesMarkedMap[codeId], tableDataCodesMap[codeId])
    );
    if (unrelatedCodeFounded) {
      return false;
    }
    return true;
  }, [allCodesSelectedIds, codesMarkedMap, tableData]);

  const searchTypes = () => {
    return types.some((item) => item === codeTypeLabel);
  };

  const checkBoxChanged = useCallback(
    (event) => {
      const bool = typeof event === "boolean" ? event : event.target.checked;
      if (bool) {
        let newData = [...types];
        newData.push(codeTypeLabel);
        setTypes(newData);
      }
      if (!bool) {
        let newData = [...types];
        const index = newData.indexOf(codeTypeLabel);
        if (index > -1) {
          newData.splice(index, 1);
          setTypes(newData);
        }
      }
    },
    [types, codeTypeLabel]
  );

  function sortColumn(columnToSort) {
    if (sort === "" || sort === sortEnum.desc || sortBy !== columnToSort) {
      setSort(sortEnum.asc);
      setSortBy(columnToSort);
      return;
    }
    setSort(sortEnum.desc);
    setSortBy(columnToSort);
  }

  const handleCheckbox = useCallback(
    (isChecked, item, sectionId) => {
      if (isChecked) {
        setCodesSelected((prev) => {
          const group = prev[sectionId] || [];
          const groupUpdated = group.concat(item.id);
          return { ...prev, [sectionId]: groupUpdated };
        });
        return;
      }
      checkBoxChanged(false);
      setCodesSelected((prev) => {
        const group = prev[sectionId] || [];
        const groupUpdated = group.filter((id) => id !== item.id);
        return { ...prev, [sectionId]: groupUpdated };
      });
    },
    [checkBoxChanged]
  );

  function handleMarkCodes() {
    const markAsRelated = isAllSelectionRelatedItems ? false : true;
    const codesFormated = allCodesSelectedIds.reduce((acc, curr) => {
      return { ...acc, [curr]: markAsRelated };
    }, {});
    const codesMarkedUpdated = { ...codesMarkedMap, ...codesFormated };
    updateCodes(codesMarkedUpdated);
    setCodesSelected({});
  }

  const onSelectGroup = (event, sectionId, groupCodes) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const groupIds = groupCodes.map((code) => code.id);
      setCodesSelected((prev) => {
        return { ...prev, [sectionId]: groupIds };
      });
      return;
    }
    checkBoxChanged(false);
    setCodesSelected((prev) => {
      return { ...prev, [sectionId]: [] };
    });
  };

  function checkAll(event) {
    if (tableDataFiltered.grouped.length > 0) {
      for (let i = 0; i < tableDataFiltered.grouped.length; i++) {
        onSelectGroup(
          event,
          tableDataFiltered.grouped[i].sectionId,
          tableDataFiltered.grouped[i].grouped
        );
      }
    }
    if (tableDataFiltered.ungrouped.length > 0) {
      if (codesSelected.ungrouped?.length > 0) {
        tableDataFiltered.ungrouped.forEach((element) =>
          handleCheckbox(event.target.checked, element, "ungrouped")
        );
      } else {
        onSelectGroup(event, "ungrouped", tableDataFiltered.ungrouped);
      }
    }
  }

  const countingDataFiltered = useMemo(() => {
    let counter = 0;
    if (tableDataFiltered.grouped.length > 0) {
      tableDataFiltered.grouped.forEach(
        (member) => (counter = counter + member.grouped.length)
      );
      return counter;
    }
    if (tableDataFiltered.ungrouped.length > 0) {
      counter = counter + tableDataFiltered.ungrouped.length;
      return counter;
    }
    if (
      tableDataFiltered.grouped.length === 0 &&
      tableDataFiltered.ungrouped.length === 0
    )
      return -1;
  }, [tableDataFiltered]);

  const countingSelectedData = () => {
    let counter = 0;
    const codesSelectedArray = Object.values(codesSelected);
    if (tableDataFiltered.grouped?.length > 0) {
      codesSelectedArray.forEach((code) => {
        counter = counter + code.length;
      });
      if (codesSelected.ungrouped?.length > 0) {
        counter = counter - codesSelected.ungrouped.length;
      }
      return counter;
    }
    if (
      tableDataFiltered.ungrouped.length > 0 &&
      codesSelected.ungrouped?.length > 0 &&
      searchTypes()
    ) {
      counter = codesSelected.ungrouped.length;
      return counter;
    }
    return 0;
  };

  const compareData = () => {
    if (countingDataFiltered === -1) return false;
    return countingDataFiltered <= countingSelectedData();
  };
  return (
    <>
      <TableContainer
        className={"table"}
        sx={{ border: 1, borderRadius: 1, borderColor: "grey.400" }}
      >
        <Table size={"small"}>
          <TableHead className={"head"}>
            <TableRow>
              <TableCell className={"description-container"} align={"left"}>
                <Checkbox
                  onClick={checkAll}
                  checked={compareData()}
                  onChange={checkBoxChanged}
                />
                {firstColumnLabel}
              </TableCell>
              <TableCell className={"patients"} align={"center"}>
                <TableSortLabel
                  active={sortBy === sortByEnum.patients}
                  direction={sort}
                  onClick={() => sortColumn(sortByEnum.patients)}
                >
                  Patients
                </TableSortLabel>
              </TableCell>
              <TableCell className={"encounters"} align={"center"}>
                <TableSortLabel
                  active={sortBy === sortByEnum.encounters}
                  direction={sort}
                  onClick={() => sortColumn(sortByEnum.encounters)}
                >
                  Visits
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ bgcolor: "background.paper" }}>
            {tableDataFiltered.grouped &&
              tableDataFiltered.grouped.map((item) => {
                return (
                  <RowGroup
                    key={item.sectionId}
                    codesSelected={codesSelected[item.sectionId]}
                    codesMarkedMap={codesMarkedMap}
                    collapseTitle={item.description}
                    groupCodes={item.grouped}
                    onCheck={handleCheckbox}
                    onSelectGroup={onSelectGroup}
                    sectionId={item.sectionId}
                  />
                );
              })}
            {tableDataFiltered.ungrouped &&
              tableDataFiltered.ungrouped.length > 0 && (
                <>
                  {(!tableDataFiltered.grouped ||
                    tableDataFiltered.grouped.length === 0) &&
                    tableDataFiltered.ungrouped.map((item) => {
                      const isRelated = isThisCodeRelated(
                        codesMarkedMap[item.id],
                        item.isRelated
                      );
                      return (
                        <RowItem
                          key={item.id}
                          item={item}
                          isRelated={isRelated}
                          checked={(codesSelected.ungrouped || []).includes(
                            item.id
                          )}
                          onCheck={handleCheckbox}
                          sectionId={"ungrouped"}
                        />
                      );
                    })}
                  {tableDataFiltered.grouped &&
                    tableDataFiltered.grouped.length > 0 && (
                      <RowGroup
                        codesSelected={codesSelected.ungrouped || []}
                        codesMarkedMap={codesMarkedMap}
                        collapseTitle={"Ungrouped"}
                        groupCodes={tableDataFiltered.ungrouped}
                        onCheck={handleCheckbox}
                        onSelectGroup={onSelectGroup}
                        sectionId={"ungrouped"}
                      />
                    )}
                </>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <MarkCodesSnackbar
        allCodesSelectedIds={allCodesSelectedIds}
        handleMarkCodes={handleMarkCodes}
        isAllSelectionRelatedItems={isAllSelectionRelatedItems}
      />
    </>
  );
}
