import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";

export default function ListItemsSkeleton() {
  return (
    <>
      <PatientCardSkeleton />
      <PatientCardSkeleton />
      <PatientCardSkeleton />
      <PatientCardSkeleton />
      <PatientCardSkeleton />
      <PatientCardSkeleton />
      <PatientCardSkeleton />
      <PatientCardSkeleton />
    </>
  );
}

function PatientCardSkeleton() {
  return (
    <Card className={"patient-list-item-card"}>
      <CardActionArea>
        <CardContent>
          <Skeleton
            animation={"wave"}
            sx={{ mr: 2 }}
            variant="circular"
            height={40}
            width={40}
          />
          <div className="patient-list-right">
            <Typography variant={"body1"}>
              <Skeleton width={65} />
            </Typography>
            <Typography variant={"body2"}>
              <Skeleton width={140} />
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
