import React from "react";
import { Skeleton } from "@mui/material";

export default function SkeletonCard(name) {
  return (
    <div className={name}>
      <Skeleton className="skeleton" animation="wave" />
      <Skeleton className="skeleton" animation="wave" />
      <Skeleton className="skeleton" animation="wave" />
    </div>
  );
}
