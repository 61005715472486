import React, { useCallback, useEffect, useMemo, useState } from "react";
import ContactForMissingDevices from "./DeviceDetails/ContactForMissingDevices";
import SelectCategoryAndDevice from "./DeviceDetails/SelectCategoryAndDevice";
import { useFetch } from "../../../../services/hooks/useFetch";
import { debouncer, deviceApiPrefix } from "../../../../utils/main";
import { useFusionAuth } from "@fusionauth/react-sdk";

export default function DeviceDetails({
  canInputNewDevice,
  displayContent,
  displayErrors,
  onDisplayInputNewDevice,
  subStep,
  onUpdateData,
  projectData,
}) {
  const { data: devices, fetch: fetchDevices } = useFetch();
  const { data: categories, fetch: fetchCategories } = useFetch();
  const { data: manufacturers, fetch: fetchManufacturers } = useFetch();
  const [searchTerm, setSearchTerm] = useState("");
  const { userInfo } = useFusionAuth();
  const isWorkshop = userInfo?.roles?.includes("WORKSHOP");
  if (!projectData.deviceManufacturer) {
    projectData.deviceManufacturer =
      projectData.devices[0]?.deviceManufacturer || null;
  }

  const handleChangeSearchTerm = useCallback((event) => {
    setSearchTerm(event?.target?.value || null);
  }, []);

  const searchTermDebounce = useMemo(
    () => debouncer(handleChangeSearchTerm, 500),
    [handleChangeSearchTerm]
  );

  function onManufacturerChange(event, value) {
    onUpdateData({ deviceManufacturer: value });
  }

  function onCategoryChange(event, value) {
    onUpdateData({ deviceGmdn: value });
  }

  function onDevicesChange(event, value) {
    onUpdateData({ devices: value });
  }

  function onNewDeviceChange(event) {
    onUpdateData({ deviceModels: event.target.value });
  }

  function onOtherInformationChange(event) {
    onUpdateData({ otherInformation: event.target.value });
  }

  useEffect(() => {
    if (!isWorkshop || !searchTerm) return;
    fetchManufacturers(
      `${deviceApiPrefix}/devices/manufacturers?search=${
        "~" + searchTerm + "~"
      }`
    );
  }, [isWorkshop, fetchManufacturers, searchTerm]);

  //Get categories by company (based on user)
  useEffect(() => {
    if (isWorkshop) return;
    fetchCategories(`${deviceApiPrefix}/categories/`);
  }, [isWorkshop, fetchCategories]);

  //Get categories by mfr
  useEffect(() => {
    if (!isWorkshop || !projectData.deviceManufacturer) return;
    fetchCategories(
      `${deviceApiPrefix}/devices/manufacturers/${projectData.deviceManufacturer.id}/categories`
    );
  }, [isWorkshop, projectData.deviceManufacturer, fetchCategories]);

  //Get devices by mfr and category
  useEffect(() => {
    if (
      !isWorkshop ||
      !projectData.deviceManufacturer ||
      !projectData.deviceGmdn
    )
      return;
    fetchDevices(
      `${deviceApiPrefix}/devices/manufacturers/${projectData.deviceManufacturer.id}/categories/${projectData.deviceGmdn.id}`
    );
  }, [
    isWorkshop,
    projectData.deviceManufacturer,
    projectData.deviceGmdn,
    fetchDevices,
  ]);

  //Get devices by company and category (based on user)
  useEffect(() => {
    if (isWorkshop || !projectData.deviceGmdn) return;
    fetchDevices(
      `${deviceApiPrefix}/categories/${projectData.deviceGmdn.id}/devices`
    );
  }, [isWorkshop, projectData.deviceGmdn, fetchDevices]);

  if (!displayContent) {
    return null;
  }

  return (
    <section className="modal-list">
      {subStep && subStep.id === "inputNewDevice" ? (
        <ContactForMissingDevices
          displayErrors={displayErrors}
          onNewDeviceChange={onNewDeviceChange}
          onOtherInformationChange={onOtherInformationChange}
          projectData={projectData}
          subStep={subStep}
        />
      ) : (
        <SelectCategoryAndDevice
          canInputNewDevice={canInputNewDevice}
          isWorkshop={isWorkshop}
          manufacturers={manufacturers}
          categories={categories}
          devices={devices}
          projectData={projectData}
          displayErrors={displayErrors}
          searchTermDebounce={searchTermDebounce}
          onManufacturerChange={onManufacturerChange}
          onCategoryChange={onCategoryChange}
          onDevicesChange={onDevicesChange}
          onDisplayInputNewDevice={onDisplayInputNewDevice}
        />
      )}
    </section>
  );
}
