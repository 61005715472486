import {
  Breadcrumbs,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../shared/Footer";
import { useFetch } from "../../../services/hooks/useFetch";
import { deviceApiPrefix } from "../../../utils/main";
import { useFusionAuth } from "@fusionauth/react-sdk";

export default function CompanySettingsPage() {
  const [fetchRequired, setFetchRequired] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const { data: company, fetch: fetchCompanies } = useFetch();

  const { update: updateCompany } = useFetch();

  const [displayErrors, setDisplayErrors] = useState(false);

  const { userInfo } = useFusionAuth();
  const companyId = userInfo.companyId;

  useEffect(() => {
    fetchCompanies(`${deviceApiPrefix}/companies/${companyId}`);

    if (fetchRequired) setFetchRequired(false);
  }, [fetchCompanies, companyId, fetchRequired]);

  useEffect(() => {
    if (company && company.name) setCompanyName(company.name);

    if (fetchRequired) setFetchRequired(false);
  }, [company, fetchRequired]);

  const updateCompanyName = function (companyId, name) {
    updateCompany(
      `${deviceApiPrefix}/companies/update/${companyId}`,
      { name: name },
      "PUT"
    ).then(() => {
      setFetchRequired(true);
    });
  };

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              <Link underline="hover" color="inherit" to={"/"}>
                Global Settings
              </Link>
              <Typography>Company Settings</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Company Settings</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">
              Information about the companies.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          <Grid item>
            {editMode ? (
              <TextField
                required
                variant="outlined"
                label="Company Name"
                className="settings-text-input"
                sx={{ width: "50ch" }}
                value={companyName}
                InputProps={{
                  readOnly: !editMode,
                }}
                onChange={(event) => {
                  setCompanyName(event.target.value);
                }}
                error={displayErrors && companyName === ""}
                helperText={
                  displayErrors && companyName === ""
                    ? "This field is required"
                    : ""
                }
              />
            ) : (
              <>
                <Typography variant="caption">Company Name *</Typography>
                <Typography variant="body1" sx={{ width: "50ch" }}>
                  {companyName}
                </Typography>
              </>
            )}
          </Grid>
          <br />
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditMode(false);
                    setDisplayErrors(false);
                    setCompanyName(company.name);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    if (companyName !== "") {
                      if (companyName !== company.name) {
                        updateCompanyName(company.id, companyName);
                      }

                      setDisplayErrors(false);
                      setEditMode(false);
                    } else {
                      setDisplayErrors(true);
                    }
                  }}
                >
                  Update
                </Button>
              </>
            )) || (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Grid>
          <br />
          <Grid item>
            <Footer />
          </Grid>
          <br />
        </div>
      </Grid>
    </div>
  );
}
