import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../shared/Footer";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import { useFusionAuth } from "@fusionauth/react-sdk";

const promFormTypes = [
  {
    id: "KOOS",
    name: "KOOS",
  },
  {
    id: "KOOSJR",
    name: "KOOS JR",
  },
  {
    id: "OXFORD",
    name: "Oxford",
  },
];

const formatPatientReportedOutcomes = (promSetting) => {
  const forms =
    promSetting && promSetting.length > 0
      ? promSetting[0].value.split(",")
      : [];
  return forms.map((f) => {
    return promFormTypes.find((pft) => pft.id === f);
  });
};

export default function AdditionalDataPage({ projectId }) {
  const location = useLocation();

  const { fetch: fetchProject, data: project } = useFetch();
  const {
    data: promSetting,
    fetch: fetchProjectSettings,
    update: bulkUpdateProjectSettings,
  } = useFetch();
  const [patientReportedOutcomes, setPatientReportedOutcomes] = useState([]);
  const [updateDetails, setUpdateDetails] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [displayErrors, setDisplayErrors] = useState(false);

  const { userInfo } = useFusionAuth();
  const isAdmin = userInfo?.roles?.includes("ADMIN");

  useEffect(() => {
    fetchProject(`${apiPrefix}/projects/${projectId}`);
  }, [fetchProject, projectId]);

  useEffect(() => {
    fetchProjectSettings(
      `${apiPrefix}/projects/${projectId}/settings`,
      (settings) => {
        return settings.filter((s) => s.name === "PROM_FORMS");
      }
    );
  }, [projectId, fetchProjectSettings, setPatientReportedOutcomes]);

  useEffect(() => {
    setPatientReportedOutcomes(formatPatientReportedOutcomes(promSetting));
  }, [promSetting]);

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <div className="company-settings-item">
          <br />
          <Grid item>
            <Breadcrumbs>
              {location.state?.previousPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  to={location.state.previousPath}
                >
                  {project.name}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/details?projectId=${projectId}`}
                >
                  {project.name}
                </Link>
              )}
              <Link
                underline="hover"
                color="inherit"
                to={`/details?projectId=${projectId}`}
              >
                Project Settings
              </Link>
              <Typography>Additional Data</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Additional Data</Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h6">Patient Reported Outcomes</Typography>
            <Typography variant="body2">
              Select the types of Patient Reported Outcomes that you would like
              to include in this project.
            </Typography>
          </Grid>
          <Grid item>
            {editMode && isAdmin ? (
              <>
                <br />
                <Autocomplete
                  filterSelectedOptions
                  multiple
                  freeSolo
                  id={"select-proms"}
                  readOnly={!editMode || !isAdmin}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  options={promFormTypes}
                  value={patientReportedOutcomes}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  data-testid="AdditionalDataPage-autocompleteforms"
                  onChange={(event, value) => {
                    setPatientReportedOutcomes(value);
                  }}
                  ListboxProps={{
                    "data-testid": "AdditionalDataPage-promsPopper",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      variant="outlined"
                      label={"Patient Reported Outcomes"}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "AdditionalDataPage-proms",
                      }}
                    />
                  )}
                  sx={{ width: "50ch" }}
                />
              </>
            ) : (
              <>
                <br />
                <Typography variant="caption">
                  Patient Reported Outcomes
                </Typography>
                <Typography variant="body1" sx={{ width: "50ch" }}>
                  {patientReportedOutcomes &&
                    patientReportedOutcomes.map((pro) => pro.name).join(", ")}
                </Typography>
              </>
            )}
          </Grid>
          {editMode && isAdmin && (
            <>
              <br />
              <Grid item>
                <TextField
                  required
                  variant="outlined"
                  label="Note/Reason for Updating the Additional Data"
                  className="settings-text-input"
                  sx={{ width: "50ch" }}
                  value={updateDetails}
                  error={displayErrors && updateDetails === ""}
                  helperText={
                    displayErrors && updateDetails === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setUpdateDetails(event.target.value);
                  }}
                />
              </Grid>
              <br />
            </>
          )}
          <Grid container item justifyContent={"right"}>
            {(editMode && isAdmin && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setPatientReportedOutcomes(
                      formatPatientReportedOutcomes(promSetting)
                    );
                    setUpdateDetails("");
                    setDisplayErrors(false);
                    setEditMode(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    if (updateDetails === "") {
                      setDisplayErrors(true);
                      return;
                    }

                    const setting = {
                      id:
                        promSetting.length > 0 ? promSetting[0].id : undefined,
                      projectId: projectId,
                      name: "PROM_FORMS",
                      forDelete: patientReportedOutcomes.length === 0,
                      value: patientReportedOutcomes
                        .map((pro) => pro.id)
                        .join(","),
                    };

                    bulkUpdateProjectSettings(
                      `${apiPrefix}/projects/${projectId}/settings/bulk`,
                      {
                        reason: updateDetails,
                        settings: [setting],
                      },
                      "PUT"
                    ).then(() => {
                      setEditMode(false);
                      setUpdateDetails("");
                      fetchProjectSettings(
                        `${apiPrefix}/projects/${projectId}/settings`,
                        (settings) => {
                          return settings.filter(
                            (s) => s.name === "PROM_FORMS"
                          );
                        }
                      );
                    });
                  }}
                >
                  Update
                </Button>
              </>
            )) ||
              (isAdmin && (
                <Button variant="contained" onClick={() => setEditMode(true)}>
                  Edit
                </Button>
              ))}
          </Grid>
          <Footer />
        </div>
      </Grid>
    </div>
  );
}
