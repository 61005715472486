import { Checkbox, Chip, TableCell, TableRow, Typography } from "@mui/material";
import React, { memo } from "react";

export default memo(function RowItem({
  item,
  isRelated,
  checked,
  onCheck,
  sectionId,
}) {
  return (
    <TableRow className={"row"} sx={{ bgcolor: isRelated ? "" : "grey.200" }}>
      <TableCell className={"description-container"}>
        <div className={"container"}>
          <div className={"description"}>
            <Checkbox
              color={"primary"}
              checked={checked}
              onChange={(event) =>
                onCheck(event.target.checked, item, sectionId)
              }
            />
            <Chip label={item.code} size={"small"} variant={"outlined"} />
            <Typography
              className={isRelated ? "" : "unrelated-text"}
              variant={"body2"}
            >
              {item.description}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell className={"patients"} align={"center"}>
        <Typography variant={"body2"}>{item.patientCount || 0}</Typography>
      </TableCell>
      <TableCell className={"encounters"} align={"right"}>
        <Typography variant={"body2"}>{item.encounterCount || 0}</Typography>
      </TableCell>
    </TableRow>
  );
});
