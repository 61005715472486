import { Skeleton, TableCell, TableRow } from "@mui/material";

export const TableRowsSkeleton = ({ rowsNum, columnsNum }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(columnsNum)].map((_, index) => (
        <TableCell key={index} component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
};
