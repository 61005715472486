import React, { useMemo } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import ChoroplethUS from "../shared/charts/ChoroplethUS";

export default function GeographicBreakdown({ data }) {
  const { states } = data;
  const choroplethUSConfig = useMemo(() => {
    if (!states) {
      return;
    }
    return states.reduce((prev, curr) => {
      return { ...prev, [curr.state]: curr.count };
    }, {});
  }, [states]);
  return (
    <div id={"geographic-breakdown"} className="geographic-breakdown-container">
      <Typography variant="h6" align="left" gutterBottom>
        Geographic Breakdown
      </Typography>
      <Card>
        <CardContent>
          <div className="geographic-breakdown-container-graph">
            <ChoroplethUS data={choroplethUSConfig} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
