import { hasIncompleteKeywords } from "./Projects";

export function isDeviceSectionValid(projectObj) {
  if (!projectObj.deviceGmdn && projectObj.devices?.length === 0) {
    return false;
  }
  return projectObj.devices?.length !== 0;
}

export function isPrioritizationSectionValid(_, keywords) {
  return !hasIncompleteKeywords(keywords);
}

export function isProjectSectionValid(projectObj) {
  return projectObj.name.trim() !== "";
}

export function isInputNewDeviceValid(projectObj) {
  return projectObj.deviceModels;
}

export function isProtocolSectionValid(projectObj, _, minDate) {
  const startDate = projectObj.startDate;
  const endDate = projectObj.endDate;
  if (
    !startDate ||
    !endDate ||
    !projectObj.expectedPatientCount ||
    !projectObj.diagnosisBeforeDays ||
    !projectObj.procedureAfterDays
  ) {
    return false;
  }
  const startDateYear = startDate.getFullYear();
  const endDateYear = endDate.getFullYear();
  if (startDateYear < minDate.getFullYear()) {
    return false;
  }
  return endDateYear >= startDateYear;
}
