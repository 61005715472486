import React from "react";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { projectVersions } from "../../../utils/main";

export default function DrawerTitle({ version, mode, onClose }) {
  function getTitleText() {
    if (mode === "update") {
      return "Update Project Details";
    }
    if (version === projectVersions.premium) {
      return "Start a Project";
    }
    if (version === projectVersions.previewMode) {
      return "Start a Preview Project";
    }
    return "";
  }

  return (
    <div className="header">
      <Typography className="drawer-title" variant="h5">
        {getTitleText()}
      </Typography>
      <IconButton
        onClick={onClose}
        sx={{ "&:hover": { bgcolor: "action.hover" } }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
}
