import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useMemo } from "react";

export default function Description({ project }) {
  const startDate = project.startDate?.getUTCFullYear();
  const endDate = project.endDate?.getUTCFullYear();
  const description = useMemo(() => {
    if (!project || !project.devices) {
      return [];
    }
    return project.devices.map(
      // TODO: Add the model/version to match the format, example: (183123) - device_description
      (device) => `${device.description}`
    );
  }, [project]);

  return (
    <Card className="description-box">
      <CardHeader
        className={"description-card-header"}
        title={
          <Typography className={"header-title"}>Device Information</Typography>
        }
      />
      <CardContent className={"description-card-content"}>
        <Typography variant={"body1"}>{project.name}</Typography>
        <Typography variant={"body1"}>{description.join(", ")}</Typography>
        {/* <div className="description-chips-box">
          <Chip label="G01559" size="small" sx={{ borderRadius: 1 }} />
          <Chip label="Text" size="small" sx={{ borderRadius: 1 }} />
          <Chip label="Text" size="small" sx={{ borderRadius: 1 }} />
          <Chip label="+5 more" size="small" sx={{ borderRadius: 1 }} />
        </div> */}
        <Typography
          variant={"body1"}
        >{`Data from ${startDate} - ${endDate}`}</Typography>
      </CardContent>
    </Card>
  );
}
