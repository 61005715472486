import React from "react";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";

export default function PatientCardSkeleton() {
  return (
    <Card className={"patient-card"}>
      <CardContent align="left">
        <Typography className={"title"} variant="subtitle1">
          <Skeleton animation="wave" width={140} />
        </Typography>
        <div className={"content"}>
          <div className="column">
            <Typography className={"categorization-title"} variant="body2">
              <Skeleton animation="wave" width={125} />
            </Typography>
            <div className="row">
              <Typography variant="body2">
                <Skeleton animation="wave" width={70} />
              </Typography>
            </div>
            <div className="row">
              <Typography variant="body2">
                <Skeleton animation="wave" width={70} />
              </Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
