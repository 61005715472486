import FetchUtils from "../utils/FetchUtils";
import { deviceApiPrefix } from "../utils/main";

export async function EmailDomain(domain) {
  const path = `${deviceApiPrefix}/user/validate?domain=${domain}`;

  try {
    const response = await FetchUtils.fetch(path, "GET");
    return await response.json();
  } catch (error) {
    console.error("Error: ", error.toString());
    return error;
  }
}
