class FetchUtils {
  static fetch(path, method, body) {
    return fetch(path, {
      signal: null,
      method: method || "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        data: "JSON",
      },
      credentials: "include",
      body: body ? JSON.stringify(body) : null,
    }).then((response) => {
      return response.ok
        ? response
        : Promise.reject(response.status.toString());
    });
  }
}

export default FetchUtils;
