import React from "react";
import { Chip, Typography } from "@mui/material";

export default function CodesChips({ codes, chipSize }) {
  return codes.map((item, index) => {
    return (
      <div key={`${item.code}-${index}`} className={"code"}>
        <Chip label={item.code} size={chipSize} variant={"outlined"} />
        <Typography className={"text"} varaint={"body2"}>
          {item.description}
        </Typography>
      </div>
    );
  });
}
