import React, { useState, useMemo } from "react";
import {
  Button,
  ButtonGroup,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CodesTable from "./codestable/CodesTable";
import { debouncer } from "../../../utils/main";

const statusFilterEnum = {
  all: "All Codes",
  related: "Related Codes",
  unrelated: "Unrelated Codes",
};

export default function CodesLayout({
  helpText,
  searchLabel,
  codeTypeLabel,
  codeTypeOptions,
  defaultCodeType,
  tableData,
  firstColumnLabel,
  codesMarkedMap,
  setSearchValue,
  setType,
  updateCodes,
  allChecked,
  setAllChecked,
}) {
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [codeFilter, setCodeFilter] = useState(defaultCodeType);
  const searchDebounce = useMemo(
    () => debouncer(setSearchValue, 500),
    [setSearchValue]
  );

  function handleSearch(event) {
    setAllChecked(false);
    const searchValue = event.target.value;
    setSearch(searchValue);
    searchDebounce(searchValue);
  }

  function handleStatusFilter(status) {
    setStatusFilter(status);
  }

  function onCodeFilterChange(event) {
    setAllChecked(false);
    const selectValue = event.target.value;
    setCodeFilter(selectValue);
    setType(selectValue);
  }

  function getStatusCount(statusValue) {
    if (statusValue === statusFilterEnum.all) {
      return tableData.relatedCount + tableData.unrelatedCount;
    }
    if (statusValue === statusFilterEnum.related) {
      return tableData.relatedCount;
    }
    return tableData.unrelatedCount;
  }

  function combineGroupedArrays(related, unrelated) {
    let tempRelated = new Set([]);
    let tempUnrelated = new Set(unrelated);
    related.forEach((relatedItem) => {
      let currentValue = false;
      tempUnrelated.forEach((unrelatedItem) => {
        if (relatedItem.sectionId === unrelatedItem.sectionId) {
          tempRelated.add({
            ...relatedItem,
            grouped: relatedItem.grouped.concat(unrelatedItem.grouped),
          });
          currentValue = true;
          tempUnrelated.delete(unrelatedItem);
          return;
        } else {
          currentValue = false;
        }
      });
      if (!currentValue) {
        tempRelated.add(relatedItem);
      }
    });
    return Array.from(tempRelated).concat(Array.from(tempUnrelated));
  }

  function convertBasedOnStatus(status, data) {
    let newData = {
      grouped: [],
      ungrouped: [],
    };
    if (status === "all") {
      newData.grouped = combineGroupedArrays(
        data.related.grouped,
        data.unrelated.grouped
      );
      newData.ungrouped = data.related.ungrouped.concat(
        data.unrelated.ungrouped
      );
      return newData;
    }
    if (status === "related") {
      newData.grouped = data.related.grouped;
      newData.ungrouped = data.related.ungrouped;
      return newData;
    }
    if (status === "unrelated") {
      newData.grouped = data.unrelated.grouped;
      newData.ungrouped = data.unrelated.ungrouped;
      return newData;
    }
  }

  return (
    <div className={"codes-layout"}>
      <div className={"help-text"}>
        <InfoOutlinedIcon sx={{ color: "action.active" }} />
        <Typography variant={"body2"} color={"text.secondary"}>
          {helpText}
        </Typography>
      </div>
      <ButtonGroup className={"buttons"}>
        {Object.keys(statusFilterEnum).map((filter) => {
          const statusFilterValue = statusFilterEnum[filter];
          const countValue = getStatusCount(statusFilterValue);
          return (
            <Button
              key={filter}
              disableElevation
              onClick={() => handleStatusFilter(filter)}
              variant={statusFilter === filter ? "contained" : "outlined"}
            >
              {statusFilterValue}
              <span className={"count"}>({countValue})</span>
            </Button>
          );
        })}
      </ButtonGroup>
      <div className={"inputs"}>
        <TextField
          label={searchLabel}
          size={"small"}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          value={search}
          variant={"outlined"}
        />
        {/* TO-DO: unhide when we want it to work
        <TextField
          label={selectLabel}
          select
          size={"small"}
          value={daysFilter}
          onChange={onDaysFilterChange}
          InputLabelProps={{ shrink: true }}
          variant={"outlined"}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
        <TextField
          label={codeTypeLabel}
          select
          size={"small"}
          value={codeFilter}
          onChange={onCodeFilterChange}
          InputLabelProps={{ shrink: true }}
          variant={"outlined"}
        >
          {codeTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <CodesTable
        firstColumnLabel={firstColumnLabel}
        tableData={convertBasedOnStatus(statusFilter, tableData)}
        codesMarkedMap={codesMarkedMap}
        updateCodes={updateCodes}
        setAllChecked={setAllChecked}
        allChecked={allChecked}
        codeTypeLabel={codeFilter}
      />
    </div>
  );
}
