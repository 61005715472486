import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NavBar from "../shared/NavBar";
import ProjectHeader from "../shared/ProjectHeader";
import DateRange from "../shared/DateRange/DateRange";
import Autocomplete from "@mui/material/Autocomplete";
import theme from "../../styles/theme";
import { adminBaseUrl, getDevices } from "../../services/Counts";
import { debouncer } from "../../utils/main";
import Footer from "../shared/Footer";
import { useFetch } from "../../services/hooks/useFetch";
import { useFusionAuth } from "@fusionauth/react-sdk";
import JumpTo from "../details/JumpTo";
import PreviewProjectCreated from "../details/PreviewProjectCreated";
import ProjectMetricCards from "../details/projectmetriccards/ProjectMetricCards";
import PatientEncounters from "../details/PatientEncounters";

const projectData = {
  name: "3Aware Device Counts",
};

export default function AdminCounts() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [manufacturer, setManufacturer] = useState("");
  const [devices, setDevices] = useState([]);
  const [unfound, setUnfound] = useState([]);
  const startDatePlaceholder = new Date("Jul 12 2018");
  const endDatePlaceholder = new Date("Jul 12 2023");
  const [startDate, setStartDate] = useState(startDatePlaceholder);
  const [endDate, setEndDate] = useState(endDatePlaceholder);
  const [searchDevice, setSearchDevice] = useState("");
  const [searchManufacturers, setSearchManufacturers] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: dataManufacturers, fetch: fetchManufacturers } = useFetch();
  const { data: dataDevices, fetch: fetchDevices } = useFetch();
  const { data, fetch: fetchSummaries } = useFetch();
  const { startLogout } = useFusionAuth();

  function handleChangeSearch(event) {
    setSearchDevice(event.target.value);
  }

  const handleChangeSearchManufacturers = useCallback((event) => {
    setSearchManufacturers(event.target.value);
  }, []);

  const searchManufacturerDebounce = useMemo(
    () => debouncer(handleChangeSearchManufacturers, 500),
    [handleChangeSearchManufacturers]
  );

  function handleStartDate(date) {
    setStartDate(date);
  }

  function handleEndDate(date) {
    setEndDate(date);
  }

  useEffect(() => {
    fetchManufacturers(
      `${adminBaseUrl}/aggregate/manufacturers?search=${
        "~" + searchManufacturers + "~"
      }`
    );
  }, [fetchManufacturers, searchManufacturers]);

  useEffect(() => {
    if (!manufacturer) {
      return;
    }
    fetchDevices(
      `${adminBaseUrl}/aggregate/devices?manufacturer=${searchManufacturers}&search=${searchDevice}`
    );
  }, [fetchDevices, manufacturer, searchManufacturers, searchDevice]);

  function ApplyFilters() {
    const startYear = getYear(startDate);
    const endYear = getYear(endDate);
    fetchSummaries(
      `${adminBaseUrl}/aggregate/counts?manufacturer=${manufacturer}&devices=${devices}&fromYear=${startYear}&toYear=${endYear}`
    );
  }

  function handleChangeDevices(_, newValue) {
    setUnfound([]);
    setSearchDevice("");
    setDevices(newValue);
  }

  function handleChangeManufacturers(_, newValue) {
    setManufacturer(newValue);
  }

  const getYear = (date) => {
    return date.getFullYear();
  };

  if (searchDevice === undefined) {
    setSearchDevice("");
  }

  function handleOnPaste(event) {
    if (loading) {
      return;
    }
    setLoading(true);
    setSearchDevice(event.clipboardData.getData("text"));
    let unFoundData = [];
    let newData = [];
    if (event.clipboardData.getData("text").length >= 1) {
      if (!manufacturer) {
        return;
      }
      if (searchDevice === undefined) {
        return;
      }
      const pastedData = event.clipboardData
        .getData("text")
        .trim()
        .replace(/\s/g, "")
        .split(",");

      pastedData.forEach((element, index) => {
        getDevices(manufacturer, element)
          .then((data) => {
            if (data[0] === element) {
              newData.push(element);
              setDevices(newData);
            } else if (data.length > 1 || data.length === 0) {
              unFoundData.push(element);
            }
            if (index === pastedData.length - 1) {
              setLoading(false);
            }
            setSearchDevice("");
            setUnfound(unFoundData);
          })
          .catch((err) => {
            if (err === "401" || err === "403") {
              void startLogout();
            }
          });
      });
    }
  }

  return (
    <div className="App">
      <NavBar />
      <div className="details-container">
        <ProjectHeader
          project={projectData}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          disabled={true}
          disabledIcon={true}
        />
        <Divider light />
        <Box className="description-body">
          <Stack direction={"column"} className={"description-left-panel"}>
            <Alert
              severity="info"
              className="description-left-panel-alert"
              title="true"
            >
              <div
                className="preview-info-alert-content"
                data-testid="PreviewModeOverview-text"
              >
                This is a Preview Project with access only to aggregate data.
                For access to all features and more detailed patient data,
                please{" "}
                <Link
                  className="preview-info-alert-content-link"
                  component={"button"}
                >
                  contact us
                </Link>{" "}
                to learn more about upgrading this project.
              </div>
            </Alert>
            <JumpTo version={"Preview Mode"} />
          </Stack>
          <Stack direction={"column"} className="description-right-panel">
            <FormControl
              size="small"
              fullWidth
              className="description-right-panel-first-row"
            >
              <Autocomplete
                disablePortal
                value={manufacturer}
                options={dataManufacturers}
                onChange={handleChangeManufacturers}
                onInputChange={searchManufacturerDebounce}
                freeSolo
                sx={{ width: "75%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Manufacturer" />
                )}
              />
              <DateRange
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
              />
            </FormControl>
            <FormControl
              size="small"
              fullWidth
              className="description-right-panel-second-row"
            >
              <Autocomplete
                className="counts-autocomplete"
                disablePortal
                multiple
                disabled={
                  loading || manufacturer === null || manufacturer === ""
                }
                inputValue={searchDevice}
                loading={loading}
                value={devices}
                onPaste={handleOnPaste}
                options={dataDevices}
                onChange={handleChangeDevices}
                onInputChange={handleChangeSearch}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Devices" />
                )}
              />
              {unfound.length > 0 && (
                <FormHelperText error>
                  No results found for {unfound.join(", ")}
                </FormHelperText>
              )}
              <Button
                sx={{ bgcolor: theme.palette.primary.main }}
                className="description-right-panel-button"
                onClick={ApplyFilters}
              >
                Apply Filters
              </Button>
            </FormControl>
            <div className="stats-container">
              {data.totalPatients < 10 ? (
                <PreviewProjectCreated />
              ) : (
                <ProjectMetricCards data={data} forAdmin={true} />
              )}
            </div>
            {data.totalPatients >= 10 && <PatientEncounters data={data} />}
          </Stack>
        </Box>
        <Footer />
      </div>
    </div>
  );
}
