import React from "react";
import { Link } from "react-router-dom";
// import { Colors } from "../../styles/materialStyles/colors";
import theme from "../../styles/theme";
import {
  Chip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TableCell,
  Link as MuiLink,
  // Checkbox,
} from "@mui/material";
// import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
// import { useAuthCredentials } from "../../services/hooks/useAuthCredentials";

export default function TableComponent({ projects }) {
  // const [command, setCommand] = useState(0);

  // TO-DO: unhide when we want it to work
  // const orderById = () => {
  //   if (command === 0) {
  //     const projectsCopy = [...rows];
  //     const rowup = projectsCopy.sort((a, b) => {
  //       return -1;
  //     });
  //     setRows(rowup);
  //     setCommand(1);
  //   } else {
  //     const projectsCopy = [...rows];
  //     const rowup = projectsCopy.sort((a, b) => {
  //       return 1;
  //     });
  //     setRows(rowup);
  //     setCommand(0);
  //   }
  // };

  // TO-DO: unhide when we want it to work
  // const orderByStatus = () => {
  //   if (command === 0) {
  //     const projectsCopy = [...rows];
  //     const rowup = projectsCopy.sort((a, b) => {
  //       return -1;
  //     });
  //     setRows(rowup);
  //     setCommand(1);
  //   } else {
  //     const projectsCopy = [...rows];
  //     const rowup = projectsCopy.sort((a, b) => {
  //       return 1;
  //     });
  //     setRows(rowup);
  //     setCommand(0);
  //   }
  // };

  // TO-DO: unhide when we want it to work
  // const orderByDevice = () => {
  //   if (command === 0) {
  //     const projectsCopy = [...rows];
  //     const rowup = projectsCopy.sort((a, b) => {
  //       return -1;
  //     });
  //     setRows(rowup);
  //     setCommand(1);
  //   } else {
  //     const projectsCopy = [...rows];
  //     const rowup = projectsCopy.sort((a, b) => {
  //       return 1;
  //     });
  //     setRows(rowup);
  //     setCommand(0);
  //   }
  // };

  return (
    <div className={"table-box"}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow key="1">
              <TableCell>
                {/*TO-DO: unhide when we want it to work
                 <Checkbox
                  color={"primary"}
                  className={"project-checkbox"}
                /> */}
                Project Name
                {/* TO-DO: unhide when we want it to work
                <ArrowUpwardOutlinedIcon
                  sx={{ color: Colors.tableColors.black }}
                  onClick={orderById}
                  className={"arrow-button"}
                /> */}
              </TableCell>
              <TableCell>
                Status
                {/* TO-DO: unhide when we want it to work
                <ArrowUpwardOutlinedIcon
                  onClick={orderByStatus}
                  sx={{ color: Colors.tableColors.black }}
                  className={"arrow-button"}
                /> */}
              </TableCell>
              <TableCell>
                Device Name
                {/* TO-DO: unhide when we want it to work
                <ArrowUpwardOutlinedIcon
                  onClick={orderByDevice}
                  sx={{ color: Colors.tableColors.black }}
                  className={"arrow-button"}
                /> */}
              </TableCell>
              <TableCell>
                Patient Records
                {/* TO-DO: unhide when we want it to work
                <ArrowUpwardOutlinedIcon
                  sx={{ color: Colors.tableColors.black }}
                  className={"arrow-button"}
                /> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => {
              const descriptions = project.devices?.map(
                (device) => device.description
              );
              return (
                <TableRow key={project.id}>
                  <TableCell>
                    {/*TO-DO: unhide when we want it to work
                     <Checkbox
                      color={"primary"}
                      className={"project-checkbox"}
                    /> */}
                    <MuiLink
                      component={Link}
                      to={`/details?projectId=${project.id}`}
                      variant="body2"
                    >
                      {project.name}
                    </MuiLink>
                  </TableCell>
                  <TableCell>
                    <Chip label={project.statusName} size="small" />
                  </TableCell>
                  <TableCell>{descriptions?.join(", ")}</TableCell>
                  <TableCell sx={{ color: theme.palette.link.main }}>
                    {project.patientCount != null && project.patientCount >= 10
                      ? project.patientCount
                      : "<10"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
