import React from "react";
import { Typography } from "@mui/material";

export default function BulletLegend({ text, bulletColor, classes = {} }) {
  return (
    <div className="chart-bullet-point">
      <span
        style={{
          backgroundColor: bulletColor,
        }}
      />
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
}
