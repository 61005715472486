import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ConfirmationWithCompanyName() {
  const navigate = useNavigate();

  function handleGoTo() {
    navigate("/login");
  }

  return (
    <div className="authentication">
      <form className="login-form">
        <Typography variant="h6">Your message has been sent!</Typography>
        <Typography variant="body2">
          Thank you for submitting your information. We are reviewing it and
          will contact you as soon as possible to discuss next steps.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="return"
          onClick={handleGoTo}
        >
          Go to Log In
        </Button>
      </form>
    </div>
  );
}
