import { useCallback, useMemo, useRef } from "react";

// Use this hook only if you want to save query params without re mounting the components
// the changes made with window are not tracked by the useHistory hook, you need to refresh the page so useHistory'll have access to the query params saved.
export function useWindowQueryParams() {
  const queryParams = useRef(new URLSearchParams(window.location.search));
  const getWindowQueryParams = useMemo(() => {
    return Object.fromEntries(queryParams.current);
  }, []);

  const setWindowQueryParams = useCallback((param, value) => {
    const url = new URL(window.location);
    queryParams.current.set(param, value);
    url.search = queryParams.current.toString();
    window.history.pushState(null, "", url.toString());
  }, []);

  const deleteWindowQueryParam = useCallback((param) => {
    const url = new URL(window.location);
    queryParams.current.delete(param);
    url.search = queryParams.current.toString();
    window.history.pushState(null, "", url.toString());
  }, []);

  return { getWindowQueryParams, setWindowQueryParams, deleteWindowQueryParam };
}
