import React from "react";
import { IconButton, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const categories = [
  { value: "adverse_event", label: "Adverse Event" },
  { value: "performance", label: "Performance" },
  { value: "clinical", label: "Clinical Benefit" },
];

export default function KeywordRow({
  keyword,
  handlePhraseChange,
  handleCategoryChange,
  handleDeleteItem,
  displayRequiredError,
}) {
  return (
    <div className="keyword-row" key={keyword.id}>
      <TextField
        className="important-keywords-inputs"
        error={displayRequiredError && !keyword.phrase}
        label="Keyword"
        onChange={handlePhraseChange}
        required
        size="small"
        value={keyword.phrase}
      />
      <TextField
        className="important-keywords-inputs"
        error={displayRequiredError && !keyword.category}
        label="Category"
        onChange={handleCategoryChange}
        required
        select
        size="small"
        value={keyword.category}
      >
        {categories.map((category) => {
          return (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          );
        })}
      </TextField>
      <div className="remove-icon">
        <IconButton onClick={handleDeleteItem} size="small">
          <CloseIcon color={"error"} fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
}
