import React from "react";
import { Doughnut } from "react-chartjs-2";

const labelTooltip = (tooltipData) => {
  return `${tooltipData.label.trim()}: ${
    tooltipData.formattedValue || 0
  } Patients`;
};

const doughnutOptions = {
  cutout: "75%",
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: labelTooltip,
      },
    },
  },
};
export default function BreakdownByGender({ data }) {
  return <Doughnut data={data} options={doughnutOptions} />;
}
