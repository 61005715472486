import { useCallback, useState } from "react";
import { useFusionAuth } from "@fusionauth/react-sdk";

const no_data_response_codes = [202, 204];

export function useFetch() {
  const { startLogout } = useFusionAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [controller] = useState(new AbortController());

  const makeCall = useCallback(
    async ({
      path,
      body = undefined,
      method = "GET",
      responseManipulationFunction = undefined,
      postResponseAction = undefined,
    }) => {
      setIsLoading(true);
      setStatus(null);
      setError(null);
      try {
        const response = await fetch(path, {
          signal: controller.signal,
          method: method,
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            data: "JSON",
          },
          credentials: "include",
          body: body ? JSON.stringify(body) : null,
        });
        if (controller.signal.aborted) return;
        setStatus(response.status);
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            void startLogout();
          }
          if (response.status === 404) {
            setError("No data found");
          } else {
            let error = await response.json();
            setError(error);
            // setError(response.body.toString());
          }
        } else if (!no_data_response_codes.includes(response.status)) {
          const contentLength = response.headers.get("Content-Length");
          if (contentLength && parseInt(contentLength) > 0) {
            let data = await response.json();
            if (data) {
              if (responseManipulationFunction) {
                data = responseManipulationFunction(data);
              }
              setData(data);
            }
          }
        }
        setIsLoading(false);
        if (postResponseAction) {
          postResponseAction();
        }
      } catch (err) {
        console.error(err);
      }

      return () => {
        controller.abort();
      }; // added
    },
    [startLogout, controller]
  );

  const fetchData = useCallback(
    (
      path,
      responseManipulationFunction = undefined,
      postResponseAction = undefined
    ) => {
      return makeCall({
        path,
        responseManipulationFunction,
        postResponseAction,
      });
    },
    [makeCall]
  );

  const createData = useCallback(
    (
      path,
      body,
      method = "POST",
      responseManipulationFunction = undefined,
      postResponseAction = undefined
    ) => {
      return makeCall({
        path,
        body,
        method,
        responseManipulationFunction,
        postResponseAction,
      });
    },
    [makeCall]
  );

  const updateData = useCallback(
    (
      path,
      body,
      method = "PUT",
      responseManipulationFunction = undefined,
      postResponseAction = undefined
    ) => {
      return makeCall({
        path,
        body,
        method,
        responseManipulationFunction,
        postResponseAction,
      });
    },
    [makeCall]
  );

  const deleteData = useCallback(
    (
      path,
      body,
      method = "DELETE",
      responseManipulationFunction = undefined,
      postResponseAction = undefined
    ) => {
      return makeCall({
        path,
        body,
        method,
        responseManipulationFunction,
        postResponseAction,
      });
    },
    [makeCall]
  );

  const abortCall = useCallback(
    (reason) => {
      return controller.abort(reason);
    },
    [controller]
  );

  const resetData = useCallback(() => {
    setStatus(null);
    setData([]);
    setIsLoading(false);
    setError(null);
  }, []);

  return {
    abort: abortCall,
    data,
    error,
    isLoading,
    status,
    fetch: fetchData,
    create: createData,
    update: updateData,
    delete: deleteData,
    reset: resetData,
  };
}
