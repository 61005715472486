import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Link,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import CodesChips from "./CodesChips";
import VisitsDrawer from "./VisitsDrawer";
import IndexProcedureVisitCardSkeleton from "./skeletons/IndexProcedureVisitCardSkeleton";
import { formatEncounter, updatePatientVisits } from "../../../models/patient";
import StructuredDataExpandTable from "../../shared/StructuredDataExpandTable";
import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { CurrentPatient, CurrentProject } from "../ProjectDetails";

export default function IndexProcedureVisitCard({
  patientId,
  patient,
  patientLoading,
  updatePatient,
  updatePatientList,
}) {
  const {
    isOtherDevicesUsedUpdated,
    setIsOtherDevicesUsedUpdated,
    otherDevicesUsed,
    setOtherDevicesUsed,
    indexEncounter,
    setIndexEncounter,
    isIndexEncounterUpdated,
    setIsIndexEncounterUpdated,
    isEncountersUpdated,
  } = useContext(CurrentPatient);
  const { projectSettings } = useContext(CurrentProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const refreshIndexProcedure = searchParams.get("refreshIndexProcedure");
  const projectId = searchParams.get("projectId");

  const [open, setOpen] = useState(false);
  const [openOtherDevicesUsed, setOpenOtherDevicesUsed] = useState(false);

  const {
    isLoading,
    data: otherDevicesUsedData,
    fetch: fetchOtherDevicesUsed,
  } = useFetch();
  const { isLoading: isEncounterLoading, fetch: fetchEncounter } = useFetch();

  function toggleOpen() {
    setOpen((prev) => !prev);
  }

  const removeSearchParam = useCallback(
    (param) => {
      searchParams.delete(param);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleOtherDevicesUsedLoad = useCallback(() => {
    setOpenOtherDevicesUsed(!openOtherDevicesUsed);
  }, [openOtherDevicesUsed]);

  useEffect(() => {
    if (openOtherDevicesUsed && !isOtherDevicesUsedUpdated) {
      fetchOtherDevicesUsed(
        `${projectsPrefix}/${projectId}/patients/${patientId}/other_devices`
      ).then((_) => {
        setOtherDevicesUsed(otherDevicesUsedData);
        setIsOtherDevicesUsedUpdated(true);
      });
    }
  }, [
    fetchOtherDevicesUsed,
    openOtherDevicesUsed,
    patientId,
    projectId,
    otherDevicesUsed,
    setOtherDevicesUsed,
    otherDevicesUsedData,
    isOtherDevicesUsedUpdated,
    setIsOtherDevicesUsedUpdated,
  ]);

  const getIndexProcedure = useCallback(() => {
    setIsIndexEncounterUpdated(false);
    fetchEncounter(
      `${projectsPrefix}/${projectId}/patients/${patientId}/encounters/index`,
      (data) => {
        setIndexEncounter(formatEncounter(data));
        setIsIndexEncounterUpdated(true);
      }
    );
  }, [
    patientId,
    projectId,
    fetchEncounter,
    setIndexEncounter,
    setIsIndexEncounterUpdated,
  ]);

  useEffect(() => {
    if (refreshIndexProcedure) {
      removeSearchParam("refreshIndexProcedure");
      return;
    }
    if (!patientId) {
      return;
    }
    if (isEncountersUpdated && !isIndexEncounterUpdated) {
      getIndexProcedure();
    }
  }, [
    projectId,
    patientId,
    refreshIndexProcedure,
    removeSearchParam,
    getIndexProcedure,
    isEncountersUpdated,
    isIndexEncounterUpdated,
  ]);

  function handleVisitListUpdate(data) {
    const visitsUpdated = updatePatientVisits(patient.encounters, data);
    updatePatient({ encounters: visitsUpdated });
  }
  const [imdrfEnabled, setImdrfEnabled] = useState(true);

  useEffect(() => {
    if (projectSettings) {
      const imdrfSetting = projectSettings.find(
        (s) => s.name === "DISABLE_IMDRF"
      );
      setImdrfEnabled(
        !imdrfSetting ||
          imdrfSetting.value === "" ||
          imdrfSetting.value === "FALSE"
      );
    }
  }, [projectSettings]);

  // Reset component states when the patient changes
  useEffect(() => {
    if (patientLoading) {
      setOpenOtherDevicesUsed(false);
    }
  }, [patientLoading, setOpenOtherDevicesUsed]);

  const descriptions = patient.devices?.map((item) => item.description);

  const otherDevicesDataTableFields = [
    { field: "manufacturerName", headerName: "MFR", width: 250 },
    { field: "catalogNumber", headerName: "MFR Cat #", width: 100 },
    { field: "supplyName", headerName: "Name", width: 150 },
    { field: "suppliesUsed", headerName: "Used", width: 100 },
    { field: "suppliesWasted", headerName: "Wasted", width: 100 },
    { field: "itemTypeName", headerName: "Type", width: 200 },
  ];

  if (
    isEncounterLoading ||
    patientLoading ||
    !patient ||
    !patient.devices ||
    !isIndexEncounterUpdated
  ) {
    return <IndexProcedureVisitCardSkeleton />;
  }

  return (
    <>
      {open && isIndexEncounterUpdated && (
        <VisitsDrawer
          toggleDrawer={() => toggleOpen()}
          patient={patient}
          updatedList={handleVisitListUpdate}
          visitId={indexEncounter.id}
          updatePatientList={updatePatientList}
          imdrfEnabled={imdrfEnabled}
        />
      )}
      {isIndexEncounterUpdated && (
        <div className={"index-procedure-visit-container"}>
          <Card className={"index-procedure-visit-card"}>
            <CardHeader
              title={
                <Typography
                  className="card-title"
                  align={"left"}
                  variant={"h5"}
                >
                  Index Procedure Visit
                </Typography>
              }
              action={
                <Link
                  className={"index-procedure-link"}
                  component={"button"}
                  variant={"subtitle2"}
                  onClick={toggleOpen}
                >
                  Open Visit Details
                </Link>
              }
            ></CardHeader>
            <CardContent
              classes={{ root: "content" }}
              sx={{ padding: 0 }}
              align={"left"}
            >
              <Stack spacing={2}>
                <div className={"section"}>
                  <Typography className={"visit-date-label"} variant="h6">
                    Procedure Year
                  </Typography>
                  <Typography className={"visit-date"} variant={"body1"}>
                    {indexEncounter.arriveDate?.getUTCFullYear()}
                  </Typography>
                </div>
                <div className="device-details">
                  <Typography className="device-details-title" variant="h6">
                    Device Details
                  </Typography>
                  <Typography variant="body2" className="device-details-normal">
                    Version / Model #:{" "}
                    <Box component="span" fontWeight="bold" fontSize="16px">
                      {descriptions.join(", ")}
                    </Box>
                  </Typography>
                  <div className="device-details-used">
                    <Typography
                      variant="body2"
                      className="device-details-normal"
                    >
                      Devices Used:{" "}
                      <Box component="span" fontWeight="bold" fontSize="16px">
                        {indexEncounter.suppliesUsed}
                      </Box>
                    </Typography>
                    <Typography
                      variant="body2"
                      className="device-details-normal"
                    >
                      Devices Wasted:{" "}
                      <Box component="span" fontWeight="bold" fontSize="16px">
                        {indexEncounter.suppliesWasted}
                      </Box>
                    </Typography>
                  </div>
                </div>
                <StructuredDataExpandTable
                  loading={isLoading}
                  title={"Other Devices Used"}
                  count={indexEncounter?.otherDevicesCount}
                  handleChange={handleOtherDevicesUsedLoad}
                  fields={otherDevicesDataTableFields}
                  rows={otherDevicesUsedData}
                />
                <div className={"section diagnosis"}>
                  <Typography className="section-title" variant="h6">
                    Diagnoses
                  </Typography>
                  {indexEncounter.diagnosisCodes &&
                    indexEncounter.diagnosisCodes.length > 0 && (
                      <CodesChips
                        codes={indexEncounter.diagnosisCodes}
                        chipSize={"small"}
                      />
                    )}
                </div>
                <div className={"section procedure"}>
                  <Typography className="section-title" variant="h6">
                    Procedures
                  </Typography>
                  {indexEncounter.procedureCodes &&
                    indexEncounter.procedureCodes.length > 0 && (
                      <CodesChips
                        codes={indexEncounter.procedureCodes}
                        chipSize={"small"}
                      />
                    )}
                </div>
              </Stack>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
}
