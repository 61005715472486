import React from "react";
import Marketing from "../shared/Marketing";
import Confirmation from "../authentication/Confirmation";

export default function ConfirmationLayout() {
  return (
    <div className="confirmation-layout">
      <Marketing />
      <Confirmation />
    </div>
  );
}
