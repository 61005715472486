import React, { useMemo, useState } from "react";
import { projectsPrefix } from "../../../services/ProjectsServices";
import {
  filterKeywords,
  formatUpsertProjectData,
} from "../../../models/Projects";
import {
  apiPrefix,
  convertDate,
  dateToLocalIgnoringOffset,
  projectVersions,
} from "../../../utils/main";
import EditProject from "./EditProject";
import { useFetch } from "../../../services/hooks/useFetch";

export default function UpdateProject({
  defaultValues,
  onUpdate,
  onCancel,
  onCreate,
}) {
  const [reason, setReason] = useState("");
  const { update: bulkUpdateKeywords } = useFetch();

  // In the client the datePicker works with local dates.
  // Example: User have an offset of -6 hours,
  // 2013-01-01 UTC 0 ==Ignoring Offset==>  2013-01-01 UTC -6
  // 2013-01-01 UTC 0 ==Without Ignoring Offset==>  2012-12-31 18:00:00 UTC -6
  const defaultValuesParsed = useMemo(() => {
    const defaultStartDate = dateToLocalIgnoringOffset(defaultValues.startDate);
    const defaultEndDate = dateToLocalIgnoringOffset(defaultValues.endDate);
    return {
      ...defaultValues,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    };
  }, [defaultValues]);

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  async function handleUpdateProject(projectData, keywords) {
    const keywordsToSave = filterKeywords(keywords);
    if (keywordsToSave.length > 0) {
      bulkUpdateKeywords(
        `${apiPrefix}/keywords/bulk`,
        { reason, keywords: keywordsToSave },
        "PUT"
      );
    }

    const newProject = formatUpsertProjectData(projectData);
    const updateRequest = {
      reason: reason,
      project: newProject,
    };
    await onUpdate(
      `${projectsPrefix}/${projectData.id}`,
      updateRequest,
      "PUT",
      (data) => {
        const startDate = convertDate.stringToDate(data.startDate);
        const endDate = convertDate.stringToDate(data.endDate);
        const createdAt = convertDate.stringToDate(data.createdAt);
        const modifiedAt = convertDate.stringToDate(data.modifiedAt);
        const version =
          data.statusName === "Preview Mode"
            ? projectVersions.previewMode
            : projectVersions.premium;
        return { ...data, startDate, endDate, createdAt, modifiedAt, version };
      }
    );
    onCreate();
  }

  return (
    <EditProject
      defaultValues={defaultValuesParsed}
      handleSave={handleUpdateProject}
      onCancel={onCancel}
      mode={"update"}
      reason={reason}
      handleReasonChange={handleReasonChange}
    />
  );
}
