import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  Chip,
  Box,
  LinearProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";

function CustomToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

/**
 * Note: due to styling underglass... the minimum width for any column needs to be 100
Arguments
    title: 'Catalog',
    count: 45,
    fields: [
        {field: 'title', headerName: 'Title', width: 200 },
        {field: 'description', headerName: 'Description', width: 300 },
        {field: 'price', headerName: 'Price', width: 100 }
    ],
    rows: [
        {title: 'City of Ember', description: 'A fictional story', price: '$4.55' },
    ]
*/
export default function StructuredDataExpandTable({
  loading = false,
  title,
  count,
  handleChange,
  fields,
  rows,
}) {
  const [data, setData] = useState(rows);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  const filterData = (field, operator, value) => {
    if (operator !== "isEmpty" && value?.length === 0) {
      return rows;
    }
    switch (operator) {
      case "contains":
        if (!value) {
          return rows;
        }
        return rows.filter((item) =>
          item[field].toLowerCase().includes(value.toLowerCase())
        );
      case "equals":
        if (!value) {
          return rows;
        }
        return rows.filter(
          (item) => item[field].toLowerCase() === value.toLowerCase()
        );
      case "startsWith":
        if (!value) {
          return rows;
        }
        return rows.filter((item) =>
          item[field].toLowerCase().startsWith(value.toLowerCase())
        );
      case "endsWith":
        if (!value) {
          return rows;
        }
        return rows.filter((item) =>
          item[field].toLowerCase().endsWith(value.toLowerCase())
        );
      case "isEmpty":
        return rows.filter(
          (item) =>
            item[field].length === 0 ||
            typeof item[field] === "undefined" ||
            !item[field]
        );
      case "isNotEmpty":
        return rows.filter((item) => item[field] && item[field].length > 0);
      case "isAnyOf":
        if (!value) {
          return rows;
        }
        return rows.filter((item) => value.includes(item));
      default:
        return rows;
    }
  };

  return (
    <Accordion key={title} onChange={handleChange} className={"details-table"}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={title + "-content"}
        id={title + "-header"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            sx={{ marginRight: "6px" }}
            className="details-table-title"
          >
            {title}
          </Typography>
          <Chip size="small" label={count} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGridPremium
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            slots={{
              toolbar: CustomToolBar,
              loadingOverlay: LinearProgress,
            }}
            loading={loading}
            getRowHeight={() => "auto"}
            density="compact"
            getRowId={() => crypto.randomUUID()}
            columns={fields}
            rows={data}
            filterMode="client"
            onFilterModelChange={(newValue) => {
              let field = newValue?.items[0]?.field;
              let operator = newValue?.items[0]?.operator;
              let value = newValue?.items[0]?.value;
              setData(() => filterData(field, operator, value));
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
