import React, { useState } from "react";
import CodesLayout from "./CodesLayout";

// const selectOptions = [
//   { value: "7 days", label: "Include 7 days before procedure" },
//   { value: "15 days", label: "Include 15 days before procedure" },
//   { value: "30 days", label: "Include 30 days before procedure" },
//   { value: "45 days", label: "Include 45 days before procedure" },
//   { value: "90 days", label: "Include 90 days before procedure" },
//   { value: "120 days", label: "Include 120 days before procedure" },
// ];

const diagnosisCodeOptions = [
  { value: "icd-9", label: "ICD-9" },
  { value: "icd-10", label: "ICD-10" },
  { value: "other", label: "Other" },
];

const defaultCodeType = diagnosisCodeOptions[1].value;

export default function DiagnosisCodes({
  codes,
  setDiagSearchValue,
  setDiagType,
  updateFilterPayload,
  setUpdating,
  allChecked,
  setAllChecked,
}) {
  const [codesMarkedMap, setCodesMarkedMap] = useState({});

  function updateCodes(codesMarkedUpdated) {
    setUpdating(true);
    setCodesMarkedMap(codesMarkedUpdated);
    const diagnosisCodes = Object.entries(codesMarkedUpdated).map(
      ([codeId, isRelated]) => {
        return { id: codeId, isRelated: isRelated };
      }
    );

    updateFilterPayload("diagnosisCodes", diagnosisCodes);
  }

  return (
    <CodesLayout
      firstColumnLabel={"Diagnosis Code"}
      helpText={
        "Diagnosis codes below are based on criteria you set when you started the project."
      }
      searchLabel={"Search by keyword"}
      codeTypeLabel={"Diagnosis Code Type"}
      codeTypeOptions={diagnosisCodeOptions}
      defaultCodeType={defaultCodeType}
      tableData={codes}
      codesMarkedMap={codesMarkedMap}
      setSearchValue={setDiagSearchValue}
      setType={setDiagType}
      updateCodes={updateCodes}
      allChecked={allChecked}
      setAllChecked={setAllChecked}
    />
  );
}
