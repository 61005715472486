import {
  Popover,
  Typography,
  Button,
  CircularProgress,
  FormLabel,
  FormControl,
  FormControlLabel,
  Stack,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import { useSearchParams } from "react-router-dom";
import { exportData } from "../../../services/ProjectsServices";
import { useFusionAuth } from "@fusionauth/react-sdk";

export default function DataExportDrawer({ onClose, anchorEl }) {
  const [activityLogConfirmation, setActivityLogConfirmation] = useState({
    isDisplayed: false,
    value: "PROJECT_ACTIVITY_LOG",
  });
  const [patientProblemsConfirmation, setPatientProblemsConfirmation] =
    useState({ isDisplayed: false, value: "PATIENT_PROBLEMS" });
  const [
    patientReportedOutcomeDetailsConfirmation,
    setPatientReportedOutcomeDetailsConfirmation,
  ] = useState({
    isDisplayed: false,
    value: "PATIENT_REPORTED_OUTCOME_DETAILS",
  });
  const [
    patientReportedOutcomeCalculationsConfirmation,
    setPatientReportedOutcomeCalculationsConfirmation,
  ] = useState({
    isDisplayed: false,
    value: "PATIENT_REPORTED_OUTCOME_CALCULATIONS",
  });
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { startLogout } = useFusionAuth();
  const filesOptional = useMemo(() => {
    return [
      activityLogConfirmation.isDisplayed
        ? activityLogConfirmation.value
        : null,
      patientProblemsConfirmation.isDisplayed
        ? patientProblemsConfirmation.value
        : null,
      patientReportedOutcomeDetailsConfirmation.isDisplayed
        ? patientReportedOutcomeDetailsConfirmation.value
        : null,
      patientReportedOutcomeCalculationsConfirmation.isDisplayed
        ? patientReportedOutcomeCalculationsConfirmation.value
        : null,
    ].filter((item) => item !== null);
  }, [
    activityLogConfirmation,
    patientProblemsConfirmation,
    patientReportedOutcomeDetailsConfirmation,
    patientReportedOutcomeCalculationsConfirmation,
  ]);

  //PROM_FORMS Settings
  const { fetch: fetchProjectSettings, data: promSetting = [] } = useFetch();

  useEffect(() => {
    fetchProjectSettings(
      `${apiPrefix}/projects/${projectId}/settings`,
      (settings) => {
        return settings.filter((s) => s.name === "PROM_FORMS");
      }
    );
  }, [projectId, fetchProjectSettings]);

  const promFormsAvailable =
    promSetting.length > 0 && promSetting[0].value !== "";

  function onSubmit() {
    setLoading(true);
    exportData(projectId, filesOptional)
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        if (err === "401" || err === "403") {
          void startLogout();
        }
      });
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={true}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Stack direction={"column"} className={"export-modal"}>
        <FormControl className="export-modal-form">
          <Typography variant="h6">Data Export</Typography>
          <FormLabel variant="body1">
            Include the following files (optional)
          </FormLabel>
          <FormGroup className="export-modal-line">
            <FormControlLabel
              control={
                <Checkbox
                  value={patientProblemsConfirmation.isDisplayed}
                  onChange={() =>
                    setPatientProblemsConfirmation({
                      ...patientProblemsConfirmation,
                      isDisplayed: !patientProblemsConfirmation.isDisplayed,
                    })
                  }
                />
              }
              label="Patient Problems Listing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={activityLogConfirmation.isDisplayed}
                  onChange={() =>
                    setActivityLogConfirmation({
                      ...activityLogConfirmation,
                      isDisplayed: !activityLogConfirmation.isDisplayed,
                    })
                  }
                />
              }
              label="Project Activity History"
            />

            {promFormsAvailable && (
              <FormControlLabel
                control={
                  <Checkbox
                    value={
                      patientReportedOutcomeDetailsConfirmation.isDisplayed
                    }
                    onChange={() =>
                      setPatientReportedOutcomeDetailsConfirmation({
                        ...patientReportedOutcomeDetailsConfirmation,
                        isDisplayed:
                          !patientReportedOutcomeDetailsConfirmation.isDisplayed,
                      })
                    }
                  />
                }
                label="Patient Reported Outcome Details"
              />
            )}

            {promFormsAvailable && (
              <FormControlLabel
                control={
                  <Checkbox
                    value={
                      patientReportedOutcomeCalculationsConfirmation.isDisplayed
                    }
                    onChange={() =>
                      setPatientReportedOutcomeCalculationsConfirmation({
                        ...patientReportedOutcomeCalculationsConfirmation,
                        isDisplayed:
                          !patientReportedOutcomeCalculationsConfirmation.isDisplayed,
                      })
                    }
                  />
                }
                label="Patient Reported Outcome Calculations"
              />
            )}
          </FormGroup>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={1}
            width={"100%"}
          >
            <Button onClick={onClose} variant={"outlined"} size={"small"}>
              Back
            </Button>
            <Button
              type="submit"
              variant={"contained"}
              className={"categorize-button"}
              onClick={onSubmit}
              size={"small"}
              endIcon={loading && <CircularProgress size="1.5rem" />}
              disabled={loading}
            >
              Export Data
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Popover>
  );
}
