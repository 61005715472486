import React from "react";
import NavBar from "../shared/NavBar";
import SettingsHeader from "./SettingsHeader";
import { useSearchParams } from "react-router-dom";
import CompanySettingsPage from "./SettingPages/CompanySettingsPage";
import ProtocolPage from "./SettingPages/ProtocolPage";
import PrioritizationPage from "./SettingPages/PrioritizationPage";
// import CustomCategories from "./SettingPages/CustomCategoriesPage";
import AdditionalDataPage from "./SettingPages/AdditionalDataPage";
import ManufacturerSettingsPage from "./SettingPages/ManufacturerSettingsPage";
import { Grid } from "@mui/material";
import { RequireAuth } from "@fusionauth/react-sdk";
import DeviceDetailsSettings from "./SettingPages/DeviceDetailsSettings";
import ProjectDetailsSettingsPage from "./SettingPages/ProjectDetailsSettingsPage";
import SettingNotFound from "./SettingNotFound";

export default function BaseSettingsPage() {
  const [searchParams] = useSearchParams();
  const currentSetting = searchParams.get("settings");
  const currentProject = searchParams.get("projectId");

  const getCurrentSettingPage = function (setting) {
    switch (setting) {
      case "company":
        return (
          <RequireAuth withRole={"ADMIN"}>
            <CompanySettingsPage />
          </RequireAuth>
        );

      case "device-details":
        return <DeviceDetailsSettings projectId={currentProject} />;

      case "protocol":
        return <ProtocolPage projectId={currentProject} />;

      case "project-details":
        return <ProjectDetailsSettingsPage projectId={currentProject} />;

      case "prioritization":
        return <PrioritizationPage projectId={currentProject} />;

      // case "custom-categories":
      //   return <CustomCategories projectId={currentProject} />;

      case "additional-data":
        return (
          <RequireAuth>
            <AdditionalDataPage projectId={currentProject} />
          </RequireAuth>
        );

      case "manufacturer-settings":
        return (
          <RequireAuth withRole={"ADMIN"}>
            <ManufacturerSettingsPage />
          </RequireAuth>
        );

      default:
        return <SettingNotFound></SettingNotFound>;
    }
  };

  return (
    <div className="App">
      <NavBar />
      <div className="settings-content-box">
        <SettingsHeader />
        <Grid
          container
          direction={"column"}
          justifyContent={"space-between"}
          className="settings-container"
        >
          <Grid item>{getCurrentSettingPage(currentSetting)}</Grid>
        </Grid>
      </div>
    </div>
  );
}
