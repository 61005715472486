import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";

export default function StandardDrawerFooter({
  onLeftBtn,
  onRightBtn,
  leftBtnLabel,
  rightBtnLabel,
  loading,
  buttonsAlignment,
  displayLoadingRightBtn,
  disableRightBtn,
}) {
  return (
    <>
      <Box
        className={`standard-drawer-footer standard-drawer-footer-${buttonsAlignment} `}
        sx={{ bgcolor: "common.white" }}
      >
        <Button onClick={onLeftBtn} variant="outlined">
          {leftBtnLabel}
        </Button>
        {rightBtnLabel && (
          <Button
            variant="contained"
            onClick={onRightBtn}
            endIcon={
              displayLoadingRightBtn &&
              loading && <CircularProgress size="1.5rem" />
            }
            disabled={disableRightBtn || loading}
            data-testid="StandardDrawerFooter-rightButton"
          >
            {rightBtnLabel}
          </Button>
        )}
      </Box>
    </>
  );
}
