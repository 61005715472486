import FetchUtils from "../utils/FetchUtils";
import { deviceApiPrefix } from "../utils/main";

export async function Registration(payload) {
  const path = `${deviceApiPrefix}/user/signup`;

  try {
    await FetchUtils.fetch(path, "POST", payload);
  } catch (error) {
    console.error("Error: ", error.toString());
    throw error;
  }
}
