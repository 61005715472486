import React from "react";
import { Badge, Box } from "@mui/material";
import Badgeicon from "./BadgeIcon";

export default function IconContained({ type, icon, bgColor, color }) {
  return (
    <Badge
      badgeContent={<Badgeicon type={type} />}
      classes={{ badge: "icon-badge" }}
      style={{ color: color }}
    >
      <Box
        className={"icon-container"}
        sx={{
          bgcolor: bgColor,
        }}
      >
        {icon}
      </Box>
    </Badge>
  );
}
