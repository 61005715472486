import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const maxDate = new Date();
export default function DateRange({
  displayErrors,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  disabled,
  minDate = new Date(2018, 0),
}) {
  const startDateError =
    displayErrors &&
    (!startDate || startDate.getFullYear() < minDate.getFullYear());
  const endDateError = () => {
    if (!displayErrors) return false;
    if (!endDate) return true;
    return startDate && endDate.getFullYear() < startDate.getFullYear();
  };

  function handleStartDate(date) {
    onStartDateChange(date);
    if (!endDate || !date) {
      return;
    }
    // remove the endDate if the new Start date is newer than endDate
    const newStartDate = date.getTime();
    if (newStartDate > endDate.getTime()) {
      onEndDateChange(null);
    }
  }

  function handleEndDate(date) {
    onEndDateChange(date);
  }

  return (
    <div className="date-range">
      <DesktopDatePicker
        disabled={disabled}
        label={"Start Date"}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleStartDate}
        openTo="year"
        slotProps={{
          textField: {
            inputProps: {
              "data-testid": "DateRange-startDate",
            },
            required: true,
            error: startDateError,
          },
        }}
        value={startDate}
        views={["year"]}
      />
      <ChevronRightOutlinedIcon className="icon" />
      <DesktopDatePicker
        disabled={disabled}
        label={"End Date"}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleEndDate}
        openTo="year"
        slotProps={{
          textField: {
            inputProps: {
              "data-testid": "DateRange-endDate",
            },
            required: true,
            error: endDateError(),
          },
        }}
        value={endDate}
        views={["year"]}
      />
    </div>
  );
}
