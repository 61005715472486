import React, { useMemo } from "react";
import { Card, CardContent, FormHelperText, Typography } from "@mui/material";
import BarsVertical from "../shared/charts/BarsVertical";
import { backgroundColors } from "../../utils/charts";

export default function PatientEncounters({ data }) {
  const { models } = data;
  const [datasets, labels] = useMemo(() => {
    if (!models) {
      const datasets = [
        {
          label: "Patient Records",
          data: [0, 0],
          backgroundColor: backgroundColors[0],
        },
      ];
      const labels = datasets.map((data) => data.labels);
      return [datasets, labels];
    }
    const patientCount = models.map((pCount) => pCount.patientCount);
    const datasets = [
      {
        label: "Patient Records",
        data: patientCount,
        backgroundColor: backgroundColors[0],
      },
    ];
    const labels = models.map((model) => model.model);
    return [datasets, labels];
  }, [models]);

  return (
    <div id={"version-model-breakdown"} className="model-patient-container">
      <Typography variant="h6" align="left" gutterBottom>
        Version/Model # Breakdown
      </Typography>
      <Card>
        <CardContent>
          <div className="model-patient-container-graph">
            <BarsVertical labels={labels} datasets={datasets} />
          </div>
          <FormHelperText className="helper-text">
            Breakdown of total patient count by device version/model number.
            Some patients may have multiple devices used within the same index
            procedure.
          </FormHelperText>
        </CardContent>
      </Card>
    </div>
  );
}
