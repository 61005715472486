import React from "react";
import { Box } from "@mui/material";
import Description from "../Description";
import JumpTo from "../JumpTo";
import EmptyPatient from "../EmptyPatient";
import ProjectMetricCards from "../projectmetriccards/ProjectMetricCards";
import PatientEncounters from "../PatientEncounters";
import DemographicBreakdown from "../DemographicBreakdown";
import GeographicBreakdown from "../GeographicBreakdown";

export default function Overview({ project, deviceSearched }) {
  return (
    <Box className="description-body" sx={{ bgcolor: "grey.50" }}>
      <div className={"description-left-panel"}>
        <Description project={project} />
        <JumpTo version={"premium"} />
      </div>
      <div className="description-scroll">
        {deviceSearched && deviceSearched.totalPatients === 0 ? (
          <EmptyPatient />
        ) : (
          <>
            <div className="stats-container">
              <ProjectMetricCards data={deviceSearched} />
            </div>
            <PatientEncounters data={deviceSearched} />
            <DemographicBreakdown data={deviceSearched} />
            <GeographicBreakdown data={deviceSearched} />
          </>
        )}
      </div>
    </Box>
  );
}
