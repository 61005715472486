import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  Chip,
  Box,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import InfoIcon from "@mui/icons-material/Info";

const formNames = {
  koos: "KOOS",
  koosjr: "KOOSJR",
  oxford: "OXFORD",
};

const koosCategoryOrder = {
  symptoms: 1,
  pain: 2,
  adl: 3,
  "sport/rec": 4,
  qol: 5,
};

// This component implements a lot of custom logic to render a DataGrid.
// This DataGrid uses a feature called "Row grouping" to show the row data in
// a hierarchy.  Answer data is shown as "individual row"s in the DataGrid.
// Form Type and Diff Days are shown as "group row"s in the DataGrid.
export default function PromDataExpandTable({
  loading = false,
  title,
  count,
  handleChange,
  promData,
}) {
  const [answerRows, setAnswerRows] = useState([]);

  // Called whenever the API responds with data
  useEffect(() => {
    setAnswerRows(getDataGridRowsFromPromData(promData));
  }, [promData]);

  // Convert the hierarchical data from the API response into a list of individual answer rows
  function getDataGridRowsFromPromData(promModelData) {
    let answerRows = [];
    for (let promModel of promModelData) {
      for (let answerModel of promModel.answers) {
        let answerRow = {
          type: promModel.form.scoreType,
          diffDays: promModel.diffDays,
          promId: promModel.id,
          question: answerModel.question.questionText,
          answerText: answerModel.answerText,
          answerValue: answerModel.answerValue,
          questionOrder: answerModel.question.questionOrder,
          questionSortOrder: answerModel.question.questionOrder,
        };

        // Most forms have question numbered 1->N, where N = total questions on the form
        // KOOS forms have questions numbered 1->C, where C = total questions in the question category
        // This means with 5 categories there are 5 questions with questionOrder=1
        // To make the sorting more natural on this form, sort by category and question order
        if (promModel.form.scoreType === formNames.koos) {
          let categoryOffset =
            koosCategoryOrder[answerModel.question.category] * 100;
          answerRow.questionSortOrder =
            categoryOffset + answerRow.questionOrder;
        }

        answerRows.push(answerRow);
      }
    }

    // Default soring for questions should be question order
    answerRows.sort((a, b) => a.questionOrder - b.questionOrder);

    return answerRows;
  }

  // Needed when using useKeepGroupedColumnsHidden utility in the initial state
  const apiRef = useGridApiRef();

  // useKeepGroupedColumnsHidden: Grouped columns are added to the whichever
  //  columns are defined in the component's `columns` prop.  Without this
  //  helper method, the column would appear twice in the DataGrid
  let initialState = useKeepGroupedColumnsHidden({
    initialState: {
      // Sets the columns to use for row grouping
      // promId is the value we use from the model for grouping, but the column is
      // displayed as diff days
      rowGrouping: {
        model: ["type", "promId"],
      },
    },
    apiRef,
  });

  const getDataGridColumns = () => [
    {
      field: "type",
      headerName: "Type",
      width: 100,
    },
    {
      // We use the PromId as a "value" for this column so that answers can be grouped by PromId
      // However, in the UI we want to display the diffDays
      field: "promId",
      headerName: "Days Pre/Post Index",
      width: 150,
      renderCell: getPromIdColumnColumnText,
      valueGetter: getPromIdColumnColumnSortValue,
    },
    {
      field: "year",
      headerName: "Year",
      width: 100,
      renderCell: getYearColumnText,
      valueGetter: getYearColumnSortValue,
    },
    {
      field: "question",
      headerName: "Question Text",
      width: 250,
      renderCell: getQuestionColumnText,
      // For a PROM row, the question column spans the remainder of the table's columns
      // For all other rows, use single column span
      colSpan: (params) => (params.rowNode.groupingField === "promId" ? 3 : 1),
    },
    {
      field: "answerText",
      headerName: "Answer Text",
      width: 100,
    },
    {
      field: "answerValue",
      headerName: "Answer Value",
      width: 100,
    },
  ];

  const getPromIdColumnColumnText = function (params) {
    let prom = findProm(params.value);
    if (!prom) {
      return "";
    }
    return `${prom.diffDays} d`;
  };

  // Each of the GetXColumnText/GetXColumnSortValue methods below are called once per
  // row when for a given column in the DataGrid.  For a group row, we need custom logic
  // to override the column text (or sort value). In order to determine if we're
  // on a group row or an individual row, check the vale of "params.rowNode.groupingField".
  // For a group row, this will equal the name of the column that is being grouped.
  const getYearColumnSortValue = function (params) {
    if (params.rowNode.groupingField === "promId") {
      let formId = params.rowNode.groupingKey;
      let formRow = findProm(formId);
      let date = new Date(formRow.promDate);
      return date.getTime();
    }
    return null;
  };

  const getPromIdColumnColumnSortValue = function (params) {
    if (params.rowNode.groupingField === "promId") {
      let formId = params.rowNode.groupingKey;
      let formRow = findProm(formId);
      return formRow.promId;
    }
    return null;
  };

  const getYearColumnText = function (params) {
    if (params.rowNode.groupingField === "promId") {
      let formId = params.rowNode.groupingKey;
      let formRow = findProm(formId);
      let date = new Date(formRow.promDate);
      return date.getFullYear();
    }
    return null;
  };

  const getQuestionColumnText = function (params) {
    // Default case for an individual row is to show the question value
    // Wrap it in a span so we can add a min-height
    if (params.value) {
      return <span className={"prom-answer-text"}>{params.value}</span>;
    }

    // For a PROM grouped row, display the score summary
    if (params.rowNode.groupingField === "promId") {
      let promId = params.rowNode.groupingKey;
      let promRow = findProm(promId);
      switch (promRow.form.scoreType) {
        case formNames.koos:
          return renderKoosSummary(promRow);
        case formNames.koosjr:
          return renderKoosjrSummary(promRow);
        case formNames.oxford:
          return renderOxfordSummary(promRow);
        default:
          return null;
      }
    }

    // Don't show anything for the Type group row
    return null;
  };

  const findProm = (promId) => promData.find((p) => p.id === promId);

  // The accordian component setup was taken from the StructuredDataExpandTable component
  // We probably should move the accordian part into a wrapper component
  // if we need another custom component inside the accordian
  return (
    <Accordion key={title} onChange={handleChange} className={"details-table"}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={title + "-content"}
        id={title + "-header"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            sx={{ marginRight: "6px" }}
            className="details-table-title"
          >
            {title}
          </Typography>
          <Chip size="small" label={count} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 1 }}>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGridPremium
            apiRef={apiRef}
            initialState={initialState}
            slots={{
              toolbar: CustomToolBar,
              loadingOverlay: LinearProgress,
            }}
            loading={loading}
            density="compact"
            getRowId={() => crypto.randomUUID()}
            rows={answerRows}
            getRowHeight={(prop) => {
              return "auto";
            }}
            // Lots of custom logic in here for rendering row cells
            columns={getDataGridColumns()}
            // By default, grouped columns are all combined into the first column
            // This will make each of the grouped columns into an individual columns
            rowGroupingColumnMode="multiple"
            // Hide total row count on the grouped rows
            groupingColDef={{ hideDescendantCount: true }}
            // Hide the footer row count
            // The count will display as the number of top level groups (ie: form types)
            hideFooterRowCount
            // I tried to implement filtering but kept getting errors about rows not existing
            // I'm not really sure what's going on, but supporting filtering will need some custom
            // work.
            disableColumnFilter
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function renderKoosjrSummary(promRow) {
  if (promRow.score) {
    return (
      <div className={"prom-group-cell"}>
        <div className={"prom-group-cell-row"}>
          <div className={"prom-group-title"}>
            <div>
              Survey
              <br />
              Scores
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-questions-pill"}>
              {promRow.answers.length}/7
            </div>
            <div>
              Questions
              <br />
              Answered
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-raw-score-pill"}>
              {promRow.scoreDetail["Raw summed score"]}/28
            </div>
            <div>
              Raw
              <br />
              Score
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-score-pill"}>{promRow.score}/100</div>
            <div>
              KOOS JR
              <br />
              Score
            </div>
          </div>
        </div>
        <div className={"prom-group-cell-row"}>
          <div className={"prom-group-title"}>
            {renderSubscoreToolTip(promRow.form.scoreType)}
            <div>
              Survey
              <br />
              Subscores
            </div>
          </div>
          <div className={"prom-group-label"}>N/A</div>
        </div>
      </div>
    );
  } else {
    return renderUnscoredSummary(
      promRow.form.scoreType,
      promRow.answers.length,
      7
    );
  }
}

function renderOxfordSummary(promRow) {
  if (promRow.score) {
    return (
      <div className={"prom-group-cell"}>
        <div className={"prom-group-cell-row"}>
          <div className={"prom-group-title"}>
            <div>
              Survey
              <br />
              Scores
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-questions-pill"}>
              {promRow.answers.length}/12
            </div>
            <div>
              Questions
              <br />
              Answered
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-raw-score-pill"}>{promRow.score}/48</div>
            <div>
              Raw
              <br />
              Score
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-score-pill"}>{promRow.score}</div>
            <div>
              Oxford
              <br />
              Knee
              <br />
              Score
            </div>
          </div>
        </div>
        <div className={"prom-group-cell-row"}>
          <div className={"prom-group-title"}>
            {renderSubscoreToolTip(promRow.form.scoreType)}
            <div>
              Survey
              <br />
              Subscores
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail.pain}
            </div>
            <div>Pain</div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail.function}
            </div>
            <div>Function</div>
          </div>
        </div>
      </div>
    );
  } else {
    return renderUnscoredSummary(
      promRow.form.scoreType,
      promRow.answers.length,
      12
    );
  }
}

function renderKoosSummary(promRow) {
  if (promRow.score) {
    return (
      <div className={"prom-group-cell"}>
        <div className={"prom-group-cell-row"}>
          <div className={"prom-group-title"}>
            <div>
              Survey
              <br />
              Scores
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-questions-pill"}>
              {promRow.answers.length}/42
            </div>
            <div>
              Questions
              <br />
              Answered
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-raw-score-pill"}>
              {promRow.scoreDetail["Raw summed score"]}/168
            </div>
            <div>
              Raw
              <br />
              Score
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-score-pill"}>{promRow.score}%</div>
            <div>
              KOOS
              <br />
              Score
            </div>
          </div>
        </div>
        <div className={"prom-group-cell-row koos-subscore-row"}>
          <div className={"prom-group-title"}>
            {renderSubscoreToolTip(promRow.form.scoreType)}
            <div>
              Survey
              <br />
              Subscores
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail.symptoms}%
            </div>
            <div className={"prom-subscore-label"}>
              Sympt
              <br />
              oms
            </div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail.pain}%
            </div>
            <div className={"prom-subscore-label"}>Pain</div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail.adl}%
            </div>
            <div className={"prom-subscore-label"}>Daily Living</div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail["sport/rec"]}%
            </div>
            <div className={"prom-subscore-label"}>Sport / Rec.</div>
          </div>
          <div className={"prom-group-label"}>
            <div className={"prom-subscore-pill"}>
              {promRow.scoreDetail.qol}%
            </div>
            <div className={"prom-subscore-label"}>Quality of Life</div>
          </div>
        </div>
      </div>
    );
  } else {
    return renderUnscoredSummary(
      promRow.form.scoreType,
      promRow.answers.length,
      42
    );
  }
}

function renderUnscoredSummary(formType, answerCount, questionCount) {
  return (
    <div className={"prom-group-cell"}>
      <div className={"prom-group-cell-row"}>
        <div className={"prom-group-title"}>
          <div>
            Survey
            <br />
            Scores
          </div>
        </div>
        <div className={"prom-group-label"}>
          <div className={"prom-questions-pill"}>
            {answerCount}/{questionCount}
          </div>
          <div>
            Questions
            <br />
            Answered
          </div>
        </div>
        <div className={"prom-group-label"}>
          Scores not calculated because one or more answers are missing.
        </div>
      </div>
      <div className={"prom-group-cell-row"}>
        <div className={"prom-group-title"}>
          {renderSubscoreToolTip(formType)}
          <div>
            Survey
            <br />
            Subscores
          </div>
        </div>
        <div className={"prom-group-label"}>N/A</div>
      </div>
    </div>
  );
}

function renderSubscoreToolTip(type) {
  const subscoreToolTips = {
    [formNames.koos]:
      "The KOOS Survey has five subscales that are scored separately.",
    [formNames.koosjr]:
      "Unlike some other surveys, the KOOS JR does not include subscales.",
    [formNames.oxford]:
      "The Oxford Knee Survey has two subscales that are scored separately.",
  };
  return (
    <Tooltip title={subscoreToolTips[type]} placement="top-end">
      <InfoIcon
        size="small"
        sx={{ position: "absolute", right: "105%", top: "25%" }}
        color="disabled"
      />
    </Tooltip>
  );
}

function CustomToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}
