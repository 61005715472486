import React from "react";
import { Card, Typography } from "@mui/material";

export default function EmptyPatient() {
  return (
    <Card className="no-patient-card">
      <div>
        <img src={process.env.PUBLIC_URL + "/no-data.svg"} alt="no-data" />
      </div>
      <Typography variant="h6">
        Data for your device has not been loaded.
      </Typography>
      <Typography>
        Once data has been loaded for your device the results will be shown
        here.
      </Typography>
    </Card>
  );
}
