import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Popover,
  TextField,
  Typography,
} from "@mui/material";

import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";

export default function RelatedVisitModal({
  anchorEl,
  onClose,
  visit,
  updateVisit,
  updatedList,
  patientId,
}) {
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const { update: updateVisitWithReason, isLoading, data } = useFetch();

  function noteChange(event) {
    setNote(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const payload = {
      isRelated: visit.isRelated === false,
      reason: note,
    };
    updateVisitWithReason(
      `${projectsPrefix}/${projectId}/patients/${patientId}/encounters/${visit.id}/related`,
      payload,
      "PUT"
    );
  }

  useEffect(() => {
    if (!isLoading && !!data.id) {
      updatedList({
        id: visit.id,
        isRelated: data.isRelated,
        reason: data.reason,
      });
      updateVisit(data);
      setLoading(false);
      onClose();
    }
  }, [data, isLoading, onClose, updateVisit, updatedList, visit.id]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={true}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <form onSubmit={onSubmit}>
        <Card className="mark-visit-modal">
          <CardHeader
            title={
              <Typography variant={"h5"}>
                {visit.isRelated || visit.isRelated === null
                  ? "Mark Visit As Unrelated"
                  : "Mark Visit As Related"}
              </Typography>
            }
          />
          <CardContent className="modal-content">
            <TextField
              fullWidth
              className="categorize-modal-textfield"
              label={"Enter Note/Reason"}
              onChange={noteChange}
              placeholder={"Enter Note/Reason"}
              multiline
              required
              rows={8}
              value={note}
            />
          </CardContent>
          <CardActions className={"categorize-modal-footer"}>
            <Button size={"small"} variant={"outlined"} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size={"small"}
              type={"submit"}
              variant={"contained"}
              endIcon={loading && <CircularProgress size="1.5rem" />}
              disabled={loading}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Popover>
  );
}
