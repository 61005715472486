import React, { useEffect, useRef, useState } from "react";
import * as ChartGeo from "chartjs-chart-geo";
import mapData from "../../../data/states-10m.json";
import {
  Chart as ChartJS,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChartGeo.ChoroplethController,
  ChartGeo.ProjectionScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature
);

const labelTooltip = (tooltipData) => {
  const state = tooltipData.raw.feature.properties.name;
  const value = tooltipData.raw.value;
  return `${state}: ${value || 0} Patients`;
};

export default function ChoroplethUS(props) {
  const { data } = props;
  const [nation, setNation] = useState();
  const [states, setStates] = useState();
  const canvas2 = useRef();

  useEffect(() => {
    if (!data) {
      return;
    }

    const nationRes = ChartGeo.topojson.feature(mapData, mapData.objects.nation)
      .features[0];
    const statesRes = ChartGeo.topojson.feature(
      mapData,
      mapData.objects.states
    ).features;
    setNation(nationRes);
    setStates(statesRes);
  }, [data]);

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    if (!data || !nation || !states || !canvas) {
      return;
    }
    const chart = new ChartJS(canvas.getContext("2d"), {
      type: "choropleth",
      data: {
        labels: states.map((d) => d.properties.name),
        datasets: [
          {
            label: "States",
            outline: nation,
            data: states.map((d) => {
              const currState = d.properties.name;
              return {
                feature: d,
                value: data[currState],
              };
            }),
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          xy: {
            axis: "x",
            projection: "albersUsa",
            display: false,
          },
          color: {
            axis: "x",
            display: true,
            interpolate: "oranges",
            missing: "#fcfcfc",
            quantize: 100,
            legend: {
              position: "bottom-right",
              align: "bottom",
              labels: {
                padding: 1000,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              label: labelTooltip,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [nation, states, data]);

  return <canvas ref={canvas2} id="canvas"></canvas>;
}
