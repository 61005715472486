import React, { useMemo } from "react";
import { List, Button, ButtonGroup } from "@mui/material";
import PatientDemographicsDropDown from "./PatientDemographicsDropDown";
import PatientDemographicsSkeleton from "./PatientDemographicsSkeleton";

export default function PatientDemographics({
  tags,
  selectedTags,
  updateSelectedTags,
  updateFilterPayload,
}) {
  const categoryList = useMemo(() => {
    const categories = tags.map((tag) => tag.category);
    const uniqueCategories = [...new Set(categories)];
    return uniqueCategories;
  }, [tags]);

  const categoriesGrouped = useMemo(() => {
    return categoryList.reduce((prev, currCategory) => {
      const categoryItems = tags.filter((tag) => tag.category === currCategory);
      return { ...prev, [currCategory]: categoryItems };
    }, {});
  }, [categoryList, tags]);

  const selectAll = () => {
    updateFilterPayload("patientDemographics", tags);
  };

  const deselectAll = () => {
    updateFilterPayload("patientDemographics", []);
  };

  const onChange = ({ target }) => {
    updateSelectedTags(Number(target.id), target.checked);
  };

  return (
    <List className="modal-list">
      <div className="modal-filter-content">
        <ButtonGroup variant={"text"}>
          <Button disabled={tags.length === 0} onClick={selectAll}>
            Select All
          </Button>
          <Button disabled={tags.length === 0} onClick={deselectAll}>
            Deselect All
          </Button>
        </ButtonGroup>
        <div className="modal-filter-dropdown">
          {tags.length === 0 && <PatientDemographicsSkeleton />}
          {categoryList.map((category) => {
            const categoryItems = categoriesGrouped[category];
            return (
              <PatientDemographicsDropDown
                key={category}
                tagsSelected={selectedTags}
                onChange={onChange}
                categoryName={category}
                categoryItems={categoryItems}
              />
            );
          })}
        </div>
      </div>
    </List>
  );
}
