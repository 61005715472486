import React from "react";
import { Button, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { mockKeyword } from "../../../../models/Projects";
import KeywordRow from "./KeywordRow";

export default function ImportantKeywords({
  keywords,
  updateKeyword,
  displayRequiredError,
}) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  function handlePhraseChange({ index, keyword, phraseValue }) {
    const keywordUpdated = {
      ...keyword,
      phrase: phraseValue,
      editing: true,
      target: "body",
    };
    updateKeyword(keywordUpdated, index);
  }
  function handleCategoryChange({ index, keyword, categoryValue }) {
    const keywordUpdated = {
      ...keyword,
      category: categoryValue,
      editing: true,
      target: "body",
    };
    updateKeyword(keywordUpdated, index);
  }
  function handleDeleteItem({ keyword, index }) {
    const keywordUpdated = {
      ...keyword,
      editing: true,
      forDelete: true,
    };
    updateKeyword(keywordUpdated, index);
  }
  function handleAddElement() {
    updateKeyword(
      { ...mockKeyword, projectId, editing: true },
      keywords.length
    );
  }
  return (
    <section className="important-keywords-box">
      <div className="important-keywords-header">
        <Typography variant="h6">Important Keywords</Typography>
        <Typography variant="body2">
          Add keywords that 3Aware will use to prioritize clinical notes and
          patient visits, choose a category for each keyword, and choose whether
          an occurrence of the word will prioritize or de-prioritize the records
          where it is found.
        </Typography>
      </div>
      <div className="important-keywords-items">
        {keywords.map((keyword, index) => {
          if (keyword.forDelete) {
            return null;
          }
          const defaultValues = { index, keyword };
          return (
            <KeywordRow
              key={index}
              keyword={keyword}
              handlePhraseChange={(e) =>
                handlePhraseChange({
                  ...defaultValues,
                  phraseValue: e.target.value,
                })
              }
              handleCategoryChange={(e) =>
                handleCategoryChange({
                  ...defaultValues,
                  categoryValue: e.target.value,
                })
              }
              handleDeleteItem={() => handleDeleteItem({ ...defaultValues })}
              handleAddElement={handleAddElement}
              displayRequiredError={displayRequiredError}
            />
          );
        })}
        <Button
          className="add-keyword-button"
          size="small"
          onClick={handleAddElement}
          variant="contained"
        >
          Add Keyword
        </Button>
      </div>
    </section>
  );
}
