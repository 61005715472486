import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./components/home/Home";
import ProjectDetails from "./components/details/ProjectDetails";
import SignUpLayout from "./components/login/SignUpLayout";
import ConfirmationLayout from "./components/login/ConfirmationLayout";
import ConfirmationWithCompanyNameLayout from "./components/login/ConfirmationWithCompanyNameLayout";
import AdminCounts from "./components/counts/AdminCounts";
import { RequireAuth, useFusionAuth } from "@fusionauth/react-sdk";
import NotFound from "./components/home/NotFound";
import BaseSettingsPage from "./components/settings/BaseSettingsPage";
import Agreements from "./components/authentication/Agreements";

const publicRoutes = [
  "/login",
  "/signup",
  "/confirmation",
  "/confirmation_with_companyName",
];

export default function AppRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, isFetchingUserInfo, startLogin, userInfo } =
    useFusionAuth();
  const environment = window.env.environment;

  const agreementsAccepted =
    userInfo &&
    userInfo.agreements_accepted &&
    userInfo.agreements_accepted.ALL &&
    userInfo.agreements_accepted[environment];

  useEffect(() => {
    if (isLoggedIn && (isFetchingUserInfo || !userInfo)) {
      return;
    }
    if (
      isLoggedIn &&
      location.pathname !== "/agreements" &&
      !agreementsAccepted
    ) {
      // if user hasn't accepted relevant env terms, lock into agreements page
      navigate("/agreements");
    } else if (
      isLoggedIn &&
      ((location.pathname === "/agreements" && agreementsAccepted) ||
        publicRoutes.includes(location.pathname))
    ) {
      navigate("/");
    } else if (
      !isLoggedIn &&
      (location.pathname === "/login" ||
        !publicRoutes.includes(location.pathname))
    ) {
      void startLogin(`sourceUri:${location.origin}`);
    }
  }, [
    isLoggedIn,
    navigate,
    location,
    startLogin,
    agreementsAccepted,
    isFetchingUserInfo,
    userInfo,
  ]);

  useEffect(() => {
    if (!isFetchingUserInfo && isLoggedIn && userInfo) {
      localStorage.setItem(
        "settings",
        userInfo.settings ? JSON.stringify(userInfo.settings) : "{}"
      );
    }
  }, [isLoggedIn, isFetchingUserInfo, userInfo]);

  return (
    <>
      <PublicRoutes />
      {!isFetchingUserInfo && isLoggedIn && <PrivateRoutes />}
    </>
  );
}

function PublicRoutes() {
  return (
    <Routes>
      <Route path={"/signup"} element={<SignUpLayout />} />
      <Route path={"/confirmation"} element={<ConfirmationLayout />} />
      <Route
        path={"/confirmation_with_companyName"}
        element={<ConfirmationWithCompanyNameLayout />}
      />
    </Routes>
  );
}

function PrivateRoutes() {
  return (
    <Routes>
      <Route
        exact
        path={"/details"}
        element={
          <RequireAuth>
            <ProjectDetails />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={"/"}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={"/counts"}
        element={
          <RequireAuth withRole="ADMIN">
            <AdminCounts />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={"/settings"}
        element={
          <RequireAuth>
            <BaseSettingsPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={"/agreements"}
        element={
          <RequireAuth>
            <Agreements />
          </RequireAuth>
        }
      />
      <Route exact path="*" element={<NotFound />} />
    </Routes>
  );
}
