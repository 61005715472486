import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Confirmation() {
  const navigate = useNavigate();

  function handleGoTo() {
    navigate("/login");
  }

  return (
    <div className="authentication">
      <form className="login-form">
        <Typography variant="h6">Your account has been created!</Typography>
        <Typography variant="body2">
          Please follow the instructions we just sent to your email address in
          order to activate your preview account.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="return"
          onClick={handleGoTo}
        >
          Go to Log In
        </Button>
      </form>
    </div>
  );
}
