import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Typography,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Popover,
  CircularProgress,
  Stack,
  Collapse,
  Divider,
  Alert,
} from "@mui/material";
import { projectsPrefix } from "../../../../services/ProjectsServices";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../../../../services/hooks/useFetch";
import { formatPatient } from "../../../../models/patient";
import { KeyboardArrowRight } from "@mui/icons-material";
import IMDRFComponent from "./IMDRFComponent";

import {
  ATTRIBUTE_TYPES,
  getCategoryIcon,
  getImdrfEnabled,
} from "./CategorizeUtils";
import AutoCompleteInput from "../AutoCompleteInput";
import { CurrentProject } from "../../ProjectDetails";

export default function IMDRFCategorize({
  anchorEl,
  open,
  onClose,
  patientId,
  patientNumber,
  attribute,
  updatePatientList,
}) {
  const [showImdrfSelect, setShowImdrfSelect] = useState(false);
  const [distinctValues, setDistinctValues] = useState(null);
  const [showImdrfSelectWindow, setShowImdrfSelectWindow] = useState(false);
  const [imdrfCode, setImdrfCode] = useState(attribute?.imdrfCode || null);
  const [reason, setReason] = useState(attribute?.reason || "");
  const [category, setCategory] = useState(null);
  const [attributeValue, setAttributeValue] = useState(
    attribute?.attributeValue || null
  );
  const [fieldData, setFieldData] = useState(attribute?.fieldData ?? []);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [deleting, setDeleting] = useState(false);
  const [updatedPatient, setUpdatedPatient] = useState(null);
  const [deleteReason, setDeleteReason] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const actionRef = React.useRef();
  const projectId = searchParams.get("projectId");

  const { projectSettings, attributeTypes } = useContext(CurrentProject);

  const {
    create: createCategorization,
    update: updateCategorization,
    delete: deleteCategorization,
    isLoading: patientLoading,
    error: categorizationError,
  } = useFetch();

  const { fetch: fetchDistinctValues, isLoading: distinctValuesLoading } =
    useFetch();

  const imdrfEnabled = getImdrfEnabled(projectSettings);

  function setSelectedCode(code) {
    setImdrfCode(code);
    setShowImdrfSelectWindow(false);
  }

  const handleShowImdrfSelect = useCallback(
    (category, attributeValue) => {
      let showControl = false;
      if (!category) setShowImdrfSelect(showControl);

      if (imdrfEnabled) {
        if (category.id === ATTRIBUTE_TYPES.ADVERSE_EVENT) {
          showControl = true;
        }
        if (category.id === ATTRIBUTE_TYPES.PERFORMANCE && !attributeValue) {
          showControl = true;
        }

        if (
          category.id === ATTRIBUTE_TYPES.DEVICE_DEFICIENCY &&
          attributeValue
        ) {
          showControl = true;
        }
      }
      setShowImdrfSelect(showControl);
    },
    [imdrfEnabled]
  );

  function handleFieldDataInputChange(event) {
    let customField = event.target;
    let fieldValue = customField.value;
    let attributeTypeFieldId = customField.name;
    let newFieldData = [...fieldData];
    let fieldToUpdate = newFieldData.find(
      (f) => f.attributeTypeFieldId === attributeTypeFieldId
    );

    if (!fieldToUpdate) {
      fieldToUpdate = {
        id: null,
        attributeId: attribute?.id ?? null,
        attributeTypeFieldId: attributeTypeFieldId,
        fieldValue: fieldValue,
      };
      newFieldData.push(fieldToUpdate);
    } else {
      fieldToUpdate.fieldValue = fieldValue;
    }

    setFieldData(newFieldData);
  }

  const handleClose = useCallback(() => {
    setLoading(false);
    setCategory(null);
    setImdrfCode(null);
    setAttributeValue(null);
    setReason("");
    setApiErrorMessage("");
    setShowImdrfSelect(false);
    setDistinctValues(null);
    setUpdatedPatient(null);
    onClose();
  }, [onClose]);

  function handleCategoryChange(event) {
    const attributeTypeId = event.target.value;
    let newCategory = attributeTypes.find((a) => a.id === attributeTypeId);
    categoryChanged(newCategory);
  }

  const categoryChanged = useCallback(
    (newCategory) => {
      setCategory(newCategory);

      //For AEs we always show IMDRF selection
      //For the boolean types, we only show IMDRF selection when:
      //Performance or Clinical Benefit failure (false) or Device Deficient (true)
      handleShowImdrfSelect(newCategory, null);
      setApiErrorMessage("");
      setShowImdrfSelectWindow(false);
      setDistinctValues(null);

      if (newCategory.allowRetrieveDistinct) {
        fetchDistinctValues(
          `${projectsPrefix}/${projectId}/attributes/types/${newCategory.id}/values?search=`,
          (data) => {
            if (data) {
              setDistinctValues(data);
            }
          }
        );
      }
    },
    [fetchDistinctValues, handleShowImdrfSelect, projectId]
  );

  function handleChangeSub(event) {
    const attributeValue = event.target.value;
    subcatChanged(attributeValue);
  }

  function subcatChanged(attributeValue) {
    setAttributeValue(attributeValue);

    if (
      category.id === ATTRIBUTE_TYPES.CLINICAL_BENEFIT ||
      (category.id === ATTRIBUTE_TYPES.PERFORMANCE && attributeValue) ||
      (category.id === ATTRIBUTE_TYPES.DEVICE_DEFICIENCY && !attributeValue)
    ) {
      if (attribute) {
        attribute.imdrfCode = null;
      }
      setImdrfCode(null);
      setShowImdrfSelectWindow(false);
    }
    handleShowImdrfSelect(category, attributeValue);
  }

  function handleBody() {
    let code = imdrfCode;
    let selectedCode = undefined;

    if (
      imdrfCode &&
      !imdrfCode.isSelectable &&
      imdrfCode.isOverridden &&
      imdrfCode.overrideCode
    ) {
      code = imdrfCode.overrideCode;
      selectedCode = imdrfCode;
    }
    if (code && code.children) {
      code.children = null;
    }
    if (selectedCode && selectedCode.children) {
      selectedCode.children = null;
    }

    let newFieldData = [];
    if (fieldData && fieldData.length > 0) {
      fieldData.forEach((f) => {
        if (f.fieldValue && f.fieldValue !== "") {
          newFieldData.push(f);
        }
      });
    }

    return {
      id: attribute?.id,
      attributeValue: attributeValue,
      attributeTypeId: category.id,
      imdrfCode: code,
      selectedImdrfCode: selectedCode,
      reason: reason,
      createdBy: attribute?.createdBy,
      createdAt: attribute?.createdAt,
      fieldData: newFieldData,
    };
  }

  function parseApiErrorMessage(apiError) {
    if (!apiError) return "";
    let errorMessage = apiError.message;
    return errorMessage.substring(
      errorMessage.indexOf('"') + 1,
      errorMessage.lastIndexOf('"')
    );
  }

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const attributeData = handleBody();
    if (attributeData.reason.trim() === "") {
      setLoading(false);
      return;
    }
    submitAndClose(attributeData);
  }

  function submitAndClose(attributeData) {
    if (!attribute || !attribute?.id) {
      createCategorization(
        `${projectsPrefix}/${projectId}/patients/${patientId}/attributes`,
        attributeData,
        "POST",
        (data) => {
          setUpdatedPatient(data);
        }
      );
    } else {
      //patientId is required for updates, but not allowed for create in the API
      attributeData.patientId = patientId;
      updateCategorization(
        `${projectsPrefix}/${projectId}/patients/${patientId}/attributes/${attribute.id}`,
        attributeData,
        "PUT",
        (data) => {
          setUpdatedPatient(data);
        }
      );
    }
  }

  function onDelete(event) {
    event.preventDefault();
    setLoading(true);
    deleteAndClose();
  }

  function deleteAndClose() {
    deleteCategorization(
      `${projectsPrefix}/${projectId}/patients/${patientId}/attributes/${attribute.id}`,
      deleteReason,
      "DELETE",
      (data) => {
        setUpdatedPatient(data);
      }
    );
  }

  function showAutoComplete(category) {
    if (!category) return false;
    if (
      category &&
      category.id === ATTRIBUTE_TYPES.ADVERSE_EVENT &&
      imdrfEnabled
    )
      return false;
    return category && category.allowRetrieveDistinct;
  }

  function showCustomFields(category) {
    return (
      category &&
      category.attributeTypeFields &&
      category.attributeTypeFields.length > 0 &&
      imdrfEnabled
    );
  }

  function showSubCatSelect(category) {
    return category && category.optionValues;
  }

  const getSubmitDisabled = useCallback(() => {
    if (loading || distinctValuesLoading) return true;
    if (!category) return true;
    if (
      attributeValue === null &&
      category.id !== ATTRIBUTE_TYPES.ADVERSE_EVENT
    )
      return true;
    if (!reason || reason.trim() === "") return true;
    if (showImdrfSelect && !imdrfCode) return true;
    if (!(apiErrorMessage === "")) return true;
    return false;
  }, [
    loading,
    distinctValuesLoading,
    category,
    attributeValue,
    reason,
    showImdrfSelect,
    imdrfCode,
    apiErrorMessage,
  ]);

  const getDeleteDisabled = useCallback(() => {
    return deleteReason.trim() === "" || loading;
  }, [deleteReason, loading]);

  //Attribute post-save/delete updates
  useEffect(() => {
    if (!patientLoading && updatedPatient) {
      setLoading(false);
      updatePatientList(formatPatient(updatedPatient));
      handleClose();
    }
  }, [patientLoading, updatedPatient, handleClose, updatePatientList]);

  //Error handling
  useEffect(() => {
    if (categorizationError) {
      setApiErrorMessage(parseApiErrorMessage(categorizationError));
      setLoading(false);
    }
  }, [categorizationError, handleClose]);

  //This is to load an attribute for editing if one is passed in
  useEffect(() => {
    if (attribute && attribute.attributeTypeId) {
      let category = attributeTypes.find(
        (a) => a.id === attribute.attributeTypeId
      );
      categoryChanged(category);
    }
  }, [attribute, attributeTypes, categoryChanged]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ maxHeight: "80%" }}
      action={actionRef}
    >
      <Typography variant="h6" sx={{ marginTop: "10px", marginLeft: "16px" }}>
        {`Categorize Patient ${patientNumber} ${
          category ? `/ ${category?.name}` : ""
        }`}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack direction="column" spacing={1.5} className="categorize-modal">
          <FormControl size="small" fullWidth>
            <InputLabel>Category Type</InputLabel>
            <Select
              label="Category Type"
              name={"CategoryType"}
              error={!(apiErrorMessage === "")}
              value={category?.id || ""}
              onChange={handleCategoryChange}
              disabled={!!attribute || deleting}
              variant="outlined"
              SelectDisplayProps={{
                style: { display: "flex", alignItems: "center", gap: "8px" },
              }}
            >
              {attributeTypes.map((c) => {
                return (
                  c.enabled && (
                    <MenuItem value={c.id} key={c.id}>
                      {getCategoryIcon(c.id)}&nbsp;&nbsp;
                      {c.name}
                    </MenuItem>
                  )
                );
              })}
            </Select>
          </FormControl>

          {showAutoComplete(category) && (
            <AutoCompleteInput
              label="Sub-Category"
              state={attributeValue}
              setState={setAttributeValue}
              options={distinctValues ?? []}
              deleting={deleting}
            />
          )}

          {showSubCatSelect(category) && (
            <FormControl fullWidth size="small">
              <InputLabel>Sub-Category</InputLabel>
              <Select
                value={attributeValue === null ? "" : attributeValue}
                size="small"
                label="Sub-Category"
                onChange={handleChangeSub}
                disabled={deleting}
                variant="outlined"
              >
                {category.optionValues.split(",").map((subcat) => {
                  return (
                    <MenuItem
                      value={subcat === "Yes" || subcat === "Success"}
                      key={subcat}
                    >
                      {subcat}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {showImdrfSelect && (
            <Stack direction="row" width={"100%"} alignItems={"center"}>
              <TextField
                className="categorize-modal-textfield"
                label="IMDRF Code"
                value={
                  imdrfCode
                    ? `${imdrfCode.code} - ${imdrfCode.term}`
                    : "No IMDRF Code Selected"
                }
                fullWidth
                disabled
                required
                variant={"standard"}
                InputProps={{ disableUnderline: true }}
                sx={{
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                  paddingRight: "2px",
                }}
              />
              <Button
                onClick={() => setShowImdrfSelectWindow(true)}
                variant="contained"
                size="small"
                fullWidth
                endIcon={<KeyboardArrowRight size="1.5rem" />}
                disabled={!category || showImdrfSelectWindow}
              >
                {imdrfCode
                  ? "SEARCH FOR A DIFFERENT CODE"
                  : "SEARCH FOR IMDRF CODE"}
              </Button>
            </Stack>
          )}

          {showCustomFields(category) && (
            <Stack
              direction="column"
              key={"CustomFieldsWrapper"}
              spacing={1.5}
              width={"100%"}
              alignItems={"center"}
            >
              {category.attributeTypeFields &&
                category.attributeTypeFields.map((field) => (
                  <Stack
                    direction="row"
                    key={"CustomField_" + field.id}
                    width={"100%"}
                    alignItems={"center"}
                  >
                    {field.valueType === "STRING" && (
                      <TextField
                        className="categorize-modal-textfield"
                        label={field.label ?? "Missing Label"}
                        id={field.id}
                        name={field.id}
                        value={
                          fieldData.find(
                            (f) => f.attributeTypeFieldId === field.id
                          )?.fieldValue ?? ""
                        }
                        placeholder="Treatment Name" //TODO: add placeholder text column to table??
                        InputProps={{ inputProps: { min: 0 } }}
                        size="small"
                        required={field.required ?? false}
                        onChange={handleFieldDataInputChange}
                        fullWidth
                      />
                    )}

                    {field.valueType === "BOOLEAN" && (
                      <FormControl size="small" fullWidth>
                        <InputLabel>
                          {field.label ?? "Missing Label"}
                        </InputLabel>
                        <Select
                          label={field.label ?? "Missing Label"}
                          id={field.id}
                          name={field.id}
                          value={
                            fieldData.find(
                              (f) => f.attributeTypeFieldId === field.id
                            )?.fieldValue ?? ""
                          }
                          onChange={handleFieldDataInputChange}
                          variant="outlined"
                        >
                          <MenuItem key="None" value="">
                            None
                          </MenuItem>
                          {field.optionValues.split(",").map((optionValue) => {
                            return (
                              <MenuItem
                                key={optionValue}
                                value={
                                  optionValue === "Yes" ||
                                  optionValue === "Success"
                                    ? "true"
                                    : "false"
                                }
                              >
                                {optionValue}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}

                    {field.valueType === "INTEGER" && (
                      <TextField
                        className="categorize-modal-textfield"
                        label={field.label ?? "Missing Label"}
                        id={field.id}
                        name={field.id}
                        value={
                          fieldData.find(
                            (f) => f.attributeTypeFieldId === field.id
                          )?.fieldValue ?? ""
                        }
                        placeholder={"0-n"} //TODO: add placeholder text column to table??
                        InputProps={{ inputProps: { min: 0 } }}
                        size="small"
                        type="number"
                        required={field.required ?? false}
                        onChange={handleFieldDataInputChange}
                        fullWidth
                      />
                    )}
                  </Stack>
                ))}
            </Stack>
          )}

          <TextField
            className="categorize-modal-textfield"
            name={"CategorizationReason"}
            label="Notes/Reason"
            fullWidth
            required
            multiline
            rows={4}
            value={reason || ""}
            onChange={(event) => setReason(event.target.value)}
            disabled={deleting}
          />
          {apiErrorMessage && (
            <FormControl fullWidth>
              <Alert severity="error">{apiErrorMessage}</Alert>
            </FormControl>
          )}
          {deleting && (
            <TextField
              className="categorize-modal-delete-textfield"
              name={"CategorizationDeleteReason"}
              label="Enter Note/Reason for Deleting"
              fullWidth
              maxRows={4}
              multiline
              value={deleteReason || ""}
              onChange={(event) => setDeleteReason(event.target.value)}
              placeholder="Please enter a reason for deleting this categorization."
            />
          )}
          {!deleting && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {!!attribute && (
                <Button
                  onClick={() => setDeleting(true)}
                  color="error"
                  variant="contained"
                  size="small"
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={loading}
                >
                  Delete
                </Button>
              )}
              <Stack
                direction="row"
                spacing={1}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <Button onClick={handleClose} variant="outlined" size="small">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  size="small"
                  endIcon={loading && <CircularProgress size="1.5rem" />}
                  disabled={getSubmitDisabled()}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          )}
          {deleting && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <Button
                onClick={() => setDeleting(false)}
                variant="outlined"
                size="small"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={(e) => onDelete(e)}
                size="small"
                endIcon={loading && <CircularProgress size="1.5rem" />}
                disabled={getDeleteDisabled()}
              >
                Delete
              </Button>
            </Stack>
          )}
        </Stack>
        <Collapse
          orientation="horizontal"
          in={showImdrfSelectWindow}
          unmountOnExit
          timeout={0}
          onEntered={() => actionRef.current.updatePosition()}
          onExited={() => actionRef.current.updatePosition()}
        >
          <IMDRFComponent
            projectId={projectId}
            imdrfAnnex={category?.imdrfAnnex}
            selectedCode={imdrfCode}
            setSelectedCode={setSelectedCode}
          />
        </Collapse>
      </Stack>
    </Popover>
  );
}
