import React, { useMemo, useState } from "react";
import {
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { sortEncounterCodes } from "../../../models/patient";
import { TableRowsSkeleton } from "../TableRowsSkeleton";
import { getDiffDaysMessage } from "../../../utils/main";

const sortEnum = { asc: "asc", desc: "desc" };
const sortByEnum = {
  code: "code",
  description: "description",
  visit: "diffDays",
};

export default function CodeListTable({ codes, loading, onOpenVisit }) {
  const [sortBy, setSortBy] = useState(sortByEnum.visit);
  const [sort, setSort] = useState(sortEnum.asc);

  const codesSorted = useMemo(() => {
    if (!codes || codes.length === 0) {
      return [];
    }
    const dataUnsorted = [...codes];
    if (sort === "" || sortBy === "") {
      return dataUnsorted;
    }
    return sortEncounterCodes(sort, sortBy, dataUnsorted);
  }, [sort, sortBy, codes]);

  function sortColumn(columnToSort) {
    if (sort === "" || sort === sortEnum.desc || sortBy !== columnToSort) {
      setSort(sortEnum.asc);
      setSortBy(columnToSort);
      return;
    }
    setSort(sortEnum.desc);
    setSortBy(columnToSort);
  }

  return (
    <TableContainer>
      <Table className={"code-list-table"} stickyHeader={true}>
        <TableHead className={"head"}>
          <TableRow>
            <TableCell className={"code"}>
              <TableSortLabel
                active={sortBy === sortByEnum.code}
                direction={sort}
                onClick={() => sortColumn(sortByEnum.code)}
              >
                Code
              </TableSortLabel>
            </TableCell>
            <TableCell className="code-description">
              <TableSortLabel
                active={sortBy === sortByEnum.description}
                direction={sort}
                onClick={() => sortColumn(sortByEnum.description)}
              >
                Code Description
              </TableSortLabel>
            </TableCell>
            <TableCell className="visit">
              <TableSortLabel
                active={sortBy === sortByEnum.visit}
                direction={sort}
                onClick={() => sortColumn(sortByEnum.visit)}
              >
                Visit
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={"body"} sx={{ bgcolor: "background.paper" }}>
          {loading ? (
            <TableRowsSkeleton rowsNum={5} columnsNum={3} />
          ) : (
            codesSorted.map((code, code_index) => {
              return (
                <TableRow
                  key={
                    code.encounterId + code.code + code.type + "_" + code_index
                  }
                >
                  <TableCell>
                    <Chip
                      classes={{ root: "chip-container", label: "chip-label" }}
                      label={code.code}
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{code.description}</TableCell>
                  <TableCell>
                    <Link
                      component="button"
                      variant="body2"
                      align="left"
                      onClick={() => onOpenVisit(code.encounterId)}
                    >
                      {getDiffDaysMessage(code.diffDays)}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
