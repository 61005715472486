import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import EditIcon from "@mui/icons-material/Edit";

export default function Badgeicon({ type }) {
  if (type === "Create") {
    return <AddCircleIcon fontSize={"inherit"} />;
  }
  if (type === "Delete") {
    return <RemoveCircleOutlinedIcon fontSize={"inherit"} />;
  }
  if (type === "Update") {
    return <EditIcon fontSize={"inherit"} />;
  }
  return <span></span>;
}
