import React from "react";
import { Doughnut } from "react-chartjs-2";

function sum(array) {
  if (!array) {
    return 0;
  }
  return array.reduce((acc, curr) => {
    return acc + curr;
  }, 0);
}

const labelTooltip = (tooltipData) => {
  return `${tooltipData.label.trim()}: ${
    tooltipData.formattedValue || 0
  } Patients`;
};

const doughnutOptions = {
  cutout: "80%",
  centerText: true,
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: labelTooltip,
      },
    },
  },
};

const centerText = {
  id: "centerText",
  afterDatasetsDraw(chart) {
    if (!chart.options.centerText) {
      return;
    }
    const totalAmount = sum(chart.data.datasets[0].data);
    const { ctx, chartArea } = chart;
    const { top, width, height } = chartArea;
    const textSize = 96;
    ctx.save();
    ctx.font = `bold 24px DM Sans`;
    ctx.fillStyle = "#64748B";
    ctx.textAlign = "center";
    ctx.fillText("Total", width / 2, height / 2 + top - 40);
    ctx.restore();

    ctx.font = `700 ${textSize}px DM Sans`;
    ctx.fillStyle = "#334155";
    ctx.textAlign = "center";
    ctx.fillText(totalAmount, width / 2, height / 2 + top + 48);
    ctx.restore();
  },
};

export default function TotalPatientCounts({ data }) {
  return (
    <Doughnut data={data} options={doughnutOptions} plugins={[centerText]} />
  );
}
