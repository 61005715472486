import { apiPrefix } from "../utils/main";
import FetchUtils from "../utils/FetchUtils";

export const projectsPrefix = `${apiPrefix}/projects`;

export async function getDiagnosisCodes(projectId, type, searchText, status) {
  const path = `${projectsPrefix}/${projectId}/diagnosiscodes?type=${type}&searchtext=${searchText}&status=${status}`;

  try {
    const response = await FetchUtils.fetch(path, "GET");
    return await response.json();
  } catch (error) {
    console.error("Error: ", error.toString());
    throw error;
  }
}

export async function getProcedureCodes(projectId, type, searchText, status) {
  const path = `${projectsPrefix}/${projectId}/procedurecodes?type=${type}&searchtext=${searchText}&status=${status}`;

  try {
    const response = await FetchUtils.fetch(path, "GET");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error: ", error.toString());
    throw error;
  }
}

export async function updateProjectsFilters(projectId, newData) {
  const path = `${projectsPrefix}/${projectId}/filters`;
  try {
    const response = await FetchUtils.fetch(path, "PATCH", newData);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error: ", error.toString());
    throw error;
  }
}

export async function exportData(projectId, filesOptional) {
  const getPath = () => {
    const url = new URL(`${projectsPrefix}/${projectId}/export`);
    const search_params = url.searchParams;
    if (filesOptional && filesOptional.length > 0) {
      search_params.set("includeOptionalFiles", filesOptional.join(","));
      url.search = search_params.toString();
    }
    return url.toString();
  };
  try {
    const res = await FetchUtils.fetch(getPath(), "GET");
    const fileName = res.headers
      .get("content-disposition")
      .split(";")
      .find((n) => n.includes("filename="))
      .replace("filename=", "")
      .replaceAll('"', "")
      .trim();
    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error: ", error.toString());
    throw error;
  }
}
