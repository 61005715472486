import { Close, OfflineBoltOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import theme from "../../../styles/theme";
import Footer from "../../shared/Footer";

export default function PrioritizationPage({ projectId }) {
  const { fetch: getAllKeywords } = useFetch();
  const { update: bulkUpdateKeywords } = useFetch();

  const { fetch: fetchProject, data: project } = useFetch();

  const [keywords, setKeywords] = useState([]);
  const [updateDetails, setUpdateDetails] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);

  const [displayErrors, setDisplayErrors] = useState(false);

  const location = useLocation();

  const getFormattedCategory = function (category) {
    switch (category) {
      case "adverse_event":
        return "Adverse Event";

      case "performance":
        return "Performance";

      case "clinical":
        return "Clinical Benefit";

      default:
        return "";
    }
  };

  useEffect(() => {
    getAllKeywords(`${apiPrefix}/keywords?projectId=${projectId}`, (data) => {
      setKeywords(data);
    });

    if (refreshRequired) setRefreshRequired(false);
  }, [projectId, refreshRequired, getAllKeywords]);

  useEffect(() => {
    fetchProject(`${apiPrefix}/projects/${projectId}`);

    if (refreshRequired) setRefreshRequired(false);
  }, [fetchProject, projectId, refreshRequired]);

  return (
    <div>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <br />
        <div className="company-settings-item">
          <Grid item>
            <Breadcrumbs>
              {location.state?.previousPath ? (
                <Link
                  underline="hover"
                  color="inherit"
                  to={location.state.previousPath}
                >
                  {project.name}
                </Link>
              ) : (
                <Link
                  underline="hover"
                  color="inherit"
                  to={`/details?projectId=${projectId}`}
                >
                  {project.name}
                </Link>
              )}
              <Link
                underline="hover"
                color="inherit"
                to={`/details?projectId=${projectId}`}
              >
                Project Settings
              </Link>
              <Typography>Prioritization</Typography>
            </Breadcrumbs>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="h4">Prioritization</Typography>
          </Grid>
          <br />
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ backgroundColor: "rgba(0, 180, 215, 0.1)" }}
          >
            <Alert
              className="prioritation-info-text "
              severity="info"
              sx={{ width: "50ch" }}
            >
              <AlertTitle>How 3Aware Prioritization Works</AlertTitle>
              Using your selections below, 3Aware will assign a priority score
              to clinical notes and patient visits. Records with high priority
              will be displayed with this icon:
              <OfflineBoltOutlined
                className="icon"
                size="small"
                sx={{ color: theme.palette.info.main }}
              />
            </Alert>
          </Grid>
          {/* <br />
          <Grid item>
            <Typography variant="h6">Important Clinical Note Types</Typography>
            <Typography variant="caption">
              Select the types of clinical notes that you would like to
              prioritize.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <InputLabel shrink htmlFor="select-clinical-notes">
              Clinical Note Types
            </InputLabel>
            <Select
              multiple
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value.id} label={value.modelNumber} />
                  ))}
                </Box>
              )}
              inputProps={{
                id: "select-clinical-notes",
              }}
              sx={{ width: "50ch" }}
            ></Select>
          </Grid> */}
          <br />
          <Grid item>
            <Typography variant="h6">Important Keywords</Typography>
            <Typography variant="body2">
              Project-level keywords for 3Aware to use when prioritizing each
              patient’s visits and clinical notes. Each keyword is assigned a
              category based on the corresponding outcome measure.
            </Typography>
          </Grid>
          <br />
          <Grid item>
            <Typography variant="body2">* Required fields</Typography>
          </Grid>
          <br />
          {keywords.map((value, index) => {
            if (value.forDelete) {
              return <div key={`row-${index}`}></div>;
            }

            return (
              <Grid
                item
                container
                direction="row"
                justifyContent={"center"}
                alignItems={"flex-end"}
                key={`row-${index}`}
                spacing={4}
                sx={{ paddingTop: "10px" }}
              >
                {editMode ? (
                  <Grid item>
                    <TextField
                      required
                      label="Keyword"
                      value={value.phrase}
                      error={displayErrors && value.phrase === ""}
                      helperText={
                        displayErrors && value.phrase === ""
                          ? "This field is required"
                          : ""
                      }
                      sx={{ width: "20ch" }}
                      onChange={(event) => {
                        let localKeywords = structuredClone(keywords);
                        localKeywords[index].phrase = event.target.value;
                        setKeywords(localKeywords);
                      }}
                      InputProps={{ readOnly: !editMode }}
                    />
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography variant="caption">Keyword *</Typography>
                    <Typography variant="body1" sx={{ width: "20ch" }}>
                      {value.phrase}
                    </Typography>
                  </Grid>
                )}
                {editMode ? (
                  <Grid item>
                    <FormControl error={displayErrors && value.category === ""}>
                      <InputLabel shrink htmlFor={"select-category-" + index}>
                        Category
                      </InputLabel>
                      <Select
                        label="Category"
                        value={value.category}
                        onChange={(event) => {
                          let localKeywords = structuredClone(keywords);
                          localKeywords[index].category = event.target.value;
                          setKeywords(localKeywords);
                        }}
                        inputProps={{ id: "select-category-" + index }}
                        sx={{ width: "50ch" }}
                        readOnly={!editMode}
                      >
                        <MenuItem value="adverse_event">Adverse Event</MenuItem>
                        <MenuItem value="performance">Performance</MenuItem>
                        <MenuItem value="clinical">Clinical Benefit</MenuItem>
                      </Select>
                      <FormHelperText>
                        {displayErrors && value.category === ""
                          ? "This field is required"
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography variant="caption">Category *</Typography>
                    <Typography variant="body1" sx={{ width: "50ch" }}>
                      {getFormattedCategory(value.category)}
                    </Typography>
                  </Grid>
                )}
                {(!editMode && <></>) || (
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        let localKeywords = structuredClone(keywords);
                        localKeywords[index].forDelete = true;
                        setKeywords(localKeywords);
                      }}
                    >
                      <Close sx={{ color: "rgba(161, 38, 16, 1)" }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
          <br />
          {!editMode || (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  let localKeywords = structuredClone(keywords);
                  localKeywords.push({
                    phrase: "",
                    category: "",
                    projectId: projectId,
                  });
                  setKeywords(localKeywords);
                }}
              >
                Add Keyword
              </Button>
            </Grid>
          )}
          {editMode && (
            <>
              <br />
              <Grid item>
                <TextField
                  required
                  variant="outlined"
                  label="Note/Reason for Updating the Device Details"
                  className="settings-text-input"
                  sx={{ width: "50ch" }}
                  value={updateDetails}
                  error={displayErrors && updateDetails === ""}
                  helperText={
                    displayErrors && updateDetails === ""
                      ? "This field is required"
                      : ""
                  }
                  onChange={(event) => {
                    setUpdateDetails(event.target.value);
                  }}
                />
              </Grid>
              <br />
            </>
          )}
          <Grid container item justifyContent={"right"}>
            {(editMode && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRefreshRequired(true);
                    setUpdateDetails("");
                    setDisplayErrors(false);
                    setEditMode(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="update-button"
                  sx={{ marginLeft: "5px" }}
                  onClick={() => {
                    let missingValues = false;

                    for (let i = 0; i < keywords.length; i++) {
                      if (
                        keywords[i].phrase === "" ||
                        keywords[i].category === ""
                      ) {
                        missingValues = true;
                        break;
                      }
                    }

                    if (updateDetails === "") {
                      missingValues = true;
                    }

                    if (missingValues) {
                      setDisplayErrors(true);
                      return;
                    }

                    bulkUpdateKeywords(
                      `${apiPrefix}/keywords/bulk`,
                      {
                        reason: updateDetails,
                        keywords: keywords,
                      },
                      "PUT"
                    ).then(() => {
                      setEditMode(false);
                      setRefreshRequired(true);
                      setUpdateDetails("");
                    });
                  }}
                >
                  Update
                </Button>
              </>
            )) || (
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Grid>
          <br />
          <Footer />
        </div>
      </Grid>
      <br />
    </div>
  );
}
