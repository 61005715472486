import React from "react";
import { Box } from "@mui/material";
import VisitsInfo from "./VisitsInfo";
import VisitsSkeleton from "./skeletons/VisitsSkeleton";

export default function Visits({
  patient,
  updatePatient,
  getPatientRequest,
  updatePatientList,
  encounters,
  loading,
  imdrfEnabled,
}) {
  if (!encounters || encounters.length === 0 || loading) {
    return <VisitsSkeleton />;
  }
  return (
    <div className="visits-card">
      <Box
        sx={{
          display: "flex",
          width: 1,
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <VisitsInfo
          patient={patient}
          updatePatient={updatePatient}
          getPatientRequest={getPatientRequest}
          encounters={encounters}
          updatePatientList={updatePatientList}
          imdrfEnabled={imdrfEnabled}
        />
      </Box>
    </div>
  );
}
