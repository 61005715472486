import React from "react";
import { Typography } from "@mui/material";

const regExp = /with Note\/Reason: "(.*?)". \(/;

function formatText(sentence) {
  return sentence.replace(regExp, "to: ").slice(0, -1);
}
export default function ProjectText({ sentence }) {
  const sentenceFormated = formatText(sentence);
  return <Typography variant={"body1"}>{sentenceFormated}</Typography>;
}
