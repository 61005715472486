import React, { useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";
import DemographicChart from "./DemographicChart";
import LockIcon from "@mui/icons-material/Lock";
import IconContained from "../shared/customicons/IconContained";
import theme from "../../styles/theme";
import { grey } from "@mui/material/colors";
import ContactUsModal from "./ContactUsModal";
import { backgroundColors } from "../../utils/charts";

function getChartConfig(dataInput, countProperty, labelProperty, bgColors) {
  if (!dataInput) {
    return;
  }
  const labels = dataInput.map((item) => {
    return `${item[labelProperty]}                                                                                `;
  });
  const data = dataInput.map((item) => {
    return item[countProperty];
  });
  const datasets = [
    {
      label: "",
      data,
      backgroundColor: bgColors,
    },
  ];
  return { labels, datasets };
}

export default function DemographicBreakdown({
  data,
  version,
  closeModal,
  openModal,
  displayModal,
}) {
  const { genders, ethnicities, ages, races } = data;
  const gendersConfig = useMemo(
    () => getChartConfig(genders, "count", "gender", backgroundColors),
    [genders]
  );
  const ethnicitiesConfig = useMemo(
    () => getChartConfig(ethnicities, "count", "ethnicity", backgroundColors),
    [ethnicities]
  );
  const agesConfig = useMemo(
    () => getChartConfig(ages, "count", "range", backgroundColors),
    [ages]
  );
  const racesConfig = useMemo(
    () => getChartConfig(races, "count", "race", backgroundColors),
    [races]
  );

  return (
    <div
      id={"demographic-breakdown"}
      className="demographic-breakdown-container"
    >
      {displayModal && <ContactUsModal closeModal={closeModal} />}
      <Typography variant="h6" align="left" gutterBottom>
        Demographic Breakdown
      </Typography>
      <div
        className={`${version === "Preview Mode" ? "preview-mode-charts" : ""}`}
      >
        <Grid container spacing={3}>
          <DemographicChart title="Gender" config={gendersConfig} />
          <DemographicChart title="Ethnicity" config={ethnicitiesConfig} />
          <DemographicChart
            chartClass="chart-cotainer-lg"
            title="Age"
            config={agesConfig}
          />
          <DemographicChart
            chartClass="chart-cotainer-lg"
            title="Race"
            config={racesConfig}
          />
        </Grid>
        {version && version === "Preview Mode" && (
          <div className="preview-mode-backdrop">
            <IconContained
              icon={<LockIcon />}
              bgColor={theme.palette.primary.light}
              color={"white"}
            />
            <Typography variant="h6">
              Unlock more patient data with 3Aware Premium
            </Typography>
            <Typography variant="body1" sx={{ color: grey[500] }}>
              Full access to patient demographics and geographic locations,
              patient level details with clinical notes and advanced analytic
              tools powered by artificial intelligence.
            </Typography>
            <Button
              onClick={openModal}
              variant="contained"
              className="preview-mode-backdrop-button"
            >
              Contact Us
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
