import React from "react";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

export default function NoteText({ note }) {
  const text = note.noteText;
  const sanitiziedText = DOMPurify.sanitize(text, {
    ALLOWED_TAGS: ["highlight"],
  });

  const finalText = sanitiziedText.replaceAll("\n", "<br />");

  return (
    <Typography
      variant="body1"
      dangerouslySetInnerHTML={{ __html: finalText }}
    />
  );
}
