import React from "react";
import { Alert, AlertTitle, TextField, Typography } from "@mui/material";

export default function ContactForMissingDevices({
  displayErrors,
  onNewDeviceChange,
  onOtherInformationChange,
  projectData,
  subStep,
}) {
  const showDeviceError = !projectData.deviceModels;

  if (subStep.finished) {
    return (
      <Alert className="prioritation-info-text " severity="success">
        <AlertTitle>Thank you!</AlertTitle>
        We will review the device model number you submitted and contact you as
        soon as possible.
      </Alert>
    );
  }
  return (
    <>
      <Typography variant="body2">
        Please provide the device version/model number(s) below, along with any
        other information we might need to find your device(s).
      </Typography>
      <Typography color="text.secondary">* Required fields</Typography>
      <TextField
        error={displayErrors && showDeviceError}
        fullWidth
        label="Device Version/Model Number(s)"
        required
        onChange={onNewDeviceChange}
        value={projectData.deviceModels || ""}
        InputLabelProps={{ shrink: true }}
        data-testid="ContactForMissingDevices-deviceVersionModel"
      />
      <TextField
        fullWidth
        label="Other Information"
        rows={6}
        multiline
        onChange={onOtherInformationChange}
        value={projectData.otherInformation || ""}
        InputLabelProps={{ shrink: true }}
        inputProps={{ "data-testid": "ContactForMissingDevices-otherInfo" }}
      />
    </>
  );
}
