import React from "react";
import { Skeleton, Stack } from "@mui/material";

export default function SelectedPatientSkeleton() {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{ width: "100%" }}
      className={"patient-header"}
    >
      <Stack direction={"row"} className={"patient-header-left"}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={220}
            height={30}
          />
        </Stack>
      </Stack>
      <Stack direction={"row"} className={"patient-header-right"}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          className={"categorize-button"}
          width={154}
          height={36}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          className={"categorize-button"}
          width={136}
          height={36}
        />
      </Stack>
    </Stack>
  );
}
