export const sortEnum = {
  asc: "asc",
  desc: "desc",
};

export const sortByEnum = {
  patients: "patientCount",
  encounters: "encounterCount",
};

export function sortData(sort, sortBy, data) {
  const groupedCodes = data.grouped
    ? data.grouped.map((item) => {
        const codesSorted = item.grouped.sort((a, b) => {
          if (sort === sortEnum.asc) {
            return a[sortBy] - b[sortBy];
          }
          return b[sortBy] - a[sortBy];
        });
        return Object.assign({ ...item }, { grouped: codesSorted });
      })
    : [];

  const ungroupedCodes = data.ungrouped
    ? data.ungrouped.sort((a, b) => {
        if (sort === sortEnum.asc) {
          return a[sortBy] - b[sortBy];
        }
        return b[sortBy] - a[sortBy];
      })
    : [];

  return { ...data, grouped: groupedCodes, ungrouped: ungroupedCodes };
}

export function getTableDataIsRelatedValues(tableData) {
  const groupedCodes = tableData.grouped.map((item) => item.grouped);
  const flatGroupedCodes = groupedCodes.flatMap((group) => group);
  const allCodes = [...flatGroupedCodes, ...tableData.ungrouped];
  return allCodes.reduce((acc, curr) => {
    return { ...acc, [curr.id]: curr.isRelated };
  }, {});
}

export function isThisCodeRelated(codeMarkedValue, APIValue) {
  if (codeMarkedValue !== undefined) {
    return codeMarkedValue;
  }
  if (APIValue === null || APIValue === true) {
    return true;
  }
  return false;
}
