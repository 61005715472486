import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { convertDate } from "../../../../utils/main";
import PatientsLogIcons from "./PatientLogsIcons";
import ProjectText from "./ProjectText";
import PatientText from "./PatientText";

const logsWithoutNote = ["Project Created"];
export default function ActivityCard({ log, hyperlink, cardType }) {
  return (
    <Card className="activity-card">
      <div>
        <PatientsLogIcons log={log} />
      </div>
      <div className="activity-card-content">
        <Typography variant={"body1"}>
          {convertDate.dateToUSFormat(log.createdAt, {
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })}
        </Typography>
        {cardType === "project" && <ProjectText sentence={log.sentence} />}
        {cardType === "patient" && (
          <PatientText log={log} hyperlink={hyperlink} />
        )}
        {!logsWithoutNote.includes(log.type.description) && (
          <Box
            className="activity-card-note"
            sx={{ bgcolor: "action.selected" }}
          >
            <Typography variant="body1">{log.note}</Typography>
          </Box>
        )}
      </div>
    </Card>
  );
}
