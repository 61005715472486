import React from "react";
import Marketing from "../shared/Marketing";
import SignUp from "../authentication/SignUp";

export default function SignUpLayout() {
  return (
    <div className="signup-layout">
      <Marketing />
      <SignUp />
    </div>
  );
}
