import React, { useMemo } from "react";
import { Card, CardContent, FormHelperText, Typography } from "@mui/material";
import TotalPatientCounts from "./TotalPatientCounts";
import BreakdownByGender from "./BreakdownByGender";
import BulletLegend from "../../shared/charts/BulletLegend";
import { backgroundColors } from "../../../utils/charts";
import ProjectMetricCardsSkeleton from "../skeletons/ProjectMetricCardsSkeleton";

function getChartConfig(dataInput, countProperty, labelProperty, bgColors) {
  if (!dataInput) {
    return;
  }
  const labels = dataInput.map((item) => {
    return `${item[labelProperty]}`;
  });
  const data = dataInput.map((item) => {
    return item[countProperty];
  });
  const datasets = [
    {
      label: "",
      data,
      backgroundColor: bgColors,
    },
  ];
  return { labels, datasets };
}
export default function ProjectMetricCards({ data, forAdmin }) {
  const gendersConfig = useMemo(
    () => getChartConfig(data.genders, "count", "gender", backgroundColors),
    [data.genders]
  );
  const totalPatientsConfig = useMemo(() => {
    return {
      labels: ["Adults (18 and older)", "Pediatric (under 18)"],
      datasets: [
        {
          data: [data.totalAdultPatients, data.totalPediatricPatients],
          backgroundColor: backgroundColors,
        },
      ],
    };
  }, [data.totalAdultPatients, data.totalPediatricPatients]);
  const totalPatientsCount = data.totalPatients || 0;
  if (!data || Object.keys(data).length === 0) {
    return <ProjectMetricCardsSkeleton staticSkeleton={forAdmin} />;
  }

  return (
    <section className="project-metric-cards-container">
      <Card className="total-patients">
        <CardContent className="card-content">
          <Typography variant="h6" className="card-title">
            Total Patient Counts
          </Typography>
          <div className="chart-container">
            <TotalPatientCounts data={totalPatientsConfig} />
          </div>
          <div>
            <div className="chart-labels">
              {totalPatientsConfig.labels.map((_, index) => {
                const text = `${totalPatientsConfig.datasets[0].data[index]} ${totalPatientsConfig.labels[index]}`;
                const bulletColor =
                  totalPatientsConfig.datasets[0].backgroundColor[index];
                return (
                  <BulletLegend
                    key={text}
                    text={text}
                    bulletColor={bulletColor}
                  />
                );
              })}
            </div>
          </div>
          <FormHelperText className="helper-text">
            Total number of patients in 3Aware linked to the selected device(s)
            with procedures in the selected time frame.
          </FormHelperText>
        </CardContent>
      </Card>
      <Card className="breadkdown-by-gender">
        <CardContent className="card-content">
          <Typography variant="h6" className="card-title">
            Breakdown by Gender
          </Typography>
          <div className="chart-container">
            {gendersConfig && <BreakdownByGender data={gendersConfig} />}
          </div>
          <div>
            <div className="chart-labels">
              {gendersConfig &&
                gendersConfig.labels.map((_, index) => {
                  const numberOfPatients =
                    gendersConfig.datasets[0].data[index];
                  const legendPercentage = (
                    (numberOfPatients / totalPatientsCount) *
                    100
                  ).toFixed(0);
                  const text = `${legendPercentage}% - ${gendersConfig.labels[index]}`;
                  const bulletColor =
                    gendersConfig.datasets[0].backgroundColor[index];
                  return (
                    <BulletLegend
                      key={text}
                      text={text}
                      bulletColor={bulletColor}
                      classes={{ text: "legend-text" }}
                    />
                  );
                })}
            </div>
          </div>
          <FormHelperText className="helper-text">
            Breakdown of total patient count by gender.
          </FormHelperText>
        </CardContent>
      </Card>
    </section>
  );
}
