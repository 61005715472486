import React from "react";
import { Button, Snackbar, SnackbarContent } from "@mui/material";

export default function MarkCodesSnackbar({
  allCodesSelectedIds,
  handleMarkCodes,
  isAllSelectionRelatedItems,
}) {
  return (
    <>
      {allCodesSelectedIds.length > 0 && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          className={"snackbar-containter"}
          open={true}
        >
          <SnackbarContent
            elevation={11}
            action={
              <Button
                size="small"
                onClick={handleMarkCodes}
                variant={"contained"}
                sx={{
                  bgcolor: "primary.light",
                  "&:hover": { bgcolor: "primary.light" },
                }}
              >
                {isAllSelectionRelatedItems
                  ? "Mark the selected Code(s) as unrelated"
                  : "Mark the selected Code(s) as related"}
              </Button>
            }
            message={`${allCodesSelectedIds.length} codes selected`}
          />
        </Snackbar>
      )}
    </>
  );
}
