import React from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Box,
  Typography,
} from "@mui/material";

export default function Project({
  displayContent,
  displayErrors,
  onUpdateData,
  projectData,
}) {
  const showNameError = displayErrors && projectData.name.trim() === "";

  function onNameChange(event) {
    onUpdateData({ name: event.target.value });
  }
  function onDescriptionChange(event) {
    onUpdateData({ description: event.target.value });
  }
  if (!displayContent) {
    return null;
  }
  return (
    <Box className="project-details-container" sx={{ bgcolor: "grey.50" }}>
      <Typography variant={"body2"} sx={{ marginBottom: "5px" }}>
        As a final step, provide a name and description for this project. This
        information can be modified at any time after the project is created.
      </Typography>

      <Typography
        variant={"body1"}
        sx={{ marginBottom: "10px", color: "text.secondary" }}
      >
        * Required fields
      </Typography>
      <FormControl fullWidth variant="standard">
        <TextField
          label="Project Name"
          variant="outlined"
          error={showNameError}
          InputProps={{ sx: { fontSize: 14 } }}
          size="medium"
          sx={{ bgcolor: "common.white" }}
          value={projectData.name}
          onChange={onNameChange}
          required
        />
        {showNameError && (
          <FormHelperText error>This field is required</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth variant="standard">
        <TextField
          label="Project Description"
          variant="outlined"
          error={showNameError}
          InputProps={{ sx: { fontSize: 14 } }}
          size="medium"
          sx={{ bgcolor: "common.white" }}
          value={projectData.description}
          onChange={onDescriptionChange}
        />
      </FormControl>
      {/* <FormControl fullWidth>
          <Typography className="drawer-label" variant={"body2"} sx={{ color: "text.secondary" }}>Project Owner</Typography>
          <InputBase
            className="drawer-input"
            size="medium"
            placeholder="No owner is defined in database"
          />
        </FormControl> */}
    </Box>
  );
}
