import { Grid, Typography } from "@mui/material";
import React from "react";

export default function SettingsHeader() {
  return (
    <Grid
      container
      className="settings-header"
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid item>
        <img
          src={process.env.PUBLIC_URL + "/dots-background-left.svg"}
          alt=""
          className="background-dots-left"
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" className="settings-header-text">
          <img
            src={process.env.PUBLIC_URL + "/logo.svg"}
            alt="logo-icon"
            className="settings-header-logo"
          />
          Project Settings
        </Typography>
      </Grid>
      <Grid item>
        <img
          src={process.env.PUBLIC_URL + "/dots-background-right.svg"}
          alt=""
          className="background-dots-right"
        />
      </Grid>
    </Grid>
  );
}
