import React from "react";
import { Skeleton } from "@mui/material";

export default function PatientDemographicsSkeleton() {
  return (
    <>
      <Skeleton animation={"wave"} className={"modal-demographics-skeleton"} />
      <Skeleton animation={"wave"} className={"modal-demographics-skeleton"} />
      <Skeleton animation={"wave"} className={"modal-demographics-skeleton"} />
      <Skeleton animation={"wave"} className={"modal-demographics-skeleton"} />
    </>
  );
}
