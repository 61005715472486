import FetchUtils from "../utils/FetchUtils";
import { apiPrefix, deviceApiPrefix } from "../utils/main";

export const adminBaseUrl = `${apiPrefix}/admin`;

export async function getDevices(manufacturer, search) {
  const path = `${deviceApiPrefix}/devices?manufacturer=${manufacturer}&search=${search}`;

  try {
    const response = await FetchUtils.fetch(path, "GET");
    return await response.json();
  } catch (error) {
    console.error("Error: ", error.toString());
    throw error;
  }
}
