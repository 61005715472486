import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import SelectedPatientMetadata from "./SelectedPatientMetadata";

import ListItemsWrapper from "./ListItemsWrapper";
import SelectedPatientMainBody from "./SelectedPatientMainBody";
import { useFetch } from "../../../services/hooks/useFetch";
import { CurrentPatient } from "../ProjectDetails";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { formatPatient } from "../../../models/patient";
import { useWindowQueryParams } from "../../../services/hooks/useQueryParams";

export default function Patients({
  patientMaxMode,
  setPatientMaxMode,
  patientSummaries,
  updatePatientSummaries,
}) {
  const { setWindowQueryParams } = useWindowQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPatientId = searchParams.get("patientId");
  const projectId = searchParams.get("projectId");
  const [patientToLoad, setPatientToLoad] = useState(
    patientSummaries.find((patient) => patient.id === urlPatientId) ||
      patientSummaries[0]
  );
  const goBackToOverview = useCallback(() => {
    searchParams.delete("patientId");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);
  const {
    data: patientData,
    fetch: getPatient,
    isLoading,
    status,
  } = useFetch();
  const { patient, setPatient, patientLoading, setPatientLoading } =
    useContext(CurrentPatient);
  const currentPatientIndex = patientSummaries.findIndex(
    (patientSum) => patientSum.id === patient.id
  );

  useEffect(() => {
    if (!patientSummaries || patientSummaries.length === 0) {
      return;
    }

    if (!patientToLoad && urlPatientId) {
      setPatientToLoad(patientSummaries.find((sum) => sum.id === urlPatientId));
      return;
    }
    const isPatientIncluded = patientSummaries.some(
      (patient) => patient.id === patientToLoad.id
    );
    if (!isPatientIncluded) {
      setPatientToLoad(patientSummaries[0]);
    }
  }, [projectId, urlPatientId, patientToLoad, patientSummaries]);

  useEffect(() => {
    if (!patientToLoad || !patient) {
      return;
    }

    if (!isLoading && !patientLoading && patient.id !== patientToLoad.id) {
      setPatientLoading(true);
      setPatient(patientToLoad);
      getPatient(
        `${projectsPrefix}/${projectId}/patients/${patientToLoad.id}`,
        (data) => {
          return formatPatient(data);
        }
      );
    }
  }, [
    getPatient,
    isLoading,
    patient,
    patient.id,
    urlPatientId,
    patientLoading,
    patientToLoad,
    projectId,
    setPatientLoading,
    setPatient,
    status,
  ]);

  useEffect(() => {
    if (!isLoading && status !== null) {
      if (status === 200) {
        setPatient(patientData);
        setPatientLoading(false);
        setWindowQueryParams("patientId", patientData.id);
      } else {
        goBackToOverview();
      }
    }
  }, [
    goBackToOverview,
    isLoading,
    patientData,
    patientLoading,
    status,
    setPatient,
    setPatientLoading,
    setWindowQueryParams,
  ]);

  function onPatientSelected(patientSelected) {
    setPatientToLoad(patientSelected);
  }

  function updatePatientList(patientUpdated) {
    setPatient((prev) => ({ ...prev, ...patientUpdated }));
    const dataCopy = [...patientSummaries];
    const patientSummariesUpdated = dataCopy.map((item) => {
      if (item.id === patientUpdated.id) {
        return { ...item, ...patientUpdated };
      }
      return item;
    });
    updatePatientSummaries(patientSummariesUpdated);
  }

  const updatePatient = useCallback(
    (dataUpdated) => {
      setPatient((prev) => ({ ...prev, ...dataUpdated }));
    },
    [setPatient]
  );

  function navigatePatients(isNext) {
    if (isNext && currentPatientIndex !== patientSummaries.length - 1) {
      let newId = patientSummaries[currentPatientIndex + 1];
      setPatientToLoad(newId);
      return;
    }
    if (currentPatientIndex === 0) {
      setPatientToLoad(patient);
      return;
    }
    setPatientToLoad(patientSummaries[currentPatientIndex - 1]);
  }

  return (
    <div className="patients-box">
      {!patientMaxMode && (
        <div className="patient-list">
          <ListItemsWrapper
            patientSummaries={patientSummaries}
            patientSelected={patient}
            onPatientSelected={onPatientSelected}
          />
        </div>
      )}
      <Box className="selected-patient" sx={{ bgcolor: "grey.50" }}>
        <SelectedPatientMetadata
          patientMaxMode={patientMaxMode}
          setPatientMaxMode={setPatientMaxMode}
          goPrevPatient={() => navigatePatients(false)}
          goNextPatient={() => navigatePatients(true)}
          updatePatient={updatePatient}
          updatePatientList={updatePatientList}
        />
        <Divider flexItem />
        <SelectedPatientMainBody
          patientToLoad={patientToLoad ? patientToLoad.id : urlPatientId}
          updatePatient={updatePatient}
          updatePatientList={updatePatientList}
        />
      </Box>
    </div>
  );
}
