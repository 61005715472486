import React from "react";
import { Link, Typography } from "@mui/material";

const valuesToMatch = [
  `Clinical Benefit: "(.*?)"`,
  `Performance: "(.*?)"`,
  `Adverse Event: "(.*?)"`,
  `(?<=Patient marked as )"(.*?)"`,
  `Target Location: "(.*?)"`,
  `Device Deficient: "(.*?)"`,
  `Indication for Use: "(.*?)"`,
  "Visit \\d(?= marked as )",
  `(?<=marked as )"(.*?)"`,
];
const expresion = valuesToMatch.join("|");

export default function PatientText({ log, hyperlink }) {
  const text = log.sentence;
  const regex = new RegExp(`(${expresion})`, "g");
  const boldText = text.match(regex);
  const separatedText = text.replaceAll(regex, "~");

  function regularText(text) {
    let sections = [];
    let sectionsCounter = 0;
    let boldTextIndex = 0;
    for (let i = 0; i < text.length - 1; i++) {
      if (text[i] === "~") {
        if (sections.length >= 1) {
          sectionsCounter++;
        }
        sections[sectionsCounter] = [boldText[boldTextIndex]];
        boldTextIndex++;
        sectionsCounter++;
        continue;
      }
      sections[sectionsCounter] = (sections[sectionsCounter] || "") + text[i];

      if (i > 50 && text[i] === ":") {
        break;
      }
    }
    return sections;
  }
  const editedText = regularText(separatedText);
  return (
    <Typography variant="body1">
      {editedText.map((item, index) => {
        if (
          log.type.category === "Visit Related/Not Related" &&
          index === 0 &&
          hyperlink &&
          String(item).startsWith("Visit")
        ) {
          return (
            <Link
              key={index}
              onClick={() => hyperlink.action(log.encounterId)}
              sx={{ cursor: "pointer" }}
            >
              <b>{String(item)}</b>
            </Link>
          );
        }
        if (typeof item === "object") {
          return <b key={index}>{String(item)}</b>;
        }
        return <span key={index}>{item}</span>;
      })}
    </Typography>
  );
}
