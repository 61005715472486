import React from "react";
import { Typography } from "@mui/material";

export default function EmptyCard({ type }) {
  return (
    <div className="empty-data">
      <img src={process.env.PUBLIC_URL + "/no-data.svg"} alt="no-data" />
      <Typography variant="h6">No {type}</Typography>
      <Typography variant="body1">
        This visit does not include any {type}.
      </Typography>
    </div>
  );
}
