import React, { useState } from "react";
import CodesLayout from "./CodesLayout";

// const selectOptions = [
//   { value: "7 days", label: "Include 7 days after procedure" },
//   { value: "15 days", label: "Include 15 days after procedure" },
//   { value: "30 days", label: "Include 30 days after procedure" },
//   { value: "45 days", label: "Include 45 days after procedure" },
//   { value: "90 days", label: "Include 90 days after procedure" },
//   { value: "120 days", label: "Include 120 days after procedure" },
// ];

const procedureCodeOptions = [
  { value: "cpt", label: "CPT" },
  { value: "hcpcs", label: "HCPCS" },
  { value: "other", label: "Other" },
];

const defaultCodeType = procedureCodeOptions[0].value;

export default function ProcedureCodes({
  codes,
  setProcedureSearchValue,
  setProcedureType,
  updateFilterPayload,
  setUpdating,
  allChecked,
  setAllChecked,
}) {
  const [codesMarkedMap, setCodesMarkedMap] = useState({});

  function updateCodes(codesMarkedUpdated) {
    setUpdating(true);
    setCodesMarkedMap(codesMarkedUpdated);
    const procedureCodes = Object.entries(codesMarkedUpdated).map(
      ([codeId, isRelated]) => {
        return { id: codeId, isRelated: isRelated };
      }
    );

    updateFilterPayload("procedureCodes", procedureCodes);
  }

  return (
    <CodesLayout
      firstColumnLabel={"Procedure Code"}
      helpText={
        "Procedure codes below are based on criteria you set when you started the project."
      }
      searchLabel={"Search by keyword"}
      codeTypeLabel={"Procedure Code Type"}
      codeTypeOptions={procedureCodeOptions}
      defaultCodeType={defaultCodeType}
      tableData={codes}
      codesMarkedMap={codesMarkedMap}
      setSearchValue={setProcedureSearchValue}
      setType={setProcedureType}
      updateCodes={updateCodes}
      allChecked={allChecked}
      setAllChecked={setAllChecked}
    />
  );
}
