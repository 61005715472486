import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";

export default function MakeModalComponent({
  header,
  content,
  footer,
  classes,
}) {
  return (
    <Card className={`make-modal-card ${classes?.modalCard}`}>
      {header && <CardHeader className="make-modal-header" title={header} />}
      {content && (
        <>
          <Divider flexItem />
          <CardContent
            className="make-modal-content"
            sx={{ bgcolor: "grey.50" }}
          >
            {content}
          </CardContent>
        </>
      )}
      {footer && (
        <>
          <Divider />
          <CardActions className="make-modal-footer">{footer}</CardActions>
        </>
      )}
    </Card>
  );
}
