import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Popover,
  Typography,
} from "@mui/material/";
import { useFusionAuth } from "@fusionauth/react-sdk";

export default function DetailsModal({
  project,
  onClose,
  onEdit,
  onProjectActivity,
  anchorEl,
}) {
  const [viewState, setViewState] = useState("S01");

  const description = useMemo(() => {
    if (!project || !project.devices) {
      return [];
    }
    return project.devices.map(
      // TODO: Add the model/version to match the format, example: (183123) - device_description
      (device) => `${device.description}`
    );
  }, [project]);

  function handleClose() {
    setViewState("S00");
    onClose();
  }

  function handleEdit() {
    onEdit();
  }

  const { userInfo } = useFusionAuth();
  const isAdmin = userInfo?.roles?.includes("ADMIN");

  return (
    <div>
      <Popover
        anchorEl={anchorEl}
        open={viewState === "S01"}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card className="project-details-modal">
          <CardHeader
            className={"title"}
            title={<Typography variant={"h5"}>Project Details</Typography>}
          />
          <Divider flexItem />
          <CardContent className="details-modal-box">
            <div className="details-section">
              <Typography variant={"subtitle2"} sx={{ color: "text.disabled" }}>
                Project Name
              </Typography>
              <Typography variant={"body2"}>{project.name}</Typography>
            </div>
            <div className="details-section">
              <Typography variant={"subtitle2"} sx={{ color: "text.disabled" }}>
                Project Description
              </Typography>
              <Typography variant={"body2"}>{project.description}</Typography>
            </div>
            <div className="details-section">
              <Typography variant={"subtitle2"} sx={{ color: "text.disabled" }}>
                GMDN Category
              </Typography>
              <Typography variant={"body2"}>
                {project.deviceGmdn ? project.deviceGmdn.name : ""}
              </Typography>
            </div>
            <div className="details-section">
              <Typography variant={"subtitle2"} sx={{ color: "text.disabled" }}>
                Device Description
              </Typography>
              <Typography variant={"body2"}>
                {description.join(", ")}
              </Typography>
            </div>
            {/* <div className="details-section">
            <Typography variant={"subtitle2"} sx={{ color: "text.disabled" }}>
              Version/Model#
            </Typography>
            <div className="details-versions">
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
              <Chip label="Value" size={"small"} sx={{ borderRadius: 1 }} />
            </div>
          </div> */}
            <div className="details-section">
              <Typography variant={"subtitle2"} sx={{ color: "text.disabled" }}>
                Date Range
              </Typography>
              <Typography variant={"body2"}>
                {project.startDate.getUTCFullYear()} -{" "}
                {project.endDate.getUTCFullYear()}
              </Typography>
            </div>
          </CardContent>
          <Divider flexItem />
          <CardActions className="details-bottom-actions">
            <Button
              size={"small"}
              variant={"outlined"}
              onClick={onProjectActivity}
            >
              View Project Activity
            </Button>
            {isAdmin && (
              <Button
                className="details-edit-project"
                onClick={handleEdit}
                size={"small"}
                variant={"contained"}
              >
                Edit Project
              </Button>
            )}
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
