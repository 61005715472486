import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

import DoughnutPMI from "../shared/charts/DoughnutPMI";
import DemographicsChartSkeleton from "./skeletons/DemographicsChartSkeleton";

export default function DemographicChart({ title, config, chartClass }) {
  return (
    <Grid item md={12} lg={6}>
      <Card className="demographic-breakdown-card">
        <CardContent>
          <Typography
            className="card-title"
            align="left"
            variant="subtitle1"
            gutterBottom
          >
            {title}
          </Typography>
          <div className={chartClass}>
            {!config && <DemographicsChartSkeleton />}
            {config && <DoughnutPMI data={config} />}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
}
