import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../shared/Footer";
import { Box, Button, Typography } from "@mui/material";

export default function SettingNotFound() {
  const navigate = useNavigate();

  return (
    <div className="landing-box">
      <Box className="header-box" sx={{ bgcolor: "grey.50" }}>
        <Typography className="header-text" variant={"h5"}>
          Oops! That setting doesn't exist.
        </Typography>
        <div className={"header-form-box"}>
          <div className="start-project-btn">
            <Button variant="contained" onClick={() => navigate("/")}>
              Back To Home
            </Button>
          </div>
        </div>
      </Box>
      <Footer />
    </div>
  );
}
