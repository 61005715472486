import React from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";

function sum(array) {
  if (!array) {
    return 0;
  }
  return array.reduce((acc, curr) => {
    return acc + curr;
  }, 0);
}

const labelTooltip = (tooltipData) => {
  return `${tooltipData.label.trim()}: ${
    tooltipData.formattedValue || 0
  } Patients`;
};

const doughnutOptions = {
  cutout: "75%",
  centerText: true,
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      onClick: null,
      position: "right",
      maxWidth: window.innerWidth > 1200 ? 260 : 300,
      labels: {
        padding: 15,
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
    tooltip: {
      callbacks: {
        label: labelTooltip,
      },
    },
  },
};

const centerText = {
  id: "centerText",
  afterDatasetsDraw(chart) {
    if (!chart.options.centerText) {
      return;
    }
    const totalAmount = sum(chart.data.datasets[0].data);
    const {
      ctx,
      chartArea: { top, width, height },
    } = chart;

    const textSize = 22;
    const textSpace = 13;
    ctx.save();
    ctx.font = `bold ${textSize * 0.53}px DM Sans`;
    ctx.fillStyle = "#64748B";
    ctx.textAlign = "center";
    ctx.fillText("Total", width / 2, height / 2 + top - textSpace);
    ctx.restore();

    ctx.font = `${textSize}px DM Sans`;
    ctx.fillStyle = "#334155";
    ctx.textAlign = "center";
    ctx.fillText(totalAmount, width / 2, height / 2 + top + textSpace);
    ctx.restore();
  },
};

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutPMI({ data }) {
  return (
    <Doughnut data={data} options={doughnutOptions} plugins={[centerText]} />
  );
}
