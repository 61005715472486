import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  Chip,
  Box,
  LinearProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";

function CustomToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default function PatientAttributeDataGrid({
  loading = false,
  title,
  count,
  handleRowEdit,
  fields,
  rows,
}) {
  const [data, setData] = useState(rows);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setData(rows);
    if (rows && rows.length > 0) {
      setExpanded(true);
    }
  }, [rows]);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion key={title} className={"details-table"} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => toggleAccordion()} />}
        aria-controls={title + "-content"}
        id={title + "-header"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Typography variant="h6" className={"categorization-row-title"}>
            {title}
          </Typography>
          <Chip size="small" label={count} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", padding: 1 }}>
        <Box
          sx={{
            minHeight: 200,
            maxHeight: 500,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DataGridPremium
            initialState={{
              sorting: {
                sortModel: [{ field: "category", sort: "asc" }],
              },
            }}
            slots={{
              toolbar: CustomToolBar,
              loadingOverlay: LinearProgress,
            }}
            loading={loading}
            getRowHeight={() => "auto"}
            density="compact"
            getRowId={() => crypto.randomUUID()}
            columns={fields}
            rows={data}
            filterMode="client"
            onRowDoubleClick={handleRowEdit}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
