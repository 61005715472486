import React from "react";

import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";

export default function SelectCategoryAndDevice({
  canInputNewDevice,
  isWorkshop,
  manufacturers,
  categories,
  devices,
  projectData,
  displayErrors,
  searchTermDebounce,
  onManufacturerChange,
  onCategoryChange,
  onDevicesChange,
  onDisplayInputNewDevice,
}) {
  return (
    <>
      <Typography variant={"body2"} sx={{ marginBottom: "10px" }}>
        Select a category and device name from the list of your organization’s
        devices
      </Typography>

      <Typography
        variant={"body1"}
        sx={{ marginBottom: "7px", color: "text.secondary" }}
      >
        * Required fields
      </Typography>
      <div className="drawer-options">
        {isWorkshop && (
          <FormControl fullWidth margin="dense">
            <Autocomplete
              filterOptions={(x) => x}
              autoComplete
              freeSolo
              filterSelectedOptions
              noOptionsText="Search for manufacturers"
              options={manufacturers}
              value={projectData.deviceManufacturer || null}
              onChange={onManufacturerChange}
              onInputChange={searchTermDebounce}
              getOptionLabel={(option) =>
                option.name + " (" + option.dunsNumber + ")"
              }
              getOptionKey={(option) => option.id}
              data-testid="SelectCategoryAndDevice-autocompletemfr"
              ListboxProps={{
                "data-testid": "SelectCategoryAndDevice-mfrPopper",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  variant="outlined"
                  label={"Device Manufacturer"}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "SelectCategoryAndDevice-manufacturers",
                  }}
                />
              )}
            />
            {displayErrors && (
              <FormHelperText error>This field is required</FormHelperText>
            )}
          </FormControl>
        )}

        <FormControl fullWidth margin="dense">
          <Autocomplete
            autoComplete
            filterSelectedOptions
            disabled={categories == null || categories?.length === 0}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={categories}
            value={projectData.deviceGmdn || null}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            data-testid="SelectCategoryAndDevice-autocompletegmdn"
            onChange={onCategoryChange}
            ListboxProps={{
              "data-testid": "SelectCategoryAndDevice-gmdnPopper",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="medium"
                variant="outlined"
                label={"Device Category"}
                required
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "SelectCategoryAndDevice-categories",
                }}
              />
            )}
          />
          {displayErrors && !projectData.deviceGmdn && (
            <FormHelperText error>This field is required</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="dense">
          <Autocomplete
            filterSelectedOptions
            multiple
            freeSolo
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={devices}
            value={projectData.devices || null}
            getOptionLabel={(option) => option.description}
            getOptionKey={(option) => option.id}
            data-testid="SelectCategoryAndDevice-autocompletedevice"
            onChange={onDevicesChange}
            ListboxProps={{
              "data-testid": "SelectCategoryAndDevice-devicesPopper",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="medium"
                variant="outlined"
                label={"Device Description"}
                required
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "SelectCategoryAndDevice-devices",
                }}
              />
            )}
          />
          {displayErrors && (!devices || devices.length === 0) && (
            <FormHelperText error>This field is required</FormHelperText>
          )}
        </FormControl>
      </div>
      {canInputNewDevice && (
        <Button
          variant="contained"
          fullWidth
          onClick={onDisplayInputNewDevice}
          data-testid="SelectCategoryAndDevice-iDontSeeMyDevice"
        >
          I Do Not See My Device Listed Above
        </Button>
      )}
    </>
  );
}
