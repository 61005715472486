import FetchUtils from "../utils/FetchUtils";
import { apiPrefix } from "../utils/main";

export async function getAllTags() {
  const path = `${apiPrefix}/tags`;

  try {
    const response = await FetchUtils.fetch(path, "GET");
    return await response.json();
  } catch (error) {
    console.error("Error: ", error);
    throw error;
  }
}
