import { useContext } from "react";
import { Grid, Stack } from "@mui/material";
import PatientCard from "./PatientCard";
import IndexProcedureVisitCard from "./IndexProcedureVisitCard";
import VisitsDiagnosesProcedures from "./VisitsDiagnosesProcedures";
import { CurrentPatient } from "../ProjectDetails";

export default function SelectedPatientMainBody({
  patientToLoad,
  updatePatient,
  updatePatientList,
}) {
  const { patient, patientLoading } = useContext(CurrentPatient);

  return (
    <Grid className={"data"} container spacing={2}>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <PatientCard
            patient={patient}
            patientLoading={patientLoading}
            updatePatientList={updatePatientList}
          />
          <IndexProcedureVisitCard
            patientId={patientToLoad}
            patient={patient}
            patientLoading={patientLoading}
            updatePatient={updatePatient}
            updatePatientList={updatePatientList}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <VisitsDiagnosesProcedures
          patient={patient}
          patientLoading={patientLoading}
          updatePatient={updatePatient}
          getPatientRequest={patient}
          updatePatientList={updatePatientList}
        />
      </Grid>
    </Grid>
  );
}
