import React from "react";
import Marketing from "../shared/Marketing";
import ConfirmationWithCompanyName from "../authentication/ConfirmationWithCompanyName";

export default function ConfirmationWithCompanyNameLayout() {
  return (
    <div className="confirmation-layout">
      <Marketing />
      <ConfirmationWithCompanyName />
    </div>
  );
}
