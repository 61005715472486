// A custom theme for this app
const themePalette = {
  palette: {
    mode: "light",
    primary: {
      main: "#004954",
      // light: "", //will be calculated from palette.primary.main,
      // dark: "", //will be calculated from palette.primary.main,
      // contrastText: "", //will be calculated to contrast with palette.primary.main
    },

    secondary: {
      main: "#8DC63F",
      //  light: "", //will be calculated from palette.secondary.main,
      //  dark: "", //will be calculated from palette.secondary.main,
      //  contrastText: "", //will be calculated to contrast with palette.secondary.main
    },

    error: {
      main: "#A12610",
      // light: "", //will be calculated from palette.error.main,
      // dark: "", // will be calculated from palette.error.main,
      // contrastText: "", // will be calculated to contrast with palette.error.main
    },
    warning: {
      main: "#EDAA18",
      // light: "", // will be calculated from palette.warning.main,
      // dark: "", // will be calculated from palette.warning.main,
      // contrastText: "", // will be calculated to contrast with palette.warning.main
    },
    info: {
      main: "#00B4D7",
      // light: "", // will be calculated from palette.info.main,
      // dark: "", // will be calculated from palette.info.main,
      // contrastText: "", // will be calculated to contrast with palette.info.main
    },
    success: {
      main: "#4AC63F",
      // light: will be calculated from palette.success.main,
      // dark: will be calculated from palette.success.main,
      // contrastText: will be calculated to contrast with palette.success.main
    },
    grey: {
      // grey is included in the default MUI theme, but not sure where it is used. https://mui.com/material-ui/customization/default-theme/
      // 50: "#fafafa",
      // 100: "#f5f5f5",
      // 200: "#eeeeee",
      // 300: "#e0e0e0",
      // 400: "#bdbdbd",
      // 500: "#9e9e9e",
      // 600: "#757575",
      // 700: "#616161",
      // 800: "#424242",
      // 900: "#212121",
      // A100: "#f5f5f5",
      // A200: "#eeeeee",
      // A400: "#bdbdbd",
      // A700: "#616161",
    },
    // contrastThreshold: 3, //If "contrastText" is omitted, its value will be calculated to contrast with "main", according to the "contrastThreshold" value.
    //tonalOffset: 0.2, //If the "dark" and / or "light" keys are omitted, their value(s) will be calculated from "main", according to the "tonalOffset" value.
    text: {
      primary: "#092D3F", //Reflects the text.primary variable from the theme object
      secondary: "rgba(9, 45, 63, 0.6)", //Reflects the text.secondary variable from the theme object
      // disabled: "", // Reflects the text.disabled variable from the theme object
    },
    link: {
      main: "#004954",
    },
    // divider: "", //Reflects the divider variable from the theme object
    background: {
      // paper: "", //Reflects the background.paper variable from the theme object
      default: "#FAFAFA", //Reflects the background.default variable from the theme object
    },

    action: {
      // active: "", //Reflects the action.active variable from the theme object
      // hover: "", //Reflects the action.hover variable from the theme object
      // selected: "", //Reflects the action.selected variable from the theme object
      // disabled: "", //Reflects the action.disabled variable from the theme object
      // disabledBackground: "", //Reflects the action.disabledBackground variable from the theme object
      // focus: "", //Reflects the action.focus variable from the theme object
      // selectedOpacity: 0.08,
      // disabledOpacity: 0.38,
      // focusOpacity: 0.12,
      // activatedOpacity: 0.12,
      // activeChannel: "0 0 0",
      // selectedChannel: "0 0 0",
    },

    // outlinedBorder: "", //Border style for outlined variant components in resting state (Text Field, Select, Chips, etc)
    // standardInputBorder: "", //Token for the underline style for the Standard variant components (Text Field, Select, Multiline).
    // backdropOverlay: "", //Backdrop overlay background-color
    // ratingActive: "", //Active state color for Rating component
    // snackbarBackground: "" //Snackbar background color
  },
  typography: {
    fontFamily: ["DM Sans", "Arial"].join(","),
  },
};

export default themePalette;
