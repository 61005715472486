import {
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  Stack,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useWindowQueryParams } from "../../services/hooks/useQueryParams";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailsModal from "../details/DetailsModal";
import UpdateProject from "./projectdetails/UpdateProject";
import FilterDataSet from "./filterdataset/FilterDataSet";
import DataExportDrawer from "../../components/details/patients/DataExportDrawer";
import ProjectActivity from "./activity/ProjectActivity";

export default function ProjectHeader({
  project,
  selectedTab,
  setSelectedTab,
  refreshData,
  updateData,
  disabled,
  disabledIcon,
  patientsLoading,
  patientsError,
  disableRefineButton,
  showCodeUpdateFailureAlert,
  showPollingTimeoutAlert,
}) {
  const { deleteWindowQueryParam } = useWindowQueryParams();
  const [markButton, setMarkButton] = useState();
  const navigate = useNavigate();
  const [exportDrawer, setExportDrawer] = useState(false);
  const [showDetailsModal, setShowDetaislModal] = useState(false);
  const [showProjectDetailsCRU, setShowProjectDetailsCRU] = useState(false);
  const [showFilterDataSet, setShowFilterDataSet] = useState(false);
  const [showProjectActivity, setShowProjectActivity] = useState(false);

  const goProjects = () => {
    navigate("/");
  };

  function handleTabChange(event, value) {
    setSelectedTab(value);
    if (value === 0) {
      deleteWindowQueryParam("patientId");
    }
  }

  function handleDetailsInfo(event) {
    setMarkButton(event.currentTarget);
    setShowDetaislModal(true);
  }

  function closeExportDrawer() {
    setExportDrawer(false);
  }

  function handleDetailsInfoClose() {
    setShowDetaislModal(false);
  }

  function handleEditProject() {
    setShowProjectDetailsCRU(true);
    setShowDetaislModal(false);
  }

  function handleProjectActivity() {
    setShowProjectActivity(true);
    setShowDetaislModal(false);
  }

  function handleCancelProjectCRU() {
    setShowProjectDetailsCRU(false);
    setShowDetaislModal(true);
  }

  function handleCreateProjectCRU() {
    setShowProjectDetailsCRU(false);
    setShowDetaislModal(false);
  }

  function handleOpenFilterData() {
    setShowFilterDataSet(true);
  }

  function onCloseFilterData(options) {
    const { fetchProject } = options || {};
    setShowFilterDataSet(false);
    if (fetchProject) {
      refreshData();
    }
  }

  function onCloseProjectActivity() {
    setShowProjectActivity(false);
  }

  function handleExport(event) {
    setMarkButton(event.currentTarget);
    setExportDrawer(true);
  }

  return (
    <div className="details-body">
      <Paper className="details-box" elevation={0} square>
        <Stack direction={"column"} spacing={1} className="details-box-left">
          <Stack
            direction={"row"}
            spacing={1}
            className={"details-box-left-top"}
            alignItems={"center"}
          >
            <IconButton
              onClick={goProjects}
              sx={{ "&:hover": { bgcolor: "action.hover", height: "auto" } }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">{project.name}</Typography>
            {!disabledIcon && (
              <IconButton
                onClick={handleDetailsInfo}
                sx={{ "&:hover": { bgcolor: "action.hover" } }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            )}
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Box>
              <Tabs value={selectedTab} onChange={handleTabChange} centered>
                <Tab className={"tab"} label={"Overview"} />
                {patientsLoading ? (
                  <Tab
                    className={"tab"}
                    label={`Patients`}
                    icon={<CircularProgress color={"inherit"} size={".7rem"} />}
                    iconPosition={"end"}
                    disabled={true}
                  />
                ) : (
                  <Tab
                    className={"tab"}
                    label={
                      patientsError ? `Error Loading Patients` : `Patients`
                    }
                    disabled={patientsError ? true : disabled}
                  />
                )}
              </Tabs>
            </Box>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={1} className={"details-box-right"}>
          <Button
            variant={"outlined"}
            className={"filter-button"}
            onClick={handleOpenFilterData}
            disabled={disableRefineButton}
          >
            Refine Data Set
          </Button>
          <Button
            id="data-export"
            variant={"outlined"}
            className={"filter-button"}
            onClick={handleExport}
            disabled={disabled}
          >
            Data Export
          </Button>
        </Stack>
      </Paper>
      {showDetailsModal && (
        <DetailsModal
          anchorEl={markButton}
          onClose={handleDetailsInfoClose}
          onEdit={handleEditProject}
          onProjectActivity={handleProjectActivity}
          project={project}
        />
      )}
      {showProjectDetailsCRU && (
        <UpdateProject
          defaultValues={project}
          onUpdate={updateData}
          onCancel={handleCancelProjectCRU}
          onCreate={handleCreateProjectCRU}
        />
      )}
      {showFilterDataSet && (
        <FilterDataSet onClose={onCloseFilterData} project={project} />
      )}
      {showProjectActivity && (
        <ProjectActivity onClose={onCloseProjectActivity} />
      )}
      {exportDrawer && (
        <DataExportDrawer onClose={closeExportDrawer} anchorEl={markButton} />
      )}
      {showCodeUpdateFailureAlert && (
        <Alert className="prioritation-info-text " severity="error">
          <AlertTitle>
            Project Diagnosis & Procedure Code Loading Error
          </AlertTitle>
          Error Loading Data Set, Refine Data Set Disabled
        </Alert>
      )}
      {showPollingTimeoutAlert && (
        <Alert className="prioritation-info-text " severity="error">
          <AlertTitle>Project Data Loading</AlertTitle>
          Project data is still loading, please check back in a bit.
        </Alert>
      )}
    </div>
  );
}
