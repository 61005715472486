import React from "react";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";

const filter = createFilterOptions();
export default function AutoCompleteInput({
  label,
  state,
  setState,
  options,
  deleting,
}) {
  function handleOnChange(event, newValue) {
    if (typeof newValue === "string") {
      setState(newValue);
    }
    // Create a new value from the user input
    if (newValue && newValue.inputValue) {
      setState(newValue.inputValue);
    }
  }
  function filterOptions(options, params) {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option);
    const inputValueTrimmed = inputValue.trim();
    if (inputValueTrimmed !== "" && !isExisting) {
      filtered.push({
        inputValue: inputValueTrimmed,
        title: `${inputValueTrimmed} (add new)`,
      });
    }
    return filtered;
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      value={state}
      onChange={handleOnChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      disabled={deleting}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
      }}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {typeof option === "string" ? option : option.title}
        </li>
      )}
      freeSolo
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
