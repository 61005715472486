import React from "react";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";

export default function VisitsSkeleton() {
  return (
    <div className={"visits-card"}>
      <Card>
        <CardContent align={"left"}>
          <VisitInfoSkeleton />
          <VisitInfoSkeleton />
          <VisitInfoSkeleton />
        </CardContent>
      </Card>
    </div>
  );
}

export function VisitInfoSkeleton() {
  return (
    <div className={"visit-item"}>
      <div className={"visit-data"}>
        <Typography>
          <Skeleton animation="wave" width={180} />
        </Typography>
        <div className={"visit-type"}>
          <Typography variant={"caption"}>
            <Skeleton animation="wave" width={80} />
          </Typography>
        </div>
      </div>
      <div className={"visit-actions"}>
        <Typography variant={"subtitle2"}>
          <Skeleton animation="wave" width={50} />
        </Typography>
      </div>
    </div>
  );
}
