import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RowItem from "./RowItem";
import { isThisCodeRelated } from "../../../../data/codesTable";

export default function RowGroup({
  codesSelected,
  codesMarkedMap,
  groupCodes,
  collapseTitle,
  onCheck,
  onSelectGroup,
  sectionId,
}) {
  const isEveryItemChecked = (codesSelected || []).length === groupCodes.length;
  const [open, setOpen] = useState(false);
  const [openBounced, setOpenBounced] = useState(false);

  useEffect(() => {
    if (open) {
      setOpenBounced(true);
      return;
    }
    setTimeout(() => {
      setOpenBounced(open);
    }, 200);
  }, [open]);

  function toggleOpen() {
    setOpen((prev) => !prev);
  }

  function ignoreClick(e) {
    e.stopPropagation();
  }

  return (
    <>
      <TableRow
        className={"row"}
        hover
        sx={{ bgcolor: "grey.50" }}
        onClick={toggleOpen}
      >
        <TableCell>
          <div className={"container"}>
            <div
              className={"description"}
              onClick={ignoreClick}
              aria-hidden="true"
            >
              <Checkbox
                color={"primary"}
                checked={isEveryItemChecked}
                onChange={(event) =>
                  onSelectGroup(event, sectionId, groupCodes)
                }
                inputProps={{ "data-testid": "row-group" }}
              />
              <Typography className={"title"}>{collapseTitle}</Typography>
              <Box
                className={"badge"}
                sx={{ bgcolor: "grey.300", borderRadius: 1 }}
              >
                {groupCodes.length}
              </Box>
            </div>
          </div>
        </TableCell>
        <TableCell></TableCell>
        <TableCell align={"right"}>
          <IconButton size={"small"}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={"row-collapse"}>
        <TableCell colSpan={3}>
          <Collapse in={open}>
            <Table className={"sub-table"} size={"small"}>
              <TableBody>
                {openBounced &&
                  groupCodes.map((item) => {
                    const isRelated = isThisCodeRelated(
                      codesMarkedMap[item.id],
                      item.isRelated
                    );
                    return (
                      <RowItem
                        key={item.id}
                        checked={(codesSelected || []).includes(item.id)}
                        item={item}
                        isRelated={isRelated}
                        onCheck={onCheck}
                        sectionId={sectionId}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
