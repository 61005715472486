import React from "react";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";

export default function ProjectMetricCardsSkeleton(staticSkeleton) {
  return (
    <section className="project-metric-cards-container project-metric-cards-skeleton">
      <Card className="total-patients">
        <CardContent className="card-content">
          <Typography variant="h6" sx={{ width: "100%" }}>
            <Skeleton />
          </Typography>
          <div className="chart-container">
            <Skeleton
              variant="circular"
              animation={staticSkeleton ? false : "wave"}
              width={340}
              height={340}
            />
          </div>
          <Skeleton
            variant="rectangular"
            animation={staticSkeleton ? false : "wave"}
            width="100%"
            height={140}
          />
        </CardContent>
      </Card>
      <Card className="breadkdown-by-gender">
        <CardContent className="card-content">
          <Typography variant="h6" sx={{ width: "100%" }}>
            <Skeleton />
          </Typography>
          <div className="chart-container">
            <Skeleton
              variant="circular"
              animation={staticSkeleton ? false : "wave"}
              width={130}
              height={130}
            />
          </div>
          <Skeleton
            variant="rectangular"
            animation={staticSkeleton ? false : "wave"}
            width="100%"
            height={120}
          />
        </CardContent>
      </Card>
    </section>
  );
}
