import {
  isDeviceSectionValid,
  isPrioritizationSectionValid,
  isProjectSectionValid,
  isProtocolSectionValid,
} from "../../../../models/upsertProject";
import DeviceDetails from "./DeviceDetails";
import Prioritization from "./Prioritization";
import Project from "./Project";
import Protocol from "./Protocol";

export const freemiumSteps = [
  {
    id: "device-details",
    label: "Device Details",
    validation: isDeviceSectionValid,
    component: DeviceDetails,
  },
  {
    id: "protocol",
    label: "Protocol",
    validation: isProtocolSectionValid,
    component: Protocol,
  },
  {
    id: "project-details",
    label: "ProjectDetails",
    validation: isProjectSectionValid,
    component: Project,
  },
];
export const premiumSteps = [
  {
    id: "device-details",
    label: "Device",
    validation: isDeviceSectionValid,
    component: DeviceDetails,
  },
  {
    id: "protocol",
    label: "Protocol",
    validation: isProtocolSectionValid,
    component: Protocol,
  },
  {
    id: "prioritization",
    label: "Prioritization",
    validation: isPrioritizationSectionValid,
    component: Prioritization,
  },
  {
    id: "project-details",
    label: "Project",
    validation: isProjectSectionValid,
    component: Project,
  },
];
