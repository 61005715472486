import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../styles/theme";
import Footer from "./Footer";

export default function Marketing() {
  return (
    <Box
      className="login-marketing-wrapper"
      sx={{ bgcolor: theme.palette.primary.main }}
    >
      <Box className="login-marketing">
        <img
          src={process.env.PUBLIC_URL + "/logomark-tagline-dark.svg"}
          alt={"3Aware icon"}
        />
        <Typography
          align={"left"}
          variant={"h3"}
          sx={{ color: "common.white" }}
        >
          Data access, now.
        </Typography>
        <Typography
          align={"left"}
          variant={"h6"}
          sx={{ color: "common.white" }}
        >
          Enabling MedTech’s PMS teams with real-world data to accelerate PMCF
          reporting.
        </Typography>
      </Box>
      <Footer publicFooter={true} />
    </Box>
  );
}
