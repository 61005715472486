import React from "react";
import {
  Card,
  CardContent,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import VisitsSkeleton from "../visits/skeletons/VisitsSkeleton";

export default function VisitsDiagnosesProceduresSkeleton() {
  return (
    <div className="visitsDiagnosesProcedures">
      <Card>
        <CardContent>
          <Typography>
            <Skeleton animation="wave" variant="rectangular" width={"50%"} />
          </Typography>
        </CardContent>
        <CardContent>
          <Tabs className={"tabs"} centered value={false}>
            <Tab
              className="tab"
              label={
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                />
              }
            />
            <Tab
              className="tab"
              label={
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                />
              }
            />
            <Tab
              className="tab"
              label={
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                />
              }
            />
          </Tabs>
        </CardContent>
        <CardContent>
          <VisitsSkeleton />
        </CardContent>
      </Card>
    </div>
  );
}
