import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function Activity({ title, logs, onClose, ...props }) {
  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: { width: "min(100%, 1052px)" },
      }}
    >
      <Card className="drawer-card patient-activity-drawer">
        <CardHeader
          title={
            <div className="modal-header-left">
              <IconButton
                onClick={onClose}
                sx={{ "&:hover": { bgcolor: "action.hover" } }}
              >
                <ChevronRightIcon />
              </IconButton>
              <Typography className="drawer-title" variant="h5">
                {title}
              </Typography>
            </div>
          }
        />
        <Divider flexItem />
        <CardContent
          className="drawer-content content"
          sx={{ bgcolor: "grey.50" }}
        >
          <Stack spacing={2} width="100%">
            {logs?.map((log) => {
              return props.children(log);
            })}
          </Stack>
        </CardContent>
      </Card>
    </Drawer>
  );
}
