import React from "react";
import { cyan, green, grey, purple, red } from "@mui/material/colors";
import IconContained from "./IconContained";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SensorDoorOutlinedIcon from "@mui/icons-material/SensorDoorOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import theme from "../../../styles/theme";

export function ClinicalBenefitIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<MedicalServicesOutlinedIcon />}
      bgColor={purple[50]}
      color={purple[600]}
    />
  );
}
export function PerformanceIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<TrendingUpIcon />}
      bgColor={cyan[50]}
      color={cyan[600]}
    />
  );
}
export function ComplicationIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<WarningAmberOutlinedIcon />}
      bgColor={red[50]}
      color={red[600]}
    />
  );
}
export function PatientIncludedIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<ThumbUpAltOutlinedIcon />}
      bgColor={green[50]}
      color={green[600]}
    />
  );
}
export function PatientExcludedIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<BlockOutlinedIcon />}
      bgColor={red[50]}
      color={red[600]}
    />
  );
}
export function PatientUndeterminedIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<PersonOutlineOutlinedIcon />}
      bgColor={grey[50]}
      color={grey[600]}
    />
  );
}
export function AditionalInformationIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<InfoOutlinedIcon />}
      bgColor={theme.palette.action.selected}
      color={theme.palette.primary.main}
    />
  );
}
export function VisitRelationship({ type }) {
  return (
    <IconContained
      type={type}
      icon={<SensorDoorOutlinedIcon />}
      bgColor={theme.palette.action.selected}
      color={theme.palette.primary.main}
    />
  );
}

export function ProjectDetailsIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<SettingsApplicationsOutlinedIcon />}
      bgColor={theme.palette.action.selected}
      color={theme.palette.primary.main}
    />
  );
}

export function ProjectFiltersIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={<FilterAltOutlinedIcon />}
      bgColor={theme.palette.action.selected}
      color={theme.palette.primary.main}
    />
  );
}
