import { useFetch } from "./useFetch";
import { apiPrefix } from "../../utils/main";

export function useAnnexData() {
  const { fetch: fetchAnnex, data: annexData } = useFetch();

  async function getAnnexData(annex, projectId) {
    await fetchAnnex(
      `${apiPrefix}/imdrf/annex/${annex.id}/all?projectId=${projectId}`,
      (data) => {
        annex.children = data;
        return annex;
      }
    );
    return annexData;
  }

  return {
    getAnnexData,
  };
}
