import { Typography } from "@mui/material";

export default function Footer({ publicFooter }) {
  const termsOfUseUrl = "https://3aware.ai/terms-of-service/";
  const privacyPolicyUrl = "https://3aware.ai/privacy-policy/";
  const startYear = 2022;

  return (
    <div
      className={`${publicFooter ? `public-shared-footer` : `shared-footer`}`}
    >
      <a href={termsOfUseUrl} target={"_blank"} rel="noreferrer">
        Terms of Use
      </a>{" "}
      <a href={privacyPolicyUrl} target={"_blank"} rel="noreferrer">
        Privacy Policy
      </a>{" "}
      <Typography variant="body1">
        &copy;{`${startYear}-${new Date().getFullYear()} 3Aware, Inc.`}
      </Typography>
    </div>
  );
}
