import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import TableComponent from "./TableComponent";
import ZeroProjects from "./ZeroProjects";
import { startProject } from "../../models/Projects";
import NavBar from "../shared/NavBar";
import { projectsPrefix } from "../../services/ProjectsServices";
import CreateProject from "../shared/projectdetails/CreateProject";
import { useNavigate } from "react-router-dom";
import Footer from "../shared/Footer";
import { useFetch } from "../../services/hooks/useFetch";
import { RequireAuth } from "@fusionauth/react-sdk";

export default function Home() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState("S01");
  const { data: projects, isLoading, fetch } = useFetch();

  useEffect(() => {
    fetch(projectsPrefix);
  }, [fetch]);

  function handleStartProject() {
    setPageState("S02");
  }

  function handleCancelProjectCRU() {
    setPageState("S01");
  }

  function handleCreateProjectCRU() {
    setPageState("S01");
  }

  function handleDeviceCounts() {
    navigate("/counts");
  }

  return (
    <div className={"App"}>
      <NavBar />
      <div className="landing-box">
        <Box className="header-box" sx={{ bgcolor: "grey.50" }}>
          <Typography className="header-text" variant={"h5"}>
            Let&apos;s get started!
          </Typography>
          <div className={"header-form-box"}>
            <RequireAuth withRole={window.env.underGlass ? "ADMIN" : ""}>
              <div className="start-project-btn">
                <Button variant="contained" onClick={handleStartProject}>
                  Start a Preview Project
                </Button>
              </div>
            </RequireAuth>
            <RequireAuth withRole={"ADMIN"}>
              <Button variant="contained" onClick={handleDeviceCounts}>
                Pull Device Counts
              </Button>
            </RequireAuth>
          </div>
        </Box>
        <Divider />
        <div className="body-box">
          <div className="table-box2">
            {isLoading ? (
              <CircularProgress />
            ) : projects.length > 0 ? (
              <>
                <TableComponent projects={projects} />
                {/* TO-DO: unhide when we want it to work
                <Paginator /> */}
              </>
            ) : (
              <ZeroProjects
                handleStartProject={handleStartProject}
                loading={isLoading}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
      {pageState === "S02" && (
        <CreateProject
          defaultValues={startProject}
          onCancel={handleCancelProjectCRU}
          onCreate={handleCreateProjectCRU}
        />
      )}
    </div>
  );
}
